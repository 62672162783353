import { Environment } from './model/environment.model';

export const environment: Environment = {
  production: false,
  apiUrl: 'https://apim-psp-backend-dev.azure-api.net/api',
  keycloakUrl: 'https://ca-psp-keycloak-dev.mangoriver-998e02b9.westeurope.azurecontainerapps.io',
  keycloakRealm: 'wienenergie',
  keycloakClient: 'client-meinwienenergie-public',
  baseUrl: 'https://dev-unternehmen.wienenergie.at',
  staticContentUrl: 'https://test-static.wienenergie.at/projects/premiumserviceportal/config',
  isPiwikProTrackingDebugEnabled: true,
  piwikTagPropertyId: '7a869ec1-2d1e-4866-a58f-67de85df74e8',
  cookieConsentDataUrl: 'https://test-static.wienenergie.at/modules/cookie-consent/cookie-consent-data.json',
  mazePropertyId: 'a4ea12d0-bb0a-46cb-b905-cd2185580d6c',
  feedbackUrl: 'https://www.netigate.se/ra/s.aspx?s=1245982X459947598X55106',
};
