<div class="row">
  <h2 class="h6 pb-5">{{ 'meterPoint.meterPointChangeRequest.newContractPartnerInfo' | translate }}</h2>
  @switch (contractPartnerType()) {
    @case (ContractPartnerType.PRIVATE) {
      <app-private-person-form />
    }
    @case (ContractPartnerType.COMPANY) {
      <app-organization-form />
    }
  }
</div>
