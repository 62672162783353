<div class="card-basic mb-4" [ngClass]="{ 'card-has-focus': buttonIsFocused, 'has-error': hasProductsSelectedError() }">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</div>
<ng-template #content>
  <ng-container>
    <form [formGroup]="formGroup" tabindex="-1">
      <div class="card-basic-body">
        <div class="row gx-4 align-items-center">
          <div class="col-auto text-center pt-4 productRow pl-4 pl-xl-5 pr-xl-5">
            <img src="/assets/icons/products/icon-{{ categoryIcon }}.png" alt width="75" />
            <h3 class="h6 f-16 mt-2 mb-0" data-test="product-category-text">{{ meterPoint().sparte | translateCategory | async }}</h3>
            <input
              type="checkbox"
              id="productCheckbox"
              class="mt-2 checkbox-product-selection form-check-input"
              (change)="onCheckboxChange($event)"
              [checked]="isMeterPointSelected()"
              [ngClass]="{ 'is-invalid': hasProductsSelectedError() }"
            />
            <label for="productCheckbox"></label>
          </div>
          <div class="col py-xl-0 py-3">
            <ng-container>
              <div class="row">
                <div class="col-12 col-xl-6">
                  <div class="form-floating p-3">
                    <lib-info-card-content-field
                      class="col-xl-6 col-12"
                      [key]="'meterPoint.meterPointChangeRequest.productAndDateStep.productSelection.meterPointLabel'"
                      [value]="getZaehlpunktnummerShort(formGroup.get('meterPointNumber').value)"
                      [contentDivId]="'form-control border-0'"
                    />
                  </div>
                </div>
              </div>
              <div class="row mt-3 input-row">
                <div class="col-12 col-xl-6 order-lg-0 order-1">
                  <div class="form-floating form-floating-spacing">
                    <input
                      type="text"
                      inputmode="decimal"
                      class="form-control"
                      id="inputFieldZaehlerstand"
                      placeholder="{{
                        'meterPoint.meterPointChangeRequest.productAndDateStep.productSelection.meterCountPlaceholder' | translate
                      }}"
                      formControlName="meterCount"
                      [ngClass]="{ 'is-invalid': isInputInvalid(SELECTED_METER_POINTS_KEY + '.' + this.meterPoint().id + '.meterCount') }"
                      [readonly]="!isMeterPointSelected()"
                    />
                    <label for="inputFieldZaehlerstand">{{
                      'meterPoint.meterPointChangeRequest.productAndDateStep.productSelection.meterCountLabel' | translate
                    }}</label>
                    <div class="invalid-feedback">
                      {{ getFormError(SELECTED_METER_POINTS_KEY + '.' + this.meterPoint().id + '.meterCount') | translate }}
                    </div>
                    <div class="text-muted mt-1">
                      <i class="fas fa-info-circle"></i>
                      {{ 'meterPoint.meterPointChangeRequest.productAndDateStep.productSelection.meterCountInfo' | translate }}
                    </div>
                  </div>
                </div>
                <div class="col-12 col-xl-6 order-0">
                  <div class="form-floating form-floating-spacing">
                    <input
                      type="text"
                      class="form-control"
                      id="inputFieldRefName"
                      formControlName="meterNumber"
                      [ngClass]="{ 'is-invalid': isInputInvalid(SELECTED_METER_POINTS_KEY + '.' + this.meterPoint().id + '.meterNumber') }"
                      [readonly]="!isMeterPointSelected()"
                    />
                    <label for="inputFieldRefName">{{
                      'meterPoint.meterPointChangeRequest.productAndDateStep.productSelection.meterNumberLabel' | translate
                    }}</label>
                    <div class="invalid-feedback">
                      {{ getFormError(SELECTED_METER_POINTS_KEY + '.' + this.meterPoint().id + '.meterNumber') | translate }}
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </form>
  </ng-container>
</ng-template>
