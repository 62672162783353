import { IBankDetails, ICustomerBankKonto, IVertragskonto } from '@mwe/models';
import { CryptographyUtils } from './cryptography.utils';

type IbanType = IBankDetails | IVertragskonto | ICustomerBankKonto;

export async function encryptIBAN(bankDetails: IBankDetails, secret: string): Promise<IbanType> {
  const clone = structuredClone(bankDetails);
  if (bankDetails?.iban) {
    const cu = new CryptographyUtils();
    clone.iban = await cu.encrypt(bankDetails.iban, getSecret(secret));
  }
  return clone;
}

export async function decryptIBAN(bankDetails: IbanType, secret: string): Promise<IbanType> {
  if (bankDetails?.iban?.indexOf(':') == -1) {
    return bankDetails;
  }
  const clone = structuredClone(bankDetails);
  const cu = new CryptographyUtils();
  if (bankDetails?.iban) {
    clone.iban = await cu.decrypt(bankDetails.iban, getSecret(secret));
  }
  return clone;
}

function getSecret(secret: string): string {
  return secret.length > 16 ? secret.substring(0, 16) : secret;
}
