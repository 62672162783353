import { Component, input, model, output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoCardType } from '@shared/model/info-card-type.enum';
import { InfoCardTitleComponent } from '@shared/components/cards/info-card/components/info-card-title/info-card-title.component';

@Component({
  selector: 'lib-ikp-info-card-section',
  standalone: true,
  imports: [CommonModule, InfoCardTitleComponent],
  templateUrl: './info-card-section.component.html',
  styleUrl: './info-card-section.component.scss',
})
export class InfoCardSectionComponent {
  infoCardType = input<InfoCardType>(null);
  title = input<string>(null);
  subtitle = input<string>(null);
  iconPath = input<string>(null);
  isCheckbox = input<boolean>(null);
  isChecked = input<boolean>(null);
  actionText = input<string>(null);
  actionColor = input<string>('orange');
  readonly actionClicked = output<void>();
  isCollapsed = model<boolean>();
  index = input<number>();

  onActionClicked() {
    this.actionClicked.emit();
  }
}
