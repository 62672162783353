import { Component, inject, input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { UiComponentsModule } from '@mwe/ui/components';
import { CommonModule } from '@angular/common';
import {
  MeterPointSelection,
  SELECTED_METER_POINT_PRODUCTS,
} from '@ikp/pages/meter-point-change-request/02_product_and_date/components/model/product-selection.model';
import { MeterPoint } from '@ikp/model/meter-point.model';
import { CustomValidators } from '@shared/utils/validators';
import { InfoCardContentFieldComponent } from '@shared/components/cards/info-card/components/info-card-content-field/info-card-content-field.component';
import { MeterPointChangeSignalState } from '@ikp/pages/meter-point-change-request/meterpoint-change-request.types';
import { SignalState } from '@shared/service/signal-state-service/signal-state.service';
import { getZaehlpunktnummerShort } from '@ikp/pages/meter-point-change-request/meter-point-change-request.utils';

class MeterpointCheckboxSelection {
  isMeterPointSelected: boolean;
  currentMeterPoint: MeterPointSelection | null;
}

@Component({
  selector: 'app-product-selection',
  templateUrl: './product-selection.component.html',
  standalone: true,
  styleUrl: './product-selection.component.scss',
  imports: [UiComponentsModule, CommonModule, ReactiveFormsModule, InfoCardContentFieldComponent],
})
export class ProductSelectionComponent implements OnInit {
  protected wizardStore: MeterPointChangeSignalState = inject(SignalState);

  meterPoint = input<MeterPoint>();
  hasProductsSelectedError = input<boolean>();
  isPreselectedInitially = input<boolean>();

  categoryIcon: string;
  buttonIsFocused = false;
  formGroup: FormGroup;

  formBuilder = inject(FormBuilder);
  parentForm = input<FormGroup>();

  ngOnInit() {
    const currentFormState = this.wizardStore.state().productAndDate?.meterPoints;
    const currentMeterPoint = currentFormState?.find(meterPoint => meterPoint.id === this.meterPoint().id);
    this.parentForm().addControl(SELECTED_METER_POINT_PRODUCTS, this.formBuilder.group({}));
    this.initFormWithSelectedMeterpoint(this.meterPoint().id, currentMeterPoint);
    this.categoryIcon = this.meterPoint().sparte.toLowerCase();
    this.initializeSubscriptions();
  }

  private initFormWithSelectedMeterpoint(meterPointId: string, currentMeterPoint?: MeterPointSelection) {
    const productsForm = this.parentForm().get(SELECTED_METER_POINT_PRODUCTS) as FormGroup;
    const hasAnyMeterPointSelected = this.wizardStore.state().productAndDate?.meterPoints?.length > 0;

    if (hasAnyMeterPointSelected) {
      this.handleExistingMeterPointSelection(meterPointId, currentMeterPoint, productsForm);
    } else {
      this.handleNewMeterPointSelection(meterPointId, currentMeterPoint, productsForm);
    }
  }

  private handleExistingMeterPointSelection(meterPointId: string, currentMeterPoint: MeterPointSelection, productsForm: FormGroup) {
    if (!currentMeterPoint) {
      this.addUnselectedMeterPointForm();
    } else {
      this.addSelectedMeterPointForm(meterPointId, currentMeterPoint, productsForm);
    }
  }

  private handleNewMeterPointSelection(meterPointId: string, currentMeterPoint: MeterPointSelection, productsForm: FormGroup) {
    const meterPointSelection = {
      isMeterPointSelected: this.isPreselectedInitially(),
      currentMeterPoint: currentMeterPoint,
    };
    this.addSelectedMeterpointForm(meterPointSelection);
    if (this.isPreselectedInitially()) {
      productsForm.addControl(meterPointId, this.formGroup);
    }
  }

  private addUnselectedMeterPointForm() {
    const meterPointSelection = {
      isMeterPointSelected: false,
      currentMeterPoint: null,
    };
    this.addSelectedMeterpointForm(meterPointSelection);
  }

  private addSelectedMeterPointForm(meterPointId: string, currentMeterPoint: MeterPointSelection, productsForm: FormGroup) {
    const meterPointSelection = {
      isMeterPointSelected: true,
      currentMeterPoint: currentMeterPoint,
    };
    this.addSelectedMeterpointForm(meterPointSelection);
    productsForm.addControl(meterPointId, this.formGroup);
  }

  private initializeSubscriptions() {
    const currentMeterPoint = this.parentForm().get(SELECTED_METER_POINT_PRODUCTS)?.get(this.meterPoint().id);
    if (!currentMeterPoint) {
      return;
    }
    currentMeterPoint.valueChanges.subscribe(() => {
      if (!currentMeterPoint.valid) {
        return;
      }
      this.updateWizardStore(currentMeterPoint);
    });
  }

  private updateWizardStore(currentMeterPoint: AbstractControl) {
    const trimmedInputValues = this.getTrimmedInputs(currentMeterPoint);
    const currentMeterPoints = this.wizardStore.state().productAndDate?.meterPoints;
    const currentMeterPointIndex = currentMeterPoints.findIndex(meterPoint => meterPoint.id === this.meterPoint().id);

    const updatedMeterPoints =
      currentMeterPointIndex === -1
        ? [...currentMeterPoints, trimmedInputValues]
        : currentMeterPoints.toSpliced(currentMeterPointIndex, 1, trimmedInputValues);

    this.wizardStore.patchState({
      productAndDate: {
        ...this.wizardStore.state().productAndDate,
        meterPoints: updatedMeterPoints,
      },
    });
  }

  private getTrimmedInputs(currentMeterPoint: AbstractControl) {
    return {
      ...currentMeterPoint.value,
      id: this.meterPoint().id,
      meterPointNumber: currentMeterPoint.value.meterPointNumber.trim(),
      meterNumber: currentMeterPoint.value.meterNumber.trim(),
      meterCount: currentMeterPoint.value.meterCount.trim(),
      category: this.meterPoint().sparte,
    };
  }

  removeProduct(meterPointId: string) {
    const productsForm = this.parentForm().get(SELECTED_METER_POINT_PRODUCTS) as FormGroup;
    productsForm.removeControl(meterPointId);
    const meterPointSelection = {
      isMeterPointSelected: true,
      currentMeterPoint: null,
    };
    this.addSelectedMeterpointForm(meterPointSelection);
    this.wizardStore.patchState({
      productAndDate: {
        ...this.wizardStore.state().productAndDate,
        meterPoints: this.wizardStore.state().productAndDate.meterPoints?.filter(mp => mp.id !== meterPointId),
      },
    });
  }

  onCheckboxChange(event: Event) {
    const checkbox = event.target as HTMLInputElement;
    if (checkbox.checked) {
      const currentMeterPoint = this.wizardStore.state().productAndDate?.meterPoints?.find(mp => mp.id === this.meterPoint().id);
      this.addMeterPointControl(currentMeterPoint);
      this.initializeSubscriptions();
    } else {
      this.removeProduct(this.meterPoint().id);
    }
  }

  isInputInvalid(key: string) {
    const form = this.parentForm()?.get(key);
    return form && form.touched && form.invalid;
  }

  isMeterPointSelected() {
    const productsForm = this.parentForm().get(SELECTED_METER_POINT_PRODUCTS) as FormGroup;
    return productsForm.get(this.meterPoint().id)?.get(this.IS_METER_POINT_SELECTED_KEY)?.value;
  }

  getFormError(key: string): string {
    const formKey = this.parentForm().get(key);
    if (formKey?.errors) {
      return formKey.errors.translationKey;
    }
    return null;
  }

  private addMeterPointControl(currentMeterPoint?: MeterPointSelection) {
    const meterPointSelection = {
      isMeterPointSelected: true,
      currentMeterPoint: currentMeterPoint,
    };
    this.addSelectedMeterpointForm(meterPointSelection);
    const meterPointForm = this.parentForm().get(SELECTED_METER_POINT_PRODUCTS) as FormGroup;
    meterPointForm.addControl(this.meterPoint().id, this.formGroup);
  }

  private addSelectedMeterpointForm(meterPointSelection: MeterpointCheckboxSelection) {
    const meterNumber = this.meterPoint().zaehlerNr ?? meterPointSelection.currentMeterPoint?.meterNumber ?? '';

    this.formGroup = this.formBuilder.group({
      isMeterPointSelected: meterPointSelection.isMeterPointSelected,
      meterPointNumber: [this.meterPoint().zaehlpunktnummer, [CustomValidators.meterPointNumberValidator]],
      meterNumber: [meterNumber, [CustomValidators.meterNumberValidator]],
      meterCount: [meterPointSelection.currentMeterPoint?.meterCount ?? '', [Validators.min(0), CustomValidators.meterCountValidator]],
    });
  }

  protected readonly SELECTED_METER_POINTS_KEY = SELECTED_METER_POINT_PRODUCTS;
  protected readonly IS_METER_POINT_SELECTED_KEY = 'isMeterPointSelected';
  protected readonly getZaehlpunktnummerShort = getZaehlpunktnummerShort;
}
