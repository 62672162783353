<div [ngClass]="{ 'mb-3': hasMarginBottom }">
  <h6 class="mb-3">{{ config().titleKey | translate }}</h6>
  <div class="description-text">{{ config().descriptionKey | translate }}</div>
  <hr class="my-3" />
  <input #file (change)="fileUpload($event)" [accept]="config().accept" data-test="hidden-file-upload" hidden name="upload" type="file" />
  <mwe-button-link
    (clicked)="file.click()"
    [label]="config().uploadButtonKey"
    [prependIconClass]="'fa-solid fa-cloud-arrow-up mr-2 w-max-content fs-6 text-decoration-none'"
  >
  </mwe-button-link>
  @if (showLoadingSpinner()) {
    <mwe-loading-spinner />
  }
  @if (uploadedFile()) {
    <div class="row gx-3 mt-5 mt-xl-6 flex-nowrap rounded border border-2" [ngClass]="{ 'border-danger': !isValidFile() }">
      <div class="col-auto p-2 pr-1 pt-0">
        <i aria-hidden="true" class="fas fa-file"></i>
      </div>
      <div class="col overflow-hidden">
        <div class="text-truncate fw-semibold" [ngClass]="{ 'text-danger': !isValidFile() }">
          {{ uploadedFile().name }}
        </div>
        <div>{{ uploadedFile().formattedSize }} | {{ uploadedFile().extension }}</div>
      </div>
      <div class="col-auto d-flex align-items-center">
        <div class="row gx-2 justify-content-end">
          <div class="col-auto">
            <button (click)="removeFile()" class="btn btn-bubble btn-bubble-s bg-gray--400" type="button">
              <i [ngClass]="{ 'text-danger': !isValidFile() }" aria-hidden="true" class="text-secondary fas fa-trash-alt"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  }

  @if (showUploadError()) {
    @for (msgKey of errorMessageKeys(); track msgKey) {
      <div class="font-size-11 text-danger mt-1">
        <i aria-hidden="true" class="fa fa-exclamation-circle mr-1"></i>
        <span>{{ msgKey | translate }}</span>
      </div>
    }
  }
</div>
