<section class="hero--bg hero" id="hero" style="margin-bottom: -5rem">
  <header id="header" class="container">
    <mwe-header-logo></mwe-header-logo>
  </header>
  <header class="hero-header container pb-8" *ngIf="isLoaded">
    <div class="row">
      <div class="col-lg-7 align-self-center">
        <mwe-alert
          [type]="authServerAlertData.type"
          [titleKey]="authServerAlertData.title"
          [messageKey]="authServerAlertData.message"
          *ngIf="showAuthServerAlert"
        ></mwe-alert>
        <mwe-alert
          [type]="dynamicAlertData.type"
          [titleKey]="dynamicAlertData.title"
          [messageKey]="dynamicAlertData.message"
          *ngIf="showDynamicAlert"
        ></mwe-alert>
        <h1 class="mb-5 mb-lg-6">
          <div class="h6 mb-3 text-white">
            <span *ngIf="frontpageData?.content?.titleSmall">{{ frontpageData.content.titleSmall }}</span>
            <span *ngIf="!frontpageData?.content?.titleSmall" translate="home.titleSmall"></span>
          </div>
          <div class="h1 mb-0 text-white">
            <span *ngIf="frontpageData?.content?.titleLarge">{{ frontpageData.content.titleLarge }}</span>
            <span *ngIf="!frontpageData?.content?.titleLarge" [translate]="titleBigLabelKey"></span>
          </div>
        </h1>

        <h3 *ngIf="frontpageData?.content?.subtitle" class="text-white pb-3">{{ frontpageData.content.subtitle }}</h3>

        <p *ngIf="isEmaiInvitationLandingPage" class="hero-copytext" translate="home.subtitle_emailInvitation"></p>
        <p *ngIf="!isEmaiInvitationLandingPage && frontpageData?.content?.copyText" class="hero-copytext pb-7">
          {{ frontpageData.content.copyText }}
        </p>

        <div class="mb-5 mb-lg-6" *ngIf="frontpageAlert">
          <mwe-alert
            [type]="frontpageAlert.type"
            [titleKey]="frontpageAlert.title"
            [messageKey]="frontpageAlert.message"
            [iconClassOverwrite]="frontpageAlert.icon"
          ></mwe-alert>
        </div>

        <div class="mb-5 mb-lg-6">
          <mwe-button
            [showHoverEffect]="true"
            (clicked)="login()"
            class="d-block mb-3"
            buttonId="mwe-home-login"
            color="white"
            labelKey="home.login"
          ></mwe-button>
          <mwe-button
            (clicked)="register()"
            buttonId="mwe-home-register"
            color="white"
            category="secondary"
            labelKey="home.register"
            class="d-block mb-3"
          ></mwe-button>
        </div>
        <p class="mt-5 mt-lg-6">
          <span [translate]="wrongPortalKeys.text"></span>
          <a
            class="text-white focus-inverted link-hover-behaviour"
            tabindex="0"
            [translate]="wrongPortalKeys.link"
            [href]="switchPortalUrl"
          ></a
          >.
          @if (wrongPortalKeys.text2 && (wrongPortalKeys.text2 | translate)) {
            <br />
            <span [innerHTML]="wrongPortalKeys.text2 | translate"></span>
          }
        </p>
        <p *ngIf="frontpageData?.content?.legalText">
          <small [innerHTML]="frontpageData.content.legalText | safeHtml"></small>
        </p>
      </div>
      <div class="col-lg-5 position-relative d-lg-block d-none" aria-hidden="true">
        <ng-container *ngIf="!frontpageData?.content?.imageSmartphoneBg; else smartphoneBackground">
          <img
            class="img-fluid d-block ml-auto"
            style="max-width: 360px"
            [src]="frontpageData.content.imageUri"
            alt="Illustration Container"
            [ngStyle]="{ cursor: frontpageData?.content?.imageHref ? 'pointer' : 'inherit' }"
            (click)="frontpageData?.content?.imageHref ? navigateToCampaignUri() : undefined"
          />
        </ng-container>
      </div>
    </div>
  </header>
</section>
<ng-template #smartphoneBackground>
  <div class="mx-auto ratio ratio-smartphone" style="max-width: 236px; filter: drop-shadow(0 22px 45px rgba(0, 0, 0, 0.33))">
    <div class="smartphone-display">
      <img
        class="img-fluid w-100 rounded"
        [src]="frontpageData.content.imageUri"
        alt="Illustration Container"
        [ngStyle]="{ cursor: frontpageData?.content?.imageHref ? 'pointer' : 'inherit' }"
        (click)="frontpageData?.content?.imageHref ? navigateToCampaignUri() : undefined"
      />
    </div>
  </div>
</ng-template>
