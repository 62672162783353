import { Component, computed, effect, inject, OnDestroy, signal, Signal, viewChild } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { UiComponentsModule, WizardComponent } from '@mwe/ui/components';
import { IWizardModel } from '@mwe/models';
import { ROUTE_HOME, ROUTE_METER_POINTS } from '@ikp/app.constants';
import { NavigationStateService } from '@mwe/services';
import { SignalState } from '@shared/service/signal-state-service/signal-state.service';
import { signalStateFactory } from '@shared/service/signal-state-service/signal-state.utils';
import { MeterPointChangeSignalState, MeterPointChangeState } from './meterpoint-change-request.types';
import {
  closeMeterPointChangeRequestWizard,
  getMeterPointChangeRequestSteps,
  initialMeterPointChangeRequestState,
} from './meter-point-change-request.utils';
import { MeterPointService } from '@ikp/service/meter-point/meter-point.service';
import { IkpPopupService } from '@ikp/service/popup/ikp-popup.service';
import { MeterPointChangeService } from '@ikp/service/meter-point/meter-point-change.service';
import { MeterPoint } from '@ikp/model/meter-point.model';

@Component({
  selector: 'app-ikp-meter-point-change-request',
  templateUrl: './meter-point-change-request.component.html',
  standalone: true,
  imports: [RouterOutlet, UiComponentsModule],
  providers: [
    {
      provide: SignalState,
      useFactory: () => signalStateFactory<MeterPointChangeState>(),
    },
  ],
})
export class MeterPointChangeRequestComponent implements OnDestroy {
  protected wizardStore: MeterPointChangeSignalState = inject(SignalState);
  protected meterPointService = inject(MeterPointService);
  private wizardComponentRef = viewChild(WizardComponent);
  private popupService = inject(IkpPopupService);
  private router = inject(Router);
  private ummeldungService = inject(MeterPointChangeService);
  meterPoint = signal<MeterPoint>(null);

  protected wizardModel: Signal<IWizardModel> = computed(() => ({
    titleKey: 'meterPoint.meterPointChangeRequest.title',
    isDeepLinkEnabled: true,
    forwardDirectionChangeIx: 0,
    hideProgressBar: true,
    useMoreWidth: true,
    wizardSteps: getMeterPointChangeRequestSteps(this.wizardStore.state()),
    customCancelLogic: () => this.customCancel(),
  }));

  constructor(private navigationStateService: NavigationStateService) {
    if (this.router.getCurrentNavigation().extras.state?.meterPointId) {
      const meterPointId = this.router.getCurrentNavigation().extras.state.meterPointId;
      this.meterPointService.loadMeterPoint(meterPointId).then(meterPoint => this.meterPoint.set(meterPoint as MeterPoint));
    } else {
      this.meterPoint.set(this.meterPointService.meterPoints()[0]);
    }
    effect(
      () => {
        if (this.meterPoint()) {
          this.wizardStore.patchState({ meterPoint: this.meterPoint() });
        }
      },
      { allowSignalWrites: true },
    );
    this.navigationStateService.wizardCancelUrl = `/${ROUTE_HOME}/${ROUTE_METER_POINTS}`;
    this.navigationStateService.wizardSuccessUrl = `/${ROUTE_HOME}/${ROUTE_METER_POINTS}`;
    this.wizardStore.initialize({
      initialState: initialMeterPointChangeRequestState,
      storageKey: 'meterPointChangeReqWizard',
    });
  }

  customCancel(): void {
    const showPopup = this.wizardComponentRef().currentWizardStepIdx !== 1 && this.wizardComponentRef().currentWizardStepIdx !== 6;
    const meterPointNumber = this.wizardStore.state().meterPoint?.zaehlpunktnummer;
    const deleteChangeRequestAction = () =>
      this.ummeldungService.cancelMeterPointChangeRequest(meterPointNumber).subscribe({
        error: () => this.ummeldungService.handleGenericErrorPopup('cancel-meterpoint-change-request-error'),
      });
    const popupAction = this.wizardComponentRef().currentWizardStepIdx === 5 ? deleteChangeRequestAction : null;
    closeMeterPointChangeRequestWizard(this.popupService, this.router, this.wizardStore, !showPopup, popupAction);
  }

  ngOnDestroy(): void {
    this.wizardStore.reset();
  }
}
