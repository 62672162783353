import { CommonModule } from '@angular/common';
import { Component, computed, inject, viewChild } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ContractPartnerType } from '@ikp/pages/meter-point-change-request/meter-point-change-request.utils';
import { SignalState } from '@shared/service/signal-state-service/signal-state.service';
import { PrivatePersonFormComponent } from '@ikp/pages/meter-point-change-request/04_new_contract_partner/components/private-person-form/private-person-form.component';
import { OrganizationFormComponent } from '@ikp/pages/meter-point-change-request/04_new_contract_partner/components/organization-form/organization-form.component';
import { TranslatePipe } from '@ngx-translate/core';
import { MeterPointChangeSignalState } from '@ikp/pages/meter-point-change-request/meterpoint-change-request.types';

@Component({
  selector: 'app-contact-information',
  templateUrl: './contact-information.component.html',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, PrivatePersonFormComponent, OrganizationFormComponent, TranslatePipe],
})
export class ContactInformationComponent {
  protected readonly wizardStore: MeterPointChangeSignalState = inject(SignalState);
  protected readonly contractPartnerType = computed(() => this.wizardStore.state().newContractPartner.formData?.partnerType);
  protected readonly ContractPartnerType = ContractPartnerType;

  private contactInformationFormPrivate = viewChild(PrivatePersonFormComponent);
  private contactInformationFormOrganization = viewChild(OrganizationFormComponent);

  public get validContactInformationForm(): boolean {
    if (this.contractPartnerType() === ContractPartnerType.PRIVATE) {
      return this.contactInformationFormPrivate().validForSubmission;
    } else if (this.wizardStore.state().newContractPartner.formData?.partnerType === ContractPartnerType.COMPANY) {
      return this.contactInformationFormOrganization().validForSubmission;
    }
  }
}
