import { SignalState } from '@shared/service/signal-state-service/signal-state.service';

/**
 * Usage documentation of the `signalStateFactory` function.
 * Add inside the Component decorator the following:
 * ```ts
 * providers: [
 *    {
 *      provide: SignalState,
 *      useFactory: () => signalStateFactory<YOUR_TYPE_HERE>(),
 *    },
 *  ],
 *  YOUR_TYPE_HERE: The type of the data you want to store in the context store.
 *
 *  You can define aliases for specific types in the context store, e.g. for the MeterPointChangeState:
 *  export type MeterPointChangeSignalState = SignalState<MeterPointChangeState>;
 */
export function signalStateFactory<T extends object>(): SignalState<T> {
  return new SignalState<T>();
}
