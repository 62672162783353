import { Component, inject, model, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import {
  ContractPartnerPrivateForm,
  getBirthDateModelDatepickerConfig,
} from '@ikp/pages/meter-point-change-request/04_new_contract_partner/new-contract-partner.utils';
import { DatepickerComponent, DatepickerConfig } from '@shared/components/datepicker/datepicker.component';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-struct';
import { buildPrivatePersonForm } from '@ikp/pages/meter-point-change-request/04_new_contract_partner/components/private-person-form/private-person-form.utils';
import dayjs from 'dayjs';
import { getNgbDateStructFromDayJs } from '@mwe/utils';
import { MeterPointChangeSignalState } from '@ikp/pages/meter-point-change-request/meterpoint-change-request.types';
import { SignalState } from '@shared/service/signal-state-service/signal-state.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ContractPartnerType } from '@ikp/pages/meter-point-change-request/meter-point-change-request.utils';

@Component({
  selector: 'app-private-person-form',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, TranslatePipe, DatepickerComponent],
  templateUrl: './private-person-form.component.html',
})
export class PrivatePersonFormComponent {
  private readonly BIRTH_DATE = 'birthDate';

  protected readonly wizardStore: MeterPointChangeSignalState = inject(SignalState);
  protected form: FormGroup;
  protected birthDateModel = model<NgbDateStruct>();
  protected isBirthDateValid = signal(true);

  protected isInputInvalid(key: keyof ContractPartnerPrivateForm) {
    return this.form.get(key).touched && this.form.get(key).invalid;
  }

  protected readonly birthDateModelDatepickerConfig: DatepickerConfig;

  constructor() {
    const currentFormState = this.wizardStore.state().newContractPartner.formData?.privatePartner;
    this.form = buildPrivatePersonForm(inject(FormBuilder), currentFormState);
    if (this.form.get(this.BIRTH_DATE).value) {
      this.birthDateModel.set(getNgbDateStructFromDayJs(dayjs(this.form.get(this.BIRTH_DATE).value)));
    }
    this.birthDateModelDatepickerConfig = getBirthDateModelDatepickerConfig(this.birthDateModel, this.form);
    this.initializeSubscriptions();
  }

  private initializeSubscriptions(): void {
    this.form.valueChanges.pipe(takeUntilDestroyed()).subscribe(() =>
      this.wizardStore.patchState({
        newContractPartner: {
          ...this.wizardStore.state().newContractPartner,
          emptyPartner: null,
          formData: {
            partnerType: ContractPartnerType.PRIVATE,
            privatePartner: this.form.value,
          },
        },
      }),
    );

    this.form
      .get(this.BIRTH_DATE)
      .valueChanges.pipe(takeUntilDestroyed())
      .subscribe(() => this.validateBirthDate());
  }

  protected getFormError(key: keyof ContractPartnerPrivateForm): string {
    const formKey = this.form.get(key);
    if (formKey.errors) {
      return formKey.errors.translationKey;
    }
    return null;
  }

  public get validForSubmission(): boolean {
    this.validateBirthDate();
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    return this.form.valid;
  }

  private validateBirthDate(): void {
    const formValue = this.form.get(this.BIRTH_DATE).value;
    this.isBirthDateValid.set(formValue !== undefined && formValue !== '');
  }

  protected readonly invalidDateMessageKey = 'inputErrors.blank.datePicker';
  protected readonly minBirthDate = getNgbDateStructFromDayJs(dayjs().subtract(200, 'year'));
}
