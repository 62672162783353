import { ServiceStateEnum } from '@mwe/constants';

export interface PreisIndikationenApiResponse {
  preisindikationen: PreisIndikationen[];
}

export interface PreisIndikationen {
  sparte: string;
  arbeitspreis?: string;
  arbeitspreisZahlbar?: string;
  arbeitspreisLabel?: string;
  arbeitspreisUnit?: string;
  arbeitspreisSubtext?: string;
  grundpreis: string;
  grundpreisZahlbar?: string;
  grundpreisLabel?: string;
  grundpreisUnit?: string;
  grundpreisSubtext?: string;
  anschlusspreis?: string;
  anschlusspreisUnit?: string;
  tarif: AvailabilityTarif;
  boni?: Boni[];
  preisbestandteile: PreisBestandteile;
  vorteile?: string[];
  preisDisclaimer?: string;
  rechtsText?: string;
  rechtsTextGueltig?: string;
  istRabattiert?: boolean;
  aktiveAktionen?: PriceIndicationCampaign[];
  aktionsHinweis?: string;
  preisInfos?: PreisInfo[]; // IL / T2 hack - get-preisindikationen-emobility.json
  rabattiert?: PreisRabattiert; // IL / T2 hack - get-preisindikationen-emobility.json
  anlageId?: string; // only for tariff-change, is set by T1
}

export interface AvailabilityTarif {
  tarifKey?: string;
  ISUTarifKey?: string;
  tarifName?: string;
  tarifBeschreibung?: string;
  tarifOptionen?: TarifOption[];
}

// option data from T2 / Preisindikationen / Tarifberater. only for ui, backend-requests needs TariffOptionsSap
export interface TarifOption {
  default?: boolean;
  option: string;
  optionLabel?: string;
  art?: string;
  artLabel?: string;
  cmsOptionId?: string;
  delta?: string;
  deltaBasis?: string;
  deltaUnit?: string;
  farbe?: string;
  iconLabel?: string;
  iconInfoText?: string;
  pricePeriod?: string;
  installationLabel?: string;
  installationPrice?: string;
  installationBasis?: string;
  installationUnit?: string;
  preise?: OptionPreis[];
  tarifOptionen?: TarifOption[];
}

// option data from SAP, use this for backend-requests
// just need options, rest is ignored. ui gets data from T2/TarifOption
export interface TariffOptionSap {
  option: string;
  default?: boolean;
  art?: string;
  optionLabel?: string;
}

export interface TariffSelectionCardData {
  category: string;
  tariffKey: string;
  anlageId?: string; // only for tariff-change
  userSelection: TariffOptionSap[]; // options selected from user
  tariffOptionsSap: TariffOptionSap[]; // all available options
  priceIndication: PreisIndikationen; // additional infos from Tarifberater
  state?: ServiceStateEnum; // to trigger loading state of tariff card from outside
}

export interface PriceIndicationCampaign {
  aktionstexte: PriceIndicationCampaignTextList[];
  aktionsHinweis: string;
}

export interface PriceIndicationCampaignTextList {
  aktionsLabel: string;
  aktionsDetails: string;
}

export interface Boni {
  id: string;
  icon: string;
  heading: string;
  text: string;
}

export interface PreisBestandteile {
  preise?: Preis[];
  produktAttribute: ProduktAttribute[];
}

export interface Preis {
  hauptpreis: string;
  einheit: string;
  bildunterschrift: string;
}

export interface OptionPreis {
  delta: string;
  deltaBasis: string;
  deltaUnit: string;
}

export interface ProduktAttribute {
  name: string;
  kurzWert: string;
  langWert: string;
}

// IL / T2 hack - get-preisindikationen-emobility.json
export interface PreisInfo {
  text: string;
  preis: string;
}

// IL / T2 hack - get-preisindikationen-emobility.json
export interface PreisRabattiert {
  title: string;
  info: string;
  text: string;
}
