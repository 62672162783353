import { Route, RouterModule } from '@angular/router';
import { FrontpageComponent } from './pages/frontpage/frontpage.component';
import { NgModule } from '@angular/core';
import {
  ROUTE_CHANGE_TYPE,
  ROUTE_CONDITIONS_OF_USE_WIZARD,
  ROUTE_CONFIRMATION,
  ROUTE_CURRENT_CONTRACT_PARTNER,
  ROUTE_HOME,
  ROUTE_LOGOUT_SUCCESS,
  ROUTE_METER_POINT_CHANGE_REQUEST,
  ROUTE_NEW_CONTRACT_PARTNER,
  ROUTE_PDF_UPLOAD,
  ROUTE_PRODUCTS_AND_DATE,
  ROUTE_REGISTRATION,
  ROUTE_SUMMARY,
  ROUTE_TERMS_OF_USE,
} from './app.constants';
import { authGuard } from '@shared/auth/guard/auth.guard';
import { TermsOfUsePageComponent } from '@ikp/pages/terms-of-use/terms-of-use-page.component';
import { trackingGuard } from '@ikp/guards/tracking.guard';
import { MeterPointChangeRequestComponent } from '@ikp/pages/meter-point-change-request/meter-point-change-request.component';
import { SelectChangeTypeComponent } from '@ikp/pages/meter-point-change-request/01_select_change_type/select_change_type.component';
import { ProductAndDateComponent } from '@ikp/pages/meter-point-change-request/02_product_and_date/product-and-date.component';
import { CurrentContractPartnerComponent } from '@ikp/pages/meter-point-change-request/03_current_contract_partner/current_contract_partner.component';
import { PdfUploadComponent } from '@ikp/pages/meter-point-change-request/05_2_pdf_upload/pdf-upload.component';
import { SummaryComponent } from '@ikp/pages/meter-point-change-request/05_summary/summary.component';
import { ConfirmationComponent } from '@ikp/pages/meter-point-change-request/06_confirmation/confirmation.component';
import { featureFlagGuard } from '@ikp/guards/feature-flag.guard';
import { FeatureFlag } from '@ikp/model/feature-flag.model';
import { NewContractPartnerComponent } from '@ikp/pages/meter-point-change-request/04_new_contract_partner/new-contract-partner.component';

export const appRoutes: Route[] = [
  {
    path: '',
    component: FrontpageComponent,
    canActivate: [trackingGuard],
    children: [],
  },
  {
    path: ROUTE_LOGOUT_SUCCESS,
    component: FrontpageComponent,
    canActivate: [trackingGuard],
  },
  {
    path: ROUTE_HOME,
    loadChildren: () => import('./pages/main/main.routes').then(mod => mod.MAIN_ROUTES),
    canActivate: [authGuard],
  },
  {
    path: ROUTE_REGISTRATION,
    data: {
      hideHeader: true,
      hideFooter: true,
      toggles: [],
      pageTitle: 'Registration',
    },
    loadChildren: () => import('./pages/registration/registration.module').then(mod => mod.RegistrationModule),
    canActivate: [trackingGuard, authGuard],
  },
  {
    path: ROUTE_CONDITIONS_OF_USE_WIZARD,
    data: {
      hideHeader: true,
      hideFooter: true,
      toggles: [],
      pageTitle: 'ConditionsOfUse',
    },
    canActivate: [trackingGuard, authGuard],
    loadChildren: () => import('./pages/conditions-of-use/conditions-of-use.module').then(mod => mod.ConditionsOfUseModule),
  },
  {
    path: ROUTE_TERMS_OF_USE,
    canActivate: [trackingGuard],
    component: TermsOfUsePageComponent,
  },
  {
    path: ROUTE_METER_POINT_CHANGE_REQUEST,
    component: MeterPointChangeRequestComponent,
    canActivate: [trackingGuard, authGuard, featureFlagGuard(FeatureFlag.UMMELDUNG)],
    children: [
      { path: ROUTE_CHANGE_TYPE, component: SelectChangeTypeComponent },
      { path: ROUTE_PRODUCTS_AND_DATE, component: ProductAndDateComponent },
      { path: ROUTE_CURRENT_CONTRACT_PARTNER, component: CurrentContractPartnerComponent },
      { path: ROUTE_NEW_CONTRACT_PARTNER, component: NewContractPartnerComponent },
      { path: ROUTE_SUMMARY, component: SummaryComponent },
      { path: ROUTE_PDF_UPLOAD, component: PdfUploadComponent },
      { path: ROUTE_CONFIRMATION, component: ConfirmationComponent },
      { path: '**', redirectTo: ROUTE_CHANGE_TYPE, pathMatch: 'full' },
    ],
  },
  {
    path: '**',
    pathMatch: 'full',
    loadComponent: () => import('./pages/main/main.routes').then(mod => mod.MAIN_ROUTES.find(route => route.path === '').component),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
