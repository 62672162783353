<div class="row pb-2">
  <div class="row pb-3">
    <div class="col-12 pb-3 col-xl-6 pb-xl-0">
      <div class="form-floating">
        @if (emptyContractPartner()?.company) {
          <strong class="form-control" id="addUserInputCompany">{{ emptyContractPartner().company }}</strong>
          <label for="addUserInputCompany">
            {{ 'meterPoint.meterPointChangeRequest.currentContractPartnerStep.empty.company' | translate }}
          </label>
        }
      </div>
    </div>
    <div class="col-12 col-xl-6">
      <div class="form-floating">
        <strong class="form-control" id="addUserInputCustomerNumber">{{ emptyContractPartner()?.customerNumber }} </strong>
        <label for="addUserInputCustomerNumber">
          {{ 'meterPoint.meterPointChangeRequest.currentContractPartnerStep.empty.customerNumber' | translate }}
        </label>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 pb-3 col-xl-6 pb-xl-0">
      <div class="form-floating">
        <strong class="form-control" id="addUserInputAddress">{{ addressFormatted() }}</strong>
        <label for="addUserInputAddress">
          {{ 'meterPoint.meterPointChangeRequest.currentContractPartnerStep.empty.address' | translate }}
        </label>
      </div>
    </div>
  </div>
</div>
