<lib-ikp-info-card-title
  id="title"
  [infoCardType]="infoCardType()"
  [title]="title()"
  [subtitle]="subtitle()"
  [iconPath]="iconPath()"
  [actionText]="actionText()"
  [actionColor]="actionColor()"
  [(isCollapsed)]="isCollapsed"
  [isCheckbox]="isCheckbox()"
  [isChecked]="isChecked()"
  (actionClicked)="onActionClicked()"
  [index]="index()"
  [attr.data-test]="index() ? 'info-card-idx-' + index() : 'info-card'"
></lib-ikp-info-card-title>
@if (!isCollapsed()) {
  <div class="section-content-container">
    <ng-content></ng-content>
  </div>
}
