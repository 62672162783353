import { Component, EventEmitter, inject, signal } from '@angular/core';
import { IPopupModel, IWizardStepComponent } from '@mwe/models';
import { MeterPointChangeSignalState } from '@ikp/pages/meter-point-change-request/meterpoint-change-request.types';
import { SignalState } from '@shared/service/signal-state-service/signal-state.service';
import { UiComponentsModule } from '@mwe/ui/components';
import { FileInputComponent } from '@shared/components/inputs/file-input/file-input.component';
import { FileInputConfiguration, FileUploadError, UploadResult } from '@shared/components/inputs/file-input/file-input.model';
import { MeterPointChangeService } from '@ikp/service/meter-point/meter-point-change.service';
import { IkpPopupService } from '@ikp/service/popup/ikp-popup.service';
import { InfoCardType } from '@shared/model/info-card-type.enum';
import { Router } from '@angular/router';
import { ROUTE_HOME, ROUTE_METER_POINTS } from '@ikp/app.constants';
import { finalize, lastValueFrom, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-ikp-pdf-upload',
  standalone: true,
  templateUrl: './pdf-upload.component.html',
  imports: [UiComponentsModule, FileInputComponent],
})
export class PdfUploadComponent implements IWizardStepComponent {
  private readonly DEFAULT_UPLOAD_ERROR_KEY = 'meterPoint.meterPointChangeRequest.pdfUpload.errors.fileUpload';
  protected wizardStore: MeterPointChangeSignalState = inject(SignalState);

  private readonly meterPointChangeService = inject(MeterPointChangeService);
  private readonly popupService = inject(IkpPopupService);
  private readonly router = inject(Router);

  onOverwriteNextButtonLabel = new EventEmitter<string>();

  uploadedFile = signal(null);
  isUploadedFileValid = signal(true);
  showLoadingSpinner = signal(false);

  fileInputConfiguration: FileInputConfiguration = {
    titleKey: 'meterPoint.meterPointChangeRequest.pdfUpload.uploadTitle',
    descriptionKey: 'meterPoint.meterPointChangeRequest.pdfUpload.uploadDescription',
    uploadButtonKey: 'meterPoint.meterPointChangeRequest.pdfUpload.uploadButton',
    minSizeOfFileInByte: 0,
    maxSizeOfFileInByte: 5242880, // 5MiB
    accept: 'application/pdf, image/png, image/jpeg',
    uploadErrorMsg: new Map<FileUploadError, string>([
      [FileUploadError.COULD_NOT_LOAD_FILE, this.DEFAULT_UPLOAD_ERROR_KEY],
      [FileUploadError.FILE_TOO_LARGE, 'meterPoint.meterPointChangeRequest.pdfUpload.errors.fileSize'],
      [FileUploadError.FILE_TYPE_INVALID, 'meterPoint.meterPointChangeRequest.pdfUpload.errors.fileType'],
      [FileUploadError.FILE_TOO_SMALL, this.DEFAULT_UPLOAD_ERROR_KEY],
    ]),
    showSize: true,
    showDeleteButton: true,
  };

  hasAllNeededData(): boolean {
    return true;
  }

  submit(): Promise<void> {
    if (!this.isUploadedFileValid()) {
      this.handleUploadError();
      return Promise.reject();
    }
    if (!this.uploadedFile()) {
      return this.handleUploadLater();
    }

    const meterPointNumber = this.wizardStore.state().meterPoint?.zaehlpunktnummer;
    this.showLoadingSpinner.set(true);
    return lastValueFrom(
      this.meterPointChangeService.uploadSignedMeterpointChange(meterPointNumber, this.uploadedFile()).pipe(
        catchError(() => {
          this.handleUploadError();
          return throwError(() => new Error('File Upload failed'));
        }),
        finalize(() => this.showLoadingSpinner.set(false)),
      ),
    );
  }

  validate(): boolean {
    return true;
  }

  fileUploaded($event: UploadResult) {
    this.isUploadedFileValid.set($event.valid);
    this.uploadedFile.set($event.uploadedFile);

    if ($event.uploadedFile) {
      this.onOverwriteNextButtonLabel.emit('meterPoint.meterPointChangeRequest.wizard.sendMeterPointChange');
    } else {
      this.onOverwriteNextButtonLabel.emit('meterPoint.meterPointChangeRequest.wizard.uploadLater');
    }
  }

  private handleUploadError() {
    this.popupService.handleErrorPopup(
      'upload-error',
      'meterPoint.meterPointChangeRequest.pdfUpload.errors.title',
      this.DEFAULT_UPLOAD_ERROR_KEY,
    );
  }

  protected readonly InfoCardType = InfoCardType;

  private handleUploadLater(): Promise<void> {
    const warningPopupModel: IPopupModel = {
      id: 'upload-later-popup',
      titleKey: 'meterPoint.meterPointChangeRequest.pdfUpload.uploadLaterPopup.title',
      messageKey: 'meterPoint.meterPointChangeRequest.pdfUpload.uploadLaterPopup.message',
      showSubmitButton: true,
      submitButtonKey: 'meterPoint.meterPointChangeRequest.pdfUpload.uploadLaterPopup.buttonOk',
      showCancelButton: true,
      cancelButtonKey: 'meterPoint.meterPointChangeRequest.pdfUpload.uploadLaterPopup.buttonCancel',
      iconColorClass: 'blue',
      iconTypeClass: 'fa-bell',
    };
    this.popupService.handleGenericPopup(warningPopupModel);
    return lastValueFrom(this.popupService.events()).then(result =>
      result
        ? this.router
            .navigate([`/${ROUTE_HOME}/${ROUTE_METER_POINTS}`])
            .then(() => this.wizardStore.reset())
            .then(() => Promise.reject('File uploaded later.'))
        : Promise.reject('File upload later aborted.'),
    );
  }
}
