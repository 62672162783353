import { Component, inject, input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UiComponentsModule } from '@mwe/ui/components';
import { CommonModule } from '@angular/common';
import { PRIVATE_INDIVIDUAL_CONTACT_DATA_KEY } from '@ikp/pages/meter-point-change-request/03_current_contract_partner/model/occupied_contract_partner.model';
import { buildPrivateIndividualFormWithDefaultValues } from '@ikp/pages/meter-point-change-request/03_current_contract_partner/current-contract-partner-form-builder';

@Component({
  selector: 'app-private-contact-data',
  standalone: true,
  templateUrl: './private-contact-data.component.html',
  styleUrls: ['./private-contact-data.component.scss'],
  imports: [UiComponentsModule, FormsModule, ReactiveFormsModule, CommonModule],
})
export class PrivateContactDataComponent implements OnInit, OnDestroy {
  parentForm = input<FormGroup>();
  private formBuilder = inject(FormBuilder);

  ngOnInit(): void {
    this.parentForm().addControl(PRIVATE_INDIVIDUAL_CONTACT_DATA_KEY, buildPrivateIndividualFormWithDefaultValues(this.formBuilder));
  }

  ngOnDestroy(): void {
    this.parentForm()?.removeControl(PRIVATE_INDIVIDUAL_CONTACT_DATA_KEY);
  }

  isInputInvalid(key: string) {
    const form = this.parentForm().get(key);
    return form.touched && form.invalid;
  }

  getFormError(key: string): string {
    const formKey = this.parentForm().get(key);
    if (formKey.errors) {
      return formKey.errors.translationKey;
    }
    return null;
  }

  get privateIndividualContactDataForm(): FormGroup {
    return this.parentForm().get(PRIVATE_INDIVIDUAL_CONTACT_DATA_KEY) as FormGroup;
  }

  protected readonly PRIVATE_INDIVIDUAL_CONTACT_DATA_KEY = PRIVATE_INDIVIDUAL_CONTACT_DATA_KEY;
}
