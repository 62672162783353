import { Address, IOlavEntry } from '../address/address.model';
import { IERechnungsInfo, IVertragskonto } from '../integrationlayer/api.model';

export interface IInvoiceConfig {
  invoiceData: IVertragskonto;
  erechnungData: IERechnungsInfo;
  address: string;
  rechnungsMail?: string;
  eRechnungMandatory: boolean;
  invoiceMandatory: boolean;
  customerDataChangeEmailMsgKey?: string;
  initialAddressData?: {
    address?: Address;
    olavEntry: IOlavEntry;
  };
  invoiceAddressChangesTo?: string;
}
