import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  ContractPartnerPrivateForm,
  ContractPartnerPrivateModel,
} from '@ikp/pages/meter-point-change-request/04_new_contract_partner/new-contract-partner.utils';
import { CustomValidators } from '@shared/utils/validators';

export const buildPrivatePersonForm = (formBuilder: FormBuilder, model?: ContractPartnerPrivateModel): FormGroup =>
  formBuilder.group<ContractPartnerPrivateForm>({
    birthDate: formBuilder.control(model?.birthDate || '', [Validators.required]),
    firstName: formBuilder.control(model?.firstName || '', [Validators.required, CustomValidators.firstNameValidator]),
    lastName: formBuilder.control(model?.lastName || '', [Validators.required, CustomValidators.lastNameValidator]),
    email: formBuilder.control(model?.email || '', [Validators.required, CustomValidators.emailValidator]),
    phone: formBuilder.control(model?.phone || '', [CustomValidators.optionalPhoneNumberValidator]),
  });
