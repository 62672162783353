import {
  AddressForm,
  ContactDataIndividual,
  ContactDataOrganization,
} from '@ikp/pages/meter-point-change-request/03_current_contract_partner/model/occupied_contract_partner.model';
import { FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from '@shared/utils/validators';
import { InvoiceAddressForm } from '@ikp/pages/meter-point-change-request/03_current_contract_partner/model/empty_contract_partner.model';

export const buildPrivateIndividualFormWithDefaultValues = (formBuilder: FormBuilder) => {
  return buildPrivateIndividualForm(formBuilder, null);
};

export const buildPrivateIndividualForm = (formBuilder: FormBuilder, privateIndividualContactData: ContactDataIndividual | null) => {
  return formBuilder.group({
    firstName: [privateIndividualContactData?.firstName ?? '', [Validators.required, CustomValidators.firstNameValidator]],
    lastName: [privateIndividualContactData?.lastName ?? '', [Validators.required, CustomValidators.lastNameValidator]],
    email: [privateIndividualContactData?.email ?? '', [Validators.required, CustomValidators.emailValidator]],
    phoneNumber: [privateIndividualContactData?.phoneNumber ?? '', CustomValidators.optionalPhoneNumberValidator],
  });
};

export const buildOrganizationFormWithDefaultValues = (formBuilder: FormBuilder) => {
  return buildOrganizationForm(formBuilder, null);
};

export const buildOrganizationForm = (formBuilder: FormBuilder, organizationContactData: ContactDataOrganization | null) => {
  return formBuilder.group({
    name: [organizationContactData?.name ?? '', CustomValidators.companyNameValidator],
    firmenbuchNr: [organizationContactData?.firmenbuchNr ?? '', CustomValidators.optionalFirmenbuchNrValidator],
    email: [organizationContactData?.email ?? '', [Validators.required, CustomValidators.emailValidator]],
    phoneNumber: [organizationContactData?.phoneNumber ?? '', CustomValidators.optionalPhoneNumberValidator],
    uid: [organizationContactData?.uid ?? '', CustomValidators.uidValidator],
  });
};

export const buildInvoiceAddressFormWithDefaultValues = (formBuilder: FormBuilder) => {
  return buildInvoiceAddressForm(formBuilder, null);
};

export const buildInvoiceAddressForm = (formBuilder: FormBuilder, invoiceAddress: InvoiceAddressForm | null) => {
  return formBuilder.group({
    company: [invoiceAddress?.company ?? '', CustomValidators.companyNameValidator],
    address: buildAddressForm(formBuilder, invoiceAddress?.address),
  });
};

export const buildAddressFormWithDefaultValues = (formBuilder: FormBuilder) => {
  return buildAddressForm(formBuilder, null);
};

export const buildAddressForm = (formBuilder: FormBuilder, address: AddressForm | null) => {
  return formBuilder.group({
    plz: [address?.plz ?? '', [Validators.required, CustomValidators.plzValidator]],
    city: [address?.city ?? '', [Validators.required, CustomValidators.cityValidator]],
    street: [address?.street ?? '', [Validators.required, CustomValidators.streetValidator]],
    houseNumberStaircase: [address?.houseNumberStaircase ?? '', [Validators.required, CustomValidators.houseNumberStaircaseValidator]],
    door: [address?.door ?? '', [CustomValidators.doorNumberValidator]],
  });
};
