import { PRODUCTS_ROUTE } from './navigation.constants';

export const DEFAULT_ROUTING_PATHS = (appPathPrefix?: string): any[] => {
  const redirectPrefix = appPathPrefix ? '/' + appPathPrefix + '/' : '';
  return [
    {
      path: '**',
      routeId: 'defaultPaths',
      redirectTo: redirectPrefix + PRODUCTS_ROUTE,
    },
  ];
};
