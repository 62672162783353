export interface ICMSContent {
  id: any;
  key: string;
  showCMS: boolean;
  content: string;
}

export class CMSContent implements ICMSContent {
  constructor(public id: any, public key: string, public showCMS: boolean, public content: string) {
    this.id = id;
    this.key = key;
    this.showCMS = showCMS;
    this.content = content;
  }
}
