import { AbstractControl, ValidatorFn } from '@angular/forms';

export function notEqualValidator(notEqualValue): ValidatorFn {
  return (c: AbstractControl): { [key: string]: any | null } => {
    if (c?.value == notEqualValue) {
      return { equal: true };
    }

    return null;
  };
}
