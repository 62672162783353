import { Component, inject, OnInit, Signal } from '@angular/core';
import { IWizardStepComponent } from '@mwe/models';
import { UiComponentsModule } from '@mwe/ui/components';
import { MeterPointChangeSignalState } from '@ikp/pages/meter-point-change-request/meterpoint-change-request.types';
import { SignalState } from '@shared/service/signal-state-service/signal-state.service';
import { TranslateService } from '@ngx-translate/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ROUTE_HOME, ROUTE_METER_POINTS } from '@ikp/app.constants';
import { PlatformLocation } from '@angular/common';

interface BulletPoint {
  title: string;
  description: string;
}
@Component({
  selector: 'app-ikp-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrl: './confirmation.component.scss',
  standalone: true,
  imports: [UiComponentsModule],
})
export class ConfirmationComponent implements IWizardStepComponent, OnInit {
  protected wizardStore: MeterPointChangeSignalState = inject(SignalState);
  protected translation = inject(TranslateService);
  protected platformLocation = inject(PlatformLocation);
  bulletPoints: Signal<BulletPoint[]> = toSignal(this.translation.get('meterPoint.meterPointChangeRequest.confirmation.bulletPoints'));

  ngOnInit() {
    history.pushState(null, '', location.href);
    this.platformLocation.onPopState(() => {
      history.pushState(null, '', `/${ROUTE_HOME}/${ROUTE_METER_POINTS}`);
    });
  }

  hasAllNeededData(): boolean {
    return true;
  }

  submit(): Promise<void> {
    return Promise.resolve(undefined);
  }

  validate(): boolean {
    return true;
  }
}
