import { BlockNavigationIfChange } from '@mwe/models';

export const canDeactivateBlockNavigationIfChange = async <T extends BlockNavigationIfChange>(component: T) => {
  if (component.hasChanges()) {
    await component.onChangedNavigation();
    return component.userConfirmsExit();
  }

  return true;
};
