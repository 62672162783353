import { AddressForm } from '@ikp/pages/meter-point-change-request/03_current_contract_partner/model/occupied_contract_partner.model';

export const EMPTY_CONTRACT_PARTNER_KEY = 'emptyContractPartner';

export class EmptyContractPartner {
  company: string;
  customerNumber: string;
  address: AddressForm;
  invoiceAddress: InvoiceAddressForm | null;
}

export class InvoiceAddressForm {
  company: string;
  address: AddressForm;
}
