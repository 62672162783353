import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { PRODUCTS_ROUTE } from '@mwe/constants';
import { AccountLogic } from '../account/account.logic';

export const impersonateAccessService: CanActivateFn = () => {
  const router = inject(Router);
  const accountLogic = inject(AccountLogic);

  if (accountLogic.isImpersonate()) {
    return router.parseUrl(`/${PRODUCTS_ROUTE}`);
  }
  return true;
};
