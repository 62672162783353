export interface Identity {
  sub: string;
  email: string;
  exp: number;
  family_name: string;
  given_name: string;
  iss: string;
  lastLogin: number;
  preferred_username: string;
  name: string;
  session_state: string;
  typ: string;
  impersonator?: { username: string };
  salutation?: string;
  groups: string[];
}

export interface LoadUserProfileResponse {
  info: Identity;
}
