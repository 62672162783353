import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter, withInMemoryScrolling } from '@angular/router';
import { appRoutes } from './app.routes';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, provideHttpClient, withInterceptors } from '@angular/common/http';
import { AccountLogic, AccountStateService, EnvironmentService } from '@mwe/services';
import { APPLICATION_NAME, DEFAULT_POPUP_COMPONENT_TOKEN, ENVIRONMENT_SERVICE_TOKEN, LOCATION_TOKEN } from '@mwe/constants';
import { provideNgxWebstorage, withLocalStorage, withSessionStorage, withNgxWebstorageConfig } from 'ngx-webstorage';
import { OAuthModule, OAuthService, UrlHelperService } from 'angular-oauth2-oidc';
import { createTranslateLoader } from '@mwe/utils';
import { IkpEnvironmentService } from './service/environment/ikp-environment.service';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import keycloakConfig from './config/keycloak.config';
import { PopupComponent } from '@mwe/ui/components';
import { authInterceptor } from './interceptor/auth.interceptor';

function initKeycloak(keycloak: KeycloakService) {
  return async () => {
    await keycloak.init({
      config: keycloakConfig,
      initOptions: {
        onLoad: 'check-sso',
        checkLoginIframe: false,
      },
    });
  };
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      appRoutes,
      withInMemoryScrolling({
        scrollPositionRestoration: 'top',
      }),
    ),
    provideNgxWebstorage(
      withNgxWebstorageConfig({ prefix: 'ikp', separator: '_', caseSensitive: true }),
      withLocalStorage(),
      withSessionStorage(),
    ),
    importProvidersFrom(
      TranslateModule.forRoot({
        defaultLanguage: 'de',
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient],
        },
      }),
      OAuthModule.forRoot(),
      KeycloakAngularModule,
    ),
    provideHttpClient(withInterceptors([authInterceptor])),
    { provide: APPLICATION_NAME, useValue: 'MWE' },
    { provide: LOCATION_TOKEN, useValue: 'local' },
    { provide: EnvironmentService, useClass: IkpEnvironmentService },
    { provide: ENVIRONMENT_SERVICE_TOKEN, useClass: EnvironmentService },
    { provide: AccountStateService, useClass: AccountStateService },
    { provide: AccountLogic, useClass: AccountLogic },
    { provide: OAuthService, useClass: OAuthService },
    { provide: UrlHelperService, useClass: UrlHelperService },
    { provide: KeycloakService, useClass: KeycloakService },
    { provide: DEFAULT_POPUP_COMPONENT_TOKEN, useValue: PopupComponent },
    {
      provide: APP_INITIALIZER,
      useFactory: initKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
  ],
};
