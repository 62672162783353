import { UiComponentsModule } from '@mwe/ui/components';
import { CommonModule } from '@angular/common';
import { Component, computed, inject } from '@angular/core';
import { MeterPointChangeSignalState } from '@ikp/pages/meter-point-change-request/meterpoint-change-request.types';
import { SignalState } from '@shared/service/signal-state-service/signal-state.service';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ALTERNATIVE_INVOICING_ADDRESS_KEY } from '@ikp/pages/meter-point-change-request/04_new_contract_partner/new-contract-partner.utils';
import { InvoiceAddressFormComponent } from '@ikp/pages/meter-point-change-request/03_current_contract_partner/components/invoice-company-address/invoice-address-form.component';
import { buildInvoiceAddressForm } from '@ikp/pages/meter-point-change-request/03_current_contract_partner/current-contract-partner-form-builder';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-alternative-invoicing',
  imports: [UiComponentsModule, CommonModule, FormsModule, ReactiveFormsModule, InvoiceAddressFormComponent],
  templateUrl: './alternative-invoicing.component.html',
  standalone: true,
})
export class AlternativeInvoicingComponent {
  private formBuilder = inject(FormBuilder);
  private wizardStore: MeterPointChangeSignalState = inject(SignalState);

  protected addressFromGroup = this.formBuilder.group({}, { validators: [] });
  protected alternativeInvoicingAddress = computed(() => this.wizardStore.state().newContractPartner.alternativeInvoicingAddress);
  protected isChecked = computed(() => !!this.alternativeInvoicingAddress());

  constructor() {
    if (this.alternativeInvoicingAddress()) {
      this.addressFromGroup.addControl(this.ADDRESS_KEY, buildInvoiceAddressForm(this.formBuilder, this.alternativeInvoicingAddress()));
    }

    this.addressFromGroup.valueChanges.pipe(takeUntilDestroyed()).subscribe(() => {
      const newValue = this.addressFromGroup.get(this.ADDRESS_KEY)?.value;
      if (this.isChecked() && newValue) {
        this.wizardStore.patchState({
          newContractPartner: {
            ...this.wizardStore.state().newContractPartner,
            alternativeInvoicingAddress: newValue,
          },
        });
      }
    });
  }

  public get validForSubmission(): boolean {
    this.addressFromGroup.markAllAsTouched();
    this.addressFromGroup.updateValueAndValidity();
    return this.addressFromGroup.valid;
  }

  protected alternativeInvoicingAddressChange(alternativeInvoicingAddress: Event): void {
    const isChecked = (alternativeInvoicingAddress.target as HTMLInputElement).checked;

    if (isChecked) {
      this.addressFromGroup.addControl(this.ADDRESS_KEY, buildInvoiceAddressForm(this.formBuilder, null));
      this.wizardStore.patchState({
        newContractPartner: {
          ...this.wizardStore.state().newContractPartner,
          alternativeInvoicingAddress: this.addressFromGroup.get(this.ADDRESS_KEY)?.value,
        },
      });
    } else {
      this.addressFromGroup.removeControl(this.ADDRESS_KEY);
      this.wizardStore.patchState({
        newContractPartner: {
          ...this.wizardStore.state().newContractPartner,
          alternativeInvoicingAddress: null,
        },
      });
    }
  }

  protected readonly ADDRESS_KEY = ALTERNATIVE_INVOICING_ADDRESS_KEY;
}
