<h2 class="h4 pb-2">{{ 'meterPoint.meterPointChangeRequest.productAndDateStep.title' | translate }}</h2>
<div class="pb-3">
  <p [innerHTML]="'meterPoint.meterPointChangeRequest.productAndDateStep.addressInfo' | translate: translateParams" class="mb-0"></p>
  {{ 'meterPoint.meterPointChangeRequest.productAndDateStep.meterPointChangeQuestion' | translate }}
</div>
<form [formGroup]="this.form">
  @for (meterPoint of meterPoints(); track meterPoint.id) {
    <app-product-selection
      class="mt-2"
      [meterPoint]="meterPoint"
      [parentForm]="form"
      [hasProductsSelectedError]="hasProductsSelectedError()"
      [isPreselectedInitially]="isPreselected(meterPoint)"
    ></app-product-selection>
  }
  @if (hasProductsSelectedError()) {
    <div class="d-flex align-items-center mt-2 pl-2 mb-2">
      <i class="fas fa-circle-exclamation text-danger-no-product mr-2"></i>
      <div class="font-size-13 text-danger-no-product">
        {{ 'meterPoint.meterPointChangeRequest.productAndDateStep.noProductSelection' | translate }}
      </div>
    </div>
  }
  <mwe-basic-card headingNodeType="h4" cardTitle="meterPoint.meterPointChangeRequest.productAndDateStep.meterPointChangeDateTitle">
    {{ 'meterPoint.meterPointChangeRequest.productAndDateStep.meterPointChangeDate' | translate }}
    <div class="row py-3">
      <div class="col-12 col-md-6">
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            formControlName="reRegistrationDateSelection"
            id="radioPossibleNextDate"
            [value]="ReRegistrationDateType.NEXT_POSSIBLE_DATE"
          />
          <label class="form-check-label" for="radioPossibleNextDate">{{
            'meterPoint.meterPointChangeRequest.productAndDateStep.possibleNextDate' | translate
          }}</label>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            formControlName="reRegistrationDateSelection"
            id="radioPreferredDate"
            [value]="ReRegistrationDateType.SPECIFIC_DATE"
          />
          <label class="form-check-label" for="radioPreferredDate">{{
            'meterPoint.meterPointChangeRequest.productAndDateStep.preferredDate' | translate
          }}</label>
        </div>
        @if (ReRegistrationDateType.SPECIFIC_DATE === this.form.get('reRegistrationDateSelection').value) {
          <lib-ikp-datepicker
            [datePickerConfig]="reRegistrationDateModelDatepickerConfig"
            [minimumDate]="minReRegistrationDate"
            [maximumDate]="maxReRegistrationDate"
            [isInvalidSelection]="!isReRegistrationDateValid()"
            [invalidDateMessageKey]="invalidDateMessageKey()"
          />
          <lib-ikp-helper-text [helperText]="'meterPoint.meterPointChangeRequest.productAndDateStep.dateRestrictionHint' | translate" />
        }
      </div>
    </div>
  </mwe-basic-card>
</form>
