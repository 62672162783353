import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  ContractPartnerCompanyForm,
  ContractPartnerCompanyModel,
} from '@ikp/pages/meter-point-change-request/04_new_contract_partner/new-contract-partner.utils';
import { CustomValidators } from '@shared/utils/validators';

export const buildOrganizationForm = (formBuilder: FormBuilder, model?: ContractPartnerCompanyModel): FormGroup =>
  formBuilder.group<ContractPartnerCompanyForm>({
    name: formBuilder.control(model?.name || '', [Validators.required, CustomValidators.companyNameValidator]),
    birthDate: formBuilder.control(model?.birthDate || '', [Validators.required]),
    uidNumber: formBuilder.control(model?.uidNumber || '', [Validators.required, CustomValidators.uidValidator]),
    firmenbuchNumber: formBuilder.control(model?.firmenbuchNumber || '', [CustomValidators.optionalFirmenbuchNrValidator]),
    email: formBuilder.control(model?.email || '', [Validators.required, CustomValidators.emailValidator]),
    phone: formBuilder.control(model?.phone || '', [CustomValidators.optionalPhoneNumberValidator]),
  });
