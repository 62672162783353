<form [formGroup]="addressForm">
  <div class="row pb-3 pb-xl-5">
    <div class="col-12 pb-3 col-xl-6 pb-xl-0">
      <div class="form-floating">
        <input
          formControlName="plz"
          class="form-control"
          id="addUserInputPlz"
          placeholder="{{ 'meterPoint.meterPointChangeRequest.address.plzHint' | translate }}"
          type="text"
          inputmode="numeric"
          pattern="\d*"
          [ngClass]="{ 'is-invalid': isInputInvalid(controlName() + '.plz') }"
        />
        <label for="addUserInputPlz">{{ 'meterPoint.meterPointChangeRequest.address.plz' | translate }}</label>
        <div class="invalid-feedback">{{ getFormError(controlName() + '.plz') | translate }}</div>
      </div>
    </div>
    <div class="col-12 col-xl-6">
      <div class="form-floating">
        <input
          formControlName="city"
          class="form-control"
          id="addUserInputCity"
          type="text"
          [ngClass]="{ 'is-invalid': isInputInvalid(controlName() + '.city') }"
        />
        <label for="addUserInputCity">{{ 'meterPoint.meterPointChangeRequest.address.city' | translate }}</label>
        <div class="invalid-feedback">{{ getFormError(controlName() + '.city') | translate }}</div>
      </div>
    </div>
  </div>
  <div class="row pb-3 pb-xl-5">
    <div class="col-12 pb-3 col-xl-6 pb-xl-0">
      <div class="form-floating">
        <input
          formControlName="street"
          class="form-control"
          id="addUserInputStreet"
          type="text"
          [ngClass]="{ 'is-invalid': isInputInvalid(controlName() + '.street') }"
        />
        <label for="addUserInputStreet">{{ 'meterPoint.meterPointChangeRequest.address.street' | translate }}</label>
        <div class="invalid-feedback">{{ getFormError(controlName() + '.street') | translate }}</div>
      </div>
    </div>
    <div class="col-12 col-xl-6">
      <div class="form-floating">
        <input
          formControlName="houseNumberStaircase"
          class="form-control"
          id="addUserInputHouseNumberStaircase"
          type="text"
          [ngClass]="{ 'is-invalid': isInputInvalid(controlName() + '.houseNumberStaircase') }"
        />
        <label for="addUserInputHouseNumberStaircase">{{
          'meterPoint.meterPointChangeRequest.address.houseNumberStaircase' | translate
        }}</label>
        <div class="invalid-feedback">{{ getFormError(controlName() + '.houseNumberStaircase') | translate }}</div>
      </div>
    </div>
  </div>
  <div class="row pb-3 pb-xl-5">
    <div class="col-12 pb-3 col-xl-6 pb-xl-0">
      <div class="form-floating">
        <input
          formControlName="door"
          class="form-control"
          id="addUserInputDoor"
          type="text"
          [ngClass]="{ 'is-invalid': isInputInvalid(controlName() + '.door') }"
        />
        <label for="addUserInputDoor">{{ 'meterPoint.meterPointChangeRequest.address.door' | translate }}</label>
        <div class="invalid-feedback">{{ getFormError(controlName() + '.door') | translate }}</div>
      </div>
    </div>
  </div>
</form>
