import { EventEmitter } from '@angular/core';
import { IWizardStepAlert } from './wizard.model';

export interface IWizardStepComponent {
  onShowAlert?: EventEmitter<IWizardStepAlert>;
  onAutoTriggerNextBtn?: EventEmitter<boolean>;
  onNextStep?: EventEmitter<void>;
  onSkipStep?: EventEmitter<void>;
  onDisableButtons?: EventEmitter<boolean>;
  onHideButtons?: EventEmitter<boolean>;
  onHidePrevButton?: EventEmitter<boolean>;
  onHideNextButton?: EventEmitter<boolean>;
  onDisableNextButton?: EventEmitter<boolean>;
  onOverwriteNextButtonLabel?: EventEmitter<string>;
  onOverwriteImageVisibility?: EventEmitter<boolean>;
  onHideTitle?: EventEmitter<boolean>;
  onOverwriteSubTitle?: EventEmitter<string>;
  onHideProgressBar?: EventEmitter<boolean>;

  getTrackingData?(): ITrackingData;

  submit(): Promise<void>; // custom logic for next btn
  cancel?(): boolean; // custom logic for prev btn, return true to prevent wizard-logic
  hasAllNeededData(): boolean;

  validate(): boolean;

  setNavigationDirection?(isNavigationDirectionForward: boolean): void;

  useNavigatingBackTo?(): boolean;

  additionalPrevStepLogic?(): void;
}

export interface ITrackingData {
  cancellationAction: string;
  event: string;
  processStep: string;
}

export interface INewClientTrackingData extends ITrackingData {
  products: INewClientTrackingDataProduct[];
  voucher: string;
  relocation: boolean;
  dateSelection: string;
  sepa: boolean;
  eRechnung: boolean;
}

export interface ITariffChangeTrackingData extends ITrackingData {
  products: ITariffChangeTrackingDataProduct[];
  voucher: string;
  sepa: boolean;
  eRechnung: boolean;
}

export interface ITariffChangeTrackingDataProduct {
  productType: string;
  productSelected: boolean;
  tarifNameOld: string;
  tarifKeyOld: string;
  tarifNameUrl: string;
  tarifKeyUrl: string;
  tarifNameSelected: string;
  tarifKeySelected: string;
  tarifOptionen: Record<string, string>[];
}

export interface INewClientTrackingDataProduct {
  productType: string;
  productSelected: boolean;
  tarifNameSelected: string;
  tarifKeySelected: string;
  tarifNameUrl: string;
  tarifKeyUrl: string;
  tarifOptionen: Record<string, string>[];
}
