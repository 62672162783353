import { CampaignTariffSelectionData, CampaignTariffSelectionVoucherData } from '@mwe/models';

export function checkIfCampaignTariffSelectionVoucherDataIsEnabled(
  data: CampaignTariffSelectionData,
  tariffKeys: string[],
): CampaignTariffSelectionData {
  if (!data) {
    return null;
  }

  const isValidTariff = tariffKeys?.some(t => data?.voucher?.isEnabledFor?.some(e => e === t));
  return {
    ...data,
    voucher: isValidTariff ? data.voucher : undefined,
  };
}
