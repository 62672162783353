import { ProductDetailsStaticDataDiscountUnit, ProductDetailsStaticDataTariffCardData } from '../static-content/static-content.model';
import { ServiceStateEnum } from '@mwe/constants';

export interface ContractPrices {
  vertragsnummer: string;
  anlageId: string;
  preisbestandteil: ContractPriceComponent[];
}

export interface ContractPriceComponent {
  preistyp: 'AP' | 'GP';
  beschreibung: string;
  preisZone: string;
  preisZoneVon: string;
  preisZoneBis: string;
  wert: number;
  einheit: string;
  inkludiertUmsatzsteuer: boolean;
  inkludiertGebrauchsabgabe: boolean;
  serviceArt: ContractPriceComponentServiceType[];
  deckelung: false;
}

export interface ContractPriceComponentServiceType {
  serviceTyp: string;
  bezeichnung: string;
}

export interface ContractInfo {
  vertragsnummer: string;
  verrechnungssKonto: string;
  geschaeftsPartnerId: string;
  sparte: string;
  vertragsbeginn: string;
  vertragsende: string;
  einzugsDatum: string;
  auszugsDatum: string;
  vertragsstatus: string;
  kuendigungsFrist: number;
  kuendigungsFristEinheit: string;
  kuendigungsDatum: string;
  verlaengerungsDatum: string;
  verlaengerungsFrist: number;
  verlaengerungsFristEinheit: string;
  gemeinsameFakturierung: string;
  abrechnungsKonstrukt: ContractAbrechnungsKonstrukt;
  bestellteProdukte: ContractProducts[];
}

export interface ContractAbrechnungsKonstrukt {
  id: string;
  idBezeichnung: string;
  versorgteEinheit: {
    verbrauchsstelle: {
      verbrauchsstelleId: string;
    };
  };
  zaehlpunkt: {
    zaehlpunktnummer: string;
  };
  konditionen: ContractDiscountResponse[];
}

export interface ContractProducts {
  produkt: {
    tarif: {
      ISUTarifKey: string;
      tarifName: string;
    };
  };
}

// is mapped into Interface with english properties by ContractService
export interface ContractDiscountResponse {
  typ: string;
  operand: string;
  bezeichnung: string;
  faktor: string;
  gueltigVon: string;
  gueltigBis: string;
}

export interface ContractDiscount {
  type: string;
  operand: string;
  description: string;
  factor: number;
  validFrom: string;
  validTo: string;
}

export interface ContractDiscountViewItem {
  name: string;
  value: string;
  unit: ProductDetailsStaticDataDiscountUnit;
  validFrom: string;
  validTo: string;
}

export interface TariffCardStore {
  state: ServiceStateEnum;
  viewModel: ViewModel;
  hasError: boolean;
}

export interface ViewModel {
  taxInfo: string;
  discounts: ContractDiscount[];
  arbeitsPreis: ContractPriceComponent;
  arbeitsPreisDiscounted: number;
  grundPreis: ContractPriceComponent;
  grundPreisDiscounted: number;
  hasActiveDiscounts: boolean;
  discountItems: ContractDiscountViewItem[];
  staticCardData: ProductDetailsStaticDataTariffCardData;
  discountUnits: ProductDetailsStaticDataDiscountUnit[];
  allowedDiscounts: string[];
}
