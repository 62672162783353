import { UiComponentsModule } from '@mwe/ui/components';
import { CommonModule } from '@angular/common';
import { Component, computed, inject, OnInit } from '@angular/core';
import {
  ContractPartnerType,
  meterPointChangeContractPartnerOptions,
} from '@ikp/pages/meter-point-change-request/meter-point-change-request.utils';
import { MeterPointChangeSignalState } from '@ikp/pages/meter-point-change-request/meterpoint-change-request.types';
import { SignalState } from '@shared/service/signal-state-service/signal-state.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-contract-partner-type',
  imports: [UiComponentsModule, CommonModule, FormsModule, ReactiveFormsModule],
  templateUrl: './contract-partner-type.component.html',
  standalone: true,
})
export class ContractPartnerTypeComponent implements OnInit {
  protected wizardStore: MeterPointChangeSignalState = inject(SignalState);
  protected selectedContractPartnerType = computed(() => this.wizardStore.state().newContractPartner.formData?.partnerType);

  ngOnInit() {
    //when switching from OCCPUPIED_TO_EMPTY to XXX_TO_OCCUPIED, reset the form data so a default partner type is selected
    if (!this.wizardStore.state().newContractPartner.formData) {
      this.wizardStore.patchState({
        newContractPartner: {
          ...this.wizardStore.state().newContractPartner,
          formData: {
            partnerType: ContractPartnerType.PRIVATE,
            privatePartner: null,
          },
        },
      });
    }
  }

  protected selectedContractPartnerChange(contractPartnerType: ContractPartnerType): void {
    this.wizardStore.patchState({
      newContractPartner: {
        ...this.wizardStore.state().newContractPartner,
        formData: {
          partnerType: contractPartnerType,
          ...(contractPartnerType === ContractPartnerType.PRIVATE ? { organizationPartner: null } : { privatePartner: null }),
        },
      },
    });
  }

  protected readonly options = meterPointChangeContractPartnerOptions;
}
