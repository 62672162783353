<form [formGroup]="form">
  <div class="row pb-3 pb-lg-5">
    <div class="col-12 pb-3 col-xl-6 pb-xl-0">
      <div class="form-floating">
        <input
          formControlName="firstName"
          class="form-control"
          id="addUserInputFirstName"
          type="text"
          [ngClass]="{ 'is-invalid': isInputInvalid('firstName') }"
        />
        <label for="addUserInputFirstName">
          {{ 'meterPoint.meterPointChangeRequest.currentContractPartnerStep.contactData.firstName' | translate }}
        </label>
        <div class="invalid-feedback">{{ getFormError('firstName') | translate }}</div>
      </div>
    </div>
    <div class="col-12 col-xl-6">
      <div class="form-floating">
        <input
          formControlName="lastName"
          class="form-control"
          id="addUserInputLastName"
          type="text"
          [ngClass]="{ 'is-invalid': isInputInvalid('lastName') }"
        />
        <label for="addUserInputLastName">
          {{ 'meterPoint.meterPointChangeRequest.currentContractPartnerStep.contactData.lastName' | translate }}
        </label>
        <div class="invalid-feedback">{{ getFormError('lastName') | translate }}</div>
      </div>
    </div>
  </div>
  <div class="row pb-3 pb-lg-5">
    <div class="col-12 pb-3 col-xl-6 pb-xl-0">
      <lib-ikp-datepicker
        [datePickerConfig]="birthDateModelDatepickerConfig"
        [minimumDate]="minBirthDate"
        [isInvalidSelection]="!isBirthDateValid()"
        [invalidDateMessageKey]="invalidDateMessageKey"
      />
    </div>
    <div class="col-12 col-xl-6">
      <div class="form-floating">
        <input
          formControlName="email"
          class="form-control"
          placeholder="{{ 'meterPoint.meterPointChangeRequest.currentContractPartnerStep.contactData.emailHint' | translate }}"
          id="addUserInputEmail"
          type="text"
          [ngClass]="{ 'is-invalid': isInputInvalid('email') }"
        />
        <label for="addUserInputEmail">
          {{ 'meterPoint.meterPointChangeRequest.currentContractPartnerStep.contactData.email' | translate }}
        </label>
        <div class="invalid-feedback">
          {{ getFormError('email') | translate }}
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 pb-3 col-xl-6 pb-xl-0">
      <div class="form-floating">
        <input
          formControlName="phone"
          class="form-control"
          placeholder="{{ 'meterPoint.meterPointChangeRequest.currentContractPartnerStep.contactData.phoneNumberHint' | translate }}"
          id="addUserInputPhoneNumber"
          type="text"
          [ngClass]="{ 'is-invalid': isInputInvalid('phone') }"
        />
        <label for="addUserInputPhoneNumber">
          {{ 'meterPoint.meterPointChangeRequest.currentContractPartnerStep.contactData.phoneNumber' | translate }}
        </label>
        <div class="invalid-feedback">
          {{ getFormError('phone') | translate }}
        </div>
      </div>
    </div>
  </div>
</form>
