import { IPopupModel } from '../popup/popup.model';

export interface IUploadConfiguration {
  minAmountOfFiles: number;
  maxAmountOfFiles: number;
  minSizeOfFileInByte: number;
  maxSizeOfFileInByte: number;
  accept: string;
  uploadErrorMsgKey: string;
  showSize?: boolean;
  showImagePreview?: boolean;
  showDeleteButton?: boolean;
}

export interface IUploadDeletePopupConfiguration {
  titleKey: string;
  textKey: string;
  submitKey: string;
  cancelKey: string;
}

export interface IExtendedUploadConfiguration {
  titleKey: string;
  descriptionKey: string;
  processType: string;
  validationErrorKey: string;
  uploadErrorKey: string;
  uploadPopupModel?: IPopupModel;
  deletePopupConfiguration: IUploadDeletePopupConfiguration;
  uploadConfiguration: IUploadConfiguration;
}
