import { AbstractControl, FormGroup } from '@angular/forms';
import { ModelSignal } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-struct';
import { DatepickerConfig } from '@shared/components/datepicker/datepicker.component';
import { getDayJsFromNgbDateStruct } from '@mwe/utils';
import { DATE_FORMAT } from '@mwe/constants';
import { AddressForm } from '@ikp/pages/meter-point-change-request/03_current_contract_partner/model/occupied_contract_partner.model';

export interface ContractPartnerPrivateModel {
  birthDate: string;
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
}

export interface ContractPartnerPrivateForm {
  birthDate: AbstractControl<string>;
  firstName: AbstractControl<string>;
  lastName: AbstractControl<string>;
  email: AbstractControl<string>;
  phone?: AbstractControl<string>;
}

export interface ContractPartnerCompanyModel {
  name: string;
  birthDate?: string;
  uidNumber: string;
  firmenbuchNumber?: string;
  email: string;
  phone?: string;
}

export interface ContractPartnerCompanyForm {
  name: AbstractControl<string>;
  birthDate?: AbstractControl<string>;
  uidNumber: AbstractControl<string>;
  firmenbuchNumber?: AbstractControl<string>;
  email: AbstractControl<string>;
  phone?: AbstractControl<string>;
}

export interface ContractPartnerEmptyModel {
  company: string | null;
  customerNumber: string | null;
  address: AddressForm | null;
}

export const getBirthDateModelDatepickerConfig = (model: ModelSignal<NgbDateStruct>, form: FormGroup): DatepickerConfig => ({
  label: 'meterPoint.meterPointChangeRequest.contractPartnerType.forms.privatePerson.birthDate.label',
  placeholder: 'meterPoint.meterPointChangeRequest.contractPartnerType.forms.privatePerson.birthDate.placeholder',
  currentValue: model,
  onValueChange: value => {
    model.set(value);
    form.get('birthDate').setValue(getDayJsFromNgbDateStruct(value).format(DATE_FORMAT));
  },
});

export const ALTERNATIVE_INVOICING_ADDRESS_KEY = 'newContractPartneralternativeInvoicingAddress';
