<mwe-basic-card [iconClass]="''" [cardTitle]="'meterPoint.meterPointChangeRequest.pdfUpload.title'" [hasBackground]="true">
  <div class="pt-0 pt-xl-3">
    <lib-ikp-file-input
      [config]="fileInputConfiguration"
      [hasMarginBottom]="true"
      (emitFileUpload)="fileUploaded($event)"
    ></lib-ikp-file-input>
  </div>
  @if (showLoadingSpinner()) {
    <mwe-loading-spinner />
  }
</mwe-basic-card>
