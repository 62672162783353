<mwe-basic-card [hasBackground]="false" cardTitle="meterPoint.meterPointChangeRequest.currentContractPartnerStep.cardTitle">
  <form [formGroup]="this.formGroup" tabindex="-1">
    <div class="row pb-3">
      <div class="col-12 pb-3 col-xl-6 pb-xl-0">
        <div class="form-floating">
          @if (emptyContractPartner().company) {
            <strong class="form-control" id="addUserInputCompany">{{ this.formGroup.get('company').value }}</strong>
            <label for="addUserInputCompany">
              {{ 'meterPoint.meterPointChangeRequest.currentContractPartnerStep.empty.company' | translate }}
            </label>
          } @else {
            <input
              formControlName="company"
              class="form-control"
              id="addUserInputCompanyName"
              type="text"
              [ngClass]="{ 'is-invalid': isInputInvalid('emptyContractPartner.company') }"
            />
            <label for="addUserInputCompanyName">
              {{ 'meterPoint.meterPointChangeRequest.currentContractPartnerStep.empty.company' | translate }}
            </label>
            <div class="invalid-feedback">
              {{ 'inputErrors.blank.companyName' | translate }}
            </div>
          }
        </div>
      </div>
      <div class="col-12 col-xl-6">
        <div class="form-floating">
          <strong class="form-control" id="addUserInputCustomerNumber">{{ this.formGroup.get('customerNumber').value }} </strong>
          <label for="addUserInputCustomerNumber">
            {{ 'meterPoint.meterPointChangeRequest.currentContractPartnerStep.empty.customerNumber' | translate }}
          </label>
        </div>
      </div>
    </div>
    <div class="row pb-3">
      <div class="col-12 pb-3 col-xl-6 pb-xl-0">
        <div class="form-floating">
          <strong class="form-control" id="addUserInputAddress">{{ addressFormatted() }}</strong>
          <label for="addUserInputAddress">
            {{ 'meterPoint.meterPointChangeRequest.currentContractPartnerStep.empty.address' | translate }}
          </label>
        </div>
      </div>
    </div>
    <hr />
    <div class="row py-3">
      <div class="col-12">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" formControlName="differentAddress" id="checkboxAddress" />
          <label class="form-check-label" for="checkboxAddress">
            {{ 'meterPoint.meterPointChangeRequest.currentContractPartnerStep.empty.differentInvoiceAddress' | translate }}
          </label>
        </div>
      </div>
    </div>
    @if (this.formGroup.get('differentAddress').value === true) {
      <app-invoice-address-form [controlName]="INVOICE_ADDRESS_KEY" [parentForm]="parentForm()" />
    }
  </form>
</mwe-basic-card>
