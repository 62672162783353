<div class="row pt-2">
  <form [formGroup]="addressFromGroup">
    <div class="form-check" [ngClass]="{ 'pb-5': isChecked() }">
      <input
        id="check-alternative-invoicing-address"
        type="checkbox"
        class="form-check-input"
        [checked]="isChecked()"
        (change)="alternativeInvoicingAddressChange($event)"
      />
      <label class="form-check-label form-check-label-spacing" for="check-alternative-invoicing-address">
        {{ 'meterPoint.meterPointChangeRequest.alternativeInvoicingAddress' | translate }}
      </label>
    </div>
    @if (isChecked()) {
      <app-invoice-address-form [controlName]="ADDRESS_KEY" [parentForm]="addressFromGroup" />
    }
  </form>
</div>
