import { IUploadDeletePopupConfiguration } from '@mwe/models';

export interface FileInputConfiguration {
  titleKey: string;
  descriptionKey: string;
  uploadButtonKey: string;
  uploadErrorMsg: Map<FileUploadError, string> | string;
  minSizeOfFileInByte: number;
  maxSizeOfFileInByte: number;
  accept: string;
  showSize?: boolean;
  showDeleteButton?: boolean;
  deletePopupConfiguration?: IUploadDeletePopupConfiguration;
}

export interface FileModel {
  id: string;
  name: string;
  formattedSize: string;
  size: number;
  base64: string | ArrayBuffer;
  showPreview: boolean;
  showError?: boolean;
  isLoading?: boolean;
  extension?: string;
  originalFile: File;
}

export interface UploadResult {
  uploadedFile: FileModel;
  valid: boolean;
  errors: FileUploadError[];
}

export enum FileUploadError {
  FILE_TOO_LARGE = 'FILE_TOO_LARGE',
  FILE_TOO_SMALL = 'FILE_TOO_SMALL',
  FILE_TYPE_INVALID = 'FILE_TYPE_INVALID',
  COULD_NOT_LOAD_FILE = 'COULD_NOT_LOAD_FILE',
}
