import { EnergyVoucherMapping } from '@mwe/models';
import { getCategorySequenceDiff } from '../products/product-categoriy.utils';

export const sortEnergyVoucherMapping = (mapping: EnergyVoucherMapping[]): void => {
  mapping.sort((a, b) => {
    if (a.address.getHashCode() === b.address.getHashCode()) {
      return getCategorySequenceDiff(a.categories[0], b.categories[0]);
    }

    return a.address.getHashCode() > b.address.getHashCode() ? 1 : -1;
  });
};
