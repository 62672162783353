import { Component, computed, inject } from '@angular/core';
import { IWizardStepComponent } from '@mwe/models';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { OccupiedContractPartnerComponent } from '@ikp/pages/meter-point-change-request/03_current_contract_partner/occupied/occupied_contract_partner.component';
import { EmptyContractPartnerComponent } from '@ikp/pages/meter-point-change-request/03_current_contract_partner/empty/empty_contract_partner.component';
import { MeterPointChangeType } from '@ikp/pages/meter-point-change-request/meter-point-change-request.utils';
import { MeterPointChangeSignalState } from '@ikp/pages/meter-point-change-request/meterpoint-change-request.types';
import { SignalState } from '@shared/service/signal-state-service/signal-state.service';
import {
  convertEmptyFormToModel,
  convertOccupiedFormToModel,
} from '@ikp/pages/meter-point-change-request/03_current_contract_partner/current-contract-partner-form-converter';

@Component({
  selector: 'app-ikp-current-contract-partner',
  standalone: true,
  templateUrl: './current_contract_partner.component.html',
  imports: [OccupiedContractPartnerComponent, ReactiveFormsModule, EmptyContractPartnerComponent],
})
export class CurrentContractPartnerComponent implements IWizardStepComponent {
  protected wizardStore: MeterPointChangeSignalState = inject(SignalState);

  formBuilder = inject(FormBuilder);
  form = this.formBuilder.group({}, { validators: [] });
  selectedChangeType = computed(() => this.wizardStore.state().stepChangeType);

  hasAllNeededData(): boolean {
    return true;
  }

  submit(): Promise<void> {
    if (this.isOccupiedChangeType()) {
      const occupiedContractPartner = convertOccupiedFormToModel(this.form);
      this.wizardStore.patchState({
        currentContractPartner: {
          occupiedContractPartner: occupiedContractPartner,
          emptyContractPartner: null,
        },
      });
    }
    if (this.selectedChangeType() === MeterPointChangeType.EMPTY_TO_OCCUPIED) {
      const emptyContractPartner = convertEmptyFormToModel(this.form);
      this.wizardStore.patchState({
        currentContractPartner: {
          emptyContractPartner: emptyContractPartner,
          occupiedContractPartner: null,
        },
      });
    }

    return Promise.resolve();
  }

  validate(): boolean {
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    return this.form?.valid;
  }

  protected readonly MeterPointChangeType = MeterPointChangeType;

  private isOccupiedChangeType() {
    return (
      this.selectedChangeType() === MeterPointChangeType.OCCUPIED_TO_EMPTY ||
      this.selectedChangeType() === MeterPointChangeType.TENANT_CHANGE
    );
  }
}
