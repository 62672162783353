<form [formGroup]="form">
  <div class="row pb-5">
    <div class="col-12 pb-3 col-xl-6 pb-xl-0">
      <div class="form-floating">
        <input
          formControlName="name"
          class="form-control"
          id="addUserInputName"
          type="text"
          [ngClass]="{ 'is-invalid': isInputInvalid('name') }"
        />
        <label for="addUserInputName">{{ 'meterPoint.meterPointChangeRequest.contractPartnerType.forms.company.name' | translate }}</label>
        <div class="invalid-feedback">{{ getFormError('name') | translate }}</div>
      </div>
    </div>
    <div class="col-12 col-xl-6">
      <lib-ikp-datepicker
        [datePickerConfig]="birthDateModelDatepickerConfig"
        [minimumDate]="minBirthDate"
        [isInvalidSelection]="!isDateValid()"
        [invalidDateMessageKey]="invalidDateMessageKey"
      />
      @if (isDateValid()) {
        <lib-ikp-helper-text
          helperText="meterPoint.meterPointChangeRequest.contractPartnerType.forms.company.birthDateInfo"
          class="position-absolute"
        />
      }
    </div>
  </div>
  <div class="row pb-3 pb-lg-5">
    <div class="col-12 pb-3 col-xl-6 pb-xl-0">
      <div class="form-floating">
        <input
          formControlName="uidNumber"
          class="form-control"
          id="addUserInputUidNumber"
          type="text"
          [ngClass]="{ 'is-invalid': isInputInvalid('uidNumber') }"
          [placeholder]="'meterPoint.meterPointChangeRequest.currentContractPartnerStep.contactData.uidNrHint' | translate"
        />
        <label for="addUserInputUidNumber">{{
          'meterPoint.meterPointChangeRequest.contractPartnerType.forms.company.uidNumber' | translate
        }}</label>
        <div class="invalid-feedback">{{ getFormError('uidNumber') | translate }}</div>
      </div>
    </div>
    <div class="col-12 col-xl-6">
      <div class="form-floating">
        <input
          formControlName="firmenbuchNumber"
          class="form-control"
          id="addUserInputFirmenbuchNumber"
          type="text"
          [ngClass]="{ 'is-invalid': isInputInvalid('firmenbuchNumber') }"
        />
        <label for="addUserInputFirmenbuchNumber">
          {{ 'meterPoint.meterPointChangeRequest.contractPartnerType.forms.company.firmenbuchNumber' | translate }}
        </label>
        <div class="invalid-feedback">
          {{ getFormError('firmenbuchNumber') | translate }}
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 pb-3 col-xl-6 pb-xl-0">
      <div class="form-floating">
        <input
          formControlName="email"
          class="form-control"
          placeholder="{{ 'meterPoint.meterPointChangeRequest.currentContractPartnerStep.contactData.emailHint' | translate }}"
          id="addUserInputEmail"
          type="text"
          [ngClass]="{ 'is-invalid': isInputInvalid('email') }"
        />
        <label for="addUserInputEmail">
          {{ 'meterPoint.meterPointChangeRequest.currentContractPartnerStep.contactData.email' | translate }}
        </label>
        <div class="invalid-feedback">
          {{ getFormError('email') | translate }}
        </div>
      </div>
    </div>
    <div class="col-12 col-xl-6">
      <div class="form-floating">
        <input
          formControlName="phone"
          class="form-control"
          placeholder="{{ 'meterPoint.meterPointChangeRequest.currentContractPartnerStep.contactData.phoneNumberHint' | translate }}"
          id="addUserInputPhoneNumber"
          type="text"
          [ngClass]="{ 'is-invalid': isInputInvalid('phone') }"
        />
        <label for="addUserInputPhoneNumber">
          {{ 'meterPoint.meterPointChangeRequest.currentContractPartnerStep.contactData.phoneNumber' | translate }}
        </label>
        <div class="invalid-feedback">
          {{ getFormError('phone') | translate }}
        </div>
      </div>
    </div>
  </div>
</form>
