import { IFormModel } from '../form/form.model';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

export class IPopupModel {
  id: string;
  titleKey?: string;
  messageKey?: string;
  dismissable?: boolean;
  showSubmitButton?: boolean;
  submitButtonKey?: string;
  showCancelButton?: boolean;
  cancelButtonKey?: string;
  cancelButtonHref?: string;
  iconColorClass?: string;
  iconTypeClass?: string;
  formModel?: IFormModel;
  component?: any;
  componentData?: any;
  disableMobileView?: boolean;
  showAdditionalButtons?: boolean;
  additionalButtons?: IAdditionalButton[];
  additionalWindowClass?: string[];
  useCustomReturnValue?: boolean;
  modalSize?: 'sm' | 'lg' | 'xl' | string;
  subscriptionId?: string;
  modalOpts?: NgbModalOptions;
}

export class IAdditionalButton {
  buttonKey: string;
  buttonHref?: string;
  buttonId?: string;
}
