import { AbstractControl, ValidatorFn } from '@angular/forms';

export function minMaxNumberValidator(min: number, max: number): ValidatorFn {
  return (c: AbstractControl): { [key: string]: any | null } => {
    const numberValue = parseFloat(c?.value);

    if (!numberValue && numberValue !== 0) {
      return null;
    }

    if (numberValue < min) {
      return { minError: true };
    }

    if (numberValue > max) {
      return { maxError: true };
    }

    return null;
  };
}
