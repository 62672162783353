import { Component, inject, input, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, ReactiveFormsModule, FormBuilder } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { UiComponentsModule } from '@mwe/ui/components';
import { buildInvoiceAddressFormWithDefaultValues } from '@ikp/pages/meter-point-change-request/03_current_contract_partner/current-contract-partner-form-builder';

@Component({
  selector: 'app-invoice-address-form',
  templateUrl: './invoice-address-form.component.html',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, UiComponentsModule],
})
export class InvoiceAddressFormComponent implements OnInit, OnDestroy {
  parentForm = input<FormGroup>();
  controlName = input<string>('address');

  private formBuilder = inject(FormBuilder);

  ngOnInit(): void {
    this.parentForm().addControl(this.controlName(), buildInvoiceAddressFormWithDefaultValues(this.formBuilder));
  }

  ngOnDestroy(): void {
    this.parentForm()?.removeControl(this.controlName());
  }

  isInputInvalid(key: string) {
    const form = this.parentForm().get(key);
    return form.touched && form.invalid;
  }

  getFormError(key: string): string {
    const formKey = this.parentForm().get(key);
    if (formKey.errors) {
      return formKey.errors.translationKey;
    }
    return null;
  }

  get invoiceAddressForm(): FormGroup {
    return this.parentForm().get(this.controlName()) as FormGroup;
  }

  get addressForm(): FormGroup {
    return this.parentForm().get(this.controlName()).get('address') as FormGroup;
  }
}
