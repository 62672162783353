export interface IFeatureToggle {
  id: number;
  name: string;
  category: string;
  description: string;
  value: string;
  service?: string;
  applications?: string;
  selectableApps?: string;
}
