export interface MNRUserMessagesResponse {
  messageID: number;
  creationDate: string;
  readDate: string | null;
  subject: string;
  preview: string;
  contentID: number;
  templateDesignator: string;
}

export interface MNRContentResponse {
  messageID: number;
  contentData: string;
  dataPlaceholder: string;
  templateDesignator: string;
}

export type InitialState = {
  messages: MNRUserMessagesResponse[];
  selectedMessage: MNRContentResponse | undefined;
  hasError: boolean;
  showDetails: boolean;
  errorMessage: string;
};

export interface MessageContentItem {
  elementType: string;
  text?: string;
  innerHtml?: string;
  class: string;
  src?: string;
  btnColor?: 'orange' | 'blue' | 'white' | undefined;
  items?: { text: string }[];
  route?: string;
}

export interface Placeholder {
  name: string;
  placeholder: string;
}
