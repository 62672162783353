import { Component, computed, inject, model, ModelSignal, OnInit, signal } from '@angular/core';
import { IWizardStepComponent } from '@mwe/models';
import { MeterPointChangeSignalState } from '@ikp/pages/meter-point-change-request/meterpoint-change-request.types';
import { SignalState } from '@shared/service/signal-state-service/signal-state.service';
import { UiComponentsModule } from '@mwe/ui/components';
import { ProductSelectionComponent } from '@ikp/pages/meter-point-change-request/02_product_and_date/components/product-selection/product-selection.component';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DatepickerComponent, DatepickerConfig } from '@shared/components/datepicker/datepicker.component';
import { HelperTextComponent } from '@shared/components/inputs/helper-text/helper-text/helper-text.component';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-struct';
import {
  ReRegistrationDateType,
  SELECTED_METER_POINT_PRODUCTS,
} from '@ikp/pages/meter-point-change-request/02_product_and_date/components/model/product-selection.model';
import { MeterPointService } from '@ikp/service/meter-point/meter-point.service';
import { MeterPoint } from '@ikp/model/meter-point.model';
import { getAddressStreetAndPostCodeFormatted, getDayJsFromNgbDateStruct, getNgbDateStructFromDayJs } from '@mwe/utils';
import dayjs from 'dayjs';
import { DATE_FORMAT } from '@mwe/constants';

@Component({
  selector: 'app-ikp-product-and-date',
  standalone: true,
  templateUrl: './product-and-date.component.html',
  styleUrl: './product-and-date.component.scss',
  imports: [UiComponentsModule, ProductSelectionComponent, ReactiveFormsModule, DatepickerComponent, HelperTextComponent],
})
export class ProductAndDateComponent implements IWizardStepComponent, OnInit {
  private readonly RE_REGISTRATION_DATE_SELECTION = 'reRegistrationDateSelection';
  private readonly RE_REGISTRATION_DATE_KEY = 'reRegistrationDate';

  protected wizardStore: MeterPointChangeSignalState = inject(SignalState);
  protected meterPoint = computed(() => this.wizardStore.state().meterPoint);
  readonly minReRegistrationDate = getNgbDateStructFromDayJs(dayjs().add(2, 'weeks').add(1, 'day').startOf('day'));
  readonly maxReRegistrationDate = getNgbDateStructFromDayJs(dayjs().add(2, 'months'));

  protected isReRegistrationDateValid = signal(true);
  protected invalidDateMessageKey = signal<string>('inputErrors.blank.datePicker');

  fromDatepickerModel = model<NgbDateStruct>();
  reRegistrationDateModelDatepickerConfig: DatepickerConfig;

  formBuilder = inject(FormBuilder);
  meterPointService = inject(MeterPointService);
  meterPoints = signal<MeterPoint[]>([]);
  form: FormGroup;
  translateParams: { address: string };

  ngOnInit() {
    this.translateParams = { address: getAddressStreetAndPostCodeFormatted(this.meterPoint()?.adresse) };
    const reRegistrationDateSelection = this.wizardStore.state().productAndDate?.reRegistrationDateSelection;
    const reRegistrationDate = this.wizardStore.state().productAndDate?.reRegistrationDate;

    this.form = this.formBuilder.group({
      reRegistrationDateSelection: [reRegistrationDateSelection ?? ReRegistrationDateType.NEXT_POSSIBLE_DATE],
      reRegistrationDate: reRegistrationDate ?? null,
    });

    if (this.form.get(this.RE_REGISTRATION_DATE_KEY).value) {
      this.fromDatepickerModel.set(getNgbDateStructFromDayJs(dayjs(this.form.get(this.RE_REGISTRATION_DATE_KEY).value)));
    }
    this.reRegistrationDateModelDatepickerConfig = this.getReRegistrationModelDatepickerConfig(this.fromDatepickerModel, this.form);
    this.initializeSubscriptions();

    if (this.meterPoint()) {
      this.meterPointService
        .loadMeterPointsOfAddress(this.meterPoint().adresse)
        .subscribe(meterPoints => this.meterPoints.set(meterPoints));
    }
  }

  private initializeSubscriptions(): void {
    this.form.valueChanges.subscribe(() => {
      if (this.form.valid) {
        this.storeReRegistrationDateSelection();
      }
    });

    this.form.get(this.RE_REGISTRATION_DATE_KEY).valueChanges.subscribe(() => this.validateReRegistrationDate());
  }

  hasAllNeededData(): boolean {
    return true;
  }

  submit(): Promise<void> {
    return Promise.resolve(undefined);
  }

  validate(): boolean {
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    this.validateReRegistrationDate();
    return this.form?.valid && !this.hasProductsSelectedError() && this.isReRegistrationDateValid();
  }

  hasProductsSelectedError(): boolean {
    const productsForm = this.form.get(SELECTED_METER_POINT_PRODUCTS) as FormGroup;
    return productsForm && Object.keys(productsForm?.controls).length === 0;
  }

  private storeReRegistrationDateSelection() {
    const reRegistrationDate = this.form.get(this.RE_REGISTRATION_DATE_KEY)?.value;
    const reRegistrationDateSelection = this.form.get(this.RE_REGISTRATION_DATE_SELECTION)?.value;
    this.wizardStore.patchState({
      productAndDate: {
        ...this.wizardStore.state().productAndDate,
        reRegistrationDate: reRegistrationDateSelection === ReRegistrationDateType.SPECIFIC_DATE ? reRegistrationDate : null,
        reRegistrationDateSelection: reRegistrationDateSelection,
      },
    });
  }

  validateReRegistrationDate() {
    const reRegistrationDateType = this.form.get(this.RE_REGISTRATION_DATE_SELECTION)?.value;
    const formValue = this.form.get(this.RE_REGISTRATION_DATE_KEY)?.value;
    if (reRegistrationDateType === ReRegistrationDateType.SPECIFIC_DATE) {
      const isReRegistrationDateValid = this.isDateValid(formValue, this.minReRegistrationDate, this.maxReRegistrationDate);
      this.isReRegistrationDateValid.set(isReRegistrationDateValid);
    } else {
      this.isReRegistrationDateValid.set(true);
    }
  }

  private isDateValid(selectedDate: NgbDateStruct, minDate: NgbDateStruct, maxDate: NgbDateStruct): boolean {
    if (!selectedDate) {
      return false;
    }
    const selectedDateDayJs = getDayJsFromNgbDateStruct(selectedDate);
    const minDateDayJs = getDayJsFromNgbDateStruct(minDate);
    const maxDateDayJs = getDayJsFromNgbDateStruct(maxDate);
    return !selectedDateDayJs.isAfter(maxDateDayJs) && !selectedDateDayJs.isBefore(minDateDayJs);
  }

  getReRegistrationModelDatepickerConfig = (model: ModelSignal<NgbDateStruct>, form: FormGroup): DatepickerConfig => ({
    label: 'meterPoint.meterPointChangeRequest.productAndDateStep.preferredDateLabel',
    placeholder: '',
    currentValue: model,
    onValueChange: value => {
      model.set(value);
      form.get(this.RE_REGISTRATION_DATE_KEY).setValue(getDayJsFromNgbDateStruct(value)?.format(DATE_FORMAT));
    },
  });

  isPreselected = (meterPoint: MeterPoint): boolean => {
    return meterPoint.id === this.meterPoint().id;
  };

  protected readonly ReRegistrationDateType = ReRegistrationDateType;
}
