import { Component, computed, inject, viewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IWizardStepComponent } from '@mwe/models';
import { SignalState } from '@shared/service/signal-state-service/signal-state.service';
import { UiComponentsModule } from '@mwe/ui/components';
import { ContractPartnerTypeComponent } from './components/contract-partner-type/contract-partner-type.component';
import { ContactInformationComponent } from './components/contact-information/contact-information.component';
import { InvoicingInformationComponent } from './components/invoicing-information/invoicing-information.component';
import { AlternativeInvoicingComponent } from './components/alternative-invoicing/alternative-invoicing.component';
import { ContractPartnerEmptyComponent } from './components/contract-partner-empty/contract-partner-empty.component';
import { MeterPointChangeSignalState } from '../meterpoint-change-request.types';
import { MeterPointChangeType } from '../meter-point-change-request.utils';

@Component({
  selector: 'app-ikp-new-contract-partner',
  imports: [
    UiComponentsModule,
    CommonModule,
    AlternativeInvoicingComponent,
    ContractPartnerTypeComponent,
    ContactInformationComponent,
    InvoicingInformationComponent,
    FormsModule,
    ContractPartnerEmptyComponent,
  ],
  templateUrl: './new-contract-partner.component.html',
  standalone: true,
})
export class NewContractPartnerComponent implements IWizardStepComponent {
  protected wizardStore: MeterPointChangeSignalState = inject(SignalState);
  private alternativeInvoicingForm = viewChild(AlternativeInvoicingComponent);
  private contactInformationForm = viewChild(ContactInformationComponent);

  protected readonly changeType = computed<string>(() => this.wizardStore.state().stepChangeType);
  protected readonly emptyContractPartner = computed(() => this.wizardStore.state().currentContractPartner?.emptyContractPartner);

  hasAllNeededData(): boolean {
    return true;
  }

  submit(): Promise<void> {
    return Promise.resolve(undefined);
  }

  validate(): boolean {
    if (this.changeType() !== MeterPointChangeType.OCCUPIED_TO_EMPTY) {
      const isContactInformationFormValid = this.isContactInformationFormValid();
      const isInvoicingInformationFormValid = this.isInvoicingInformationFormValid();
      return isContactInformationFormValid && isInvoicingInformationFormValid;
    }
    return this.isInvoicingInformationFormValid();
  }

  private isContactInformationFormValid(): boolean {
    return this.contactInformationForm().validContactInformationForm;
  }

  private isInvoicingInformationFormValid(): boolean {
    if (this.wizardStore.state().newContractPartner.alternativeInvoicingAddress) {
      return this.alternativeInvoicingForm().validForSubmission;
    }
    return true;
  }

  protected readonly MeterPointChangeType = MeterPointChangeType;
}
