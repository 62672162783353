<mwe-basic-card cardTitle="meterPoint.meterPointChangeRequest.newContractPartner" headingNodeType="h4">
  <div class="d-flex flex-column gap-3 pt-3">
    @if (changeType() === MeterPointChangeType.OCCUPIED_TO_EMPTY) {
      <app-new-contract-partner-empty />
    } @else {
      <app-contract-partner-type />
      <hr />
      <app-contact-information />
      <hr />
      <app-invoicing-information />
    }
    <hr />
    <app-alternative-invoicing />
  </div>
</mwe-basic-card>
