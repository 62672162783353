<mwe-basic-card [hasBackground]="false">
  <div>
    <div class="row mb-4">
      <div class="col my-auto text-center">
        <img
          [src]="'success.svg' | addStaticImagePath"
          class="mt-3"
          [alt]="'meterPoint.meterPointChangeRequest.confirmation.imageAlt' | translate"
        />
      </div>
    </div>
    <div>
      <h1 data-test="ikp-meter-point-change-request-confirmation-title" class="h4 text-center mb-4">
        {{ 'meterPoint.meterPointChangeRequest.confirmation.title' | translate }}
      </h1>
      <h2 data-test="ikp-meter-point-change-request-confirmation-subtitle" class="h4 text-center mb-4">
        {{ 'meterPoint.meterPointChangeRequest.confirmation.subtitle' | translate }}
      </h2>

      @for (bulletPoint of bulletPoints(); track bulletPoint.title; let index = $index) {
        <div class="d-inline-flex">
          <div class="circle mr-3">
            <span class="number">{{ index + 1 }}</span>
          </div>
          <div>
            <div class="h6 pt-3">
              {{ bulletPoint.title | translate }}
            </div>
            <div class="pb-5">
              {{ bulletPoint.description | translate }}
            </div>
          </div>
        </div>
      }
    </div>
  </div>
</mwe-basic-card>
