<form [formGroup]="organizationContactDataForm">
  <div class="row pb-3 pb-xl-5">
    <div class="col-12 pb-3 col-xl-6 pb-xl-0">
      <div class="form-floating">
        <input
          formControlName="name"
          class="form-control"
          id="addUserInputName"
          type="text"
          [ngClass]="{ 'is-invalid': isInputInvalid(ORGANIZATION_CONTACT_DATA_KEY + '.name') }"
        />
        <label for="addUserInputName">{{
          'meterPoint.meterPointChangeRequest.currentContractPartnerStep.contactData.companyName' | translate
        }}</label>
        <div class="invalid-feedback">{{ getFormError(ORGANIZATION_CONTACT_DATA_KEY + '.name') | translate }}</div>
      </div>
    </div>
    <div class="col-12 col-xl-6">
      <div class="form-floating">
        <input
          formControlName="firmenbuchNr"
          class="form-control"
          id="addUserInputCompanyNumber"
          type="text"
          [ngClass]="{ 'is-invalid': isInputInvalid(ORGANIZATION_CONTACT_DATA_KEY + '.firmenbuchNr') }"
        />
        <label for="addUserInputCompanyNumber">{{
          'meterPoint.meterPointChangeRequest.currentContractPartnerStep.contactData.firmenbuchNr' | translate
        }}</label>
        <div class="invalid-feedback">{{ getFormError(ORGANIZATION_CONTACT_DATA_KEY + '.firmenbuchNr') | translate }}</div>
      </div>
    </div>
  </div>
  <div class="row pb-3 pb-xl-5">
    <div class="col-12 col-xl-6 pb-xl-0">
      <div class="form-floating">
        <input
          formControlName="uid"
          class="form-control"
          placeholder="{{ 'meterPoint.meterPointChangeRequest.currentContractPartnerStep.contactData.uidNrHint' | translate }}"
          id="addUserInputUID"
          type="text"
          [ngClass]="{ 'is-invalid': isInputInvalid(ORGANIZATION_CONTACT_DATA_KEY + '.uid') }"
        />
        <label for="addUserInputUID">
          {{ 'meterPoint.meterPointChangeRequest.currentContractPartnerStep.contactData.uidNr' | translate }}
        </label>
        <div class="invalid-feedback">{{ getFormError(ORGANIZATION_CONTACT_DATA_KEY + '.uid') | translate }}</div>
      </div>
    </div>
  </div>
  <div class="row pb-3 pb-xl-5">
    <div class="col-12 pb-3 col-xl-6 pb-xl-0">
      <div class="form-floating">
        <input
          formControlName="email"
          class="form-control"
          placeholder="{{ 'meterPoint.meterPointChangeRequest.currentContractPartnerStep.contactData.emailHint' | translate }}"
          id="addUserInputCompanyEmail"
          type="text"
          [ngClass]="{ 'is-invalid': isInputInvalid(ORGANIZATION_CONTACT_DATA_KEY + '.email') }"
        />
        <label for="addUserInputCompanyEmail">{{
          'meterPoint.meterPointChangeRequest.currentContractPartnerStep.contactData.email' | translate
        }}</label>
        <div class="invalid-feedback">{{ getFormError(ORGANIZATION_CONTACT_DATA_KEY + '.email') | translate }}</div>
      </div>
    </div>
    <div class="col-12 col-xl-6">
      <div class="form-floating">
        <input
          formControlName="phoneNumber"
          class="form-control"
          placeholder="{{ 'meterPoint.meterPointChangeRequest.currentContractPartnerStep.contactData.phoneNumberHint' | translate }}"
          id="addUserInputCompanyPhoneNumber"
          type="text"
          [ngClass]="{ 'is-invalid': isInputInvalid(ORGANIZATION_CONTACT_DATA_KEY + '.phoneNumber') }"
        />
        <label for="addUserInputCompanyPhoneNumber">{{
          'meterPoint.meterPointChangeRequest.currentContractPartnerStep.contactData.phoneNumber' | translate
        }}</label>
        <div class="invalid-feedback">{{ getFormError(ORGANIZATION_CONTACT_DATA_KEY + '.phoneNumber') | translate }}</div>
      </div>
    </div>
  </div>
</form>
