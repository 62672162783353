import { Component, inject, input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { UiComponentsModule } from '@mwe/ui/components';
import { CommonModule } from '@angular/common';
import { AddressFormComponent } from '@ikp/pages/meter-point-change-request/03_current_contract_partner/components/address/address-form.component';
import {
  ContactDataIndividual,
  ContactDataOrganization,
  ContactDataType,
  OCCUPIED_CONTRACT_PARTNER_KEY,
  OccupiedContractPartner,
  ORGANIZATION_CONTACT_DATA_KEY,
  POSTAL_ADDRESS_KEY,
  PRIVATE_INDIVIDUAL_CONTACT_DATA_KEY,
} from '@ikp/pages/meter-point-change-request/03_current_contract_partner/model/occupied_contract_partner.model';
import { OrganizationContactDataComponent } from '@ikp/pages/meter-point-change-request/03_current_contract_partner/occupied/contactdata/organization/organization-contact-data.component';
import { PrivateContactDataComponent } from '@ikp/pages/meter-point-change-request/03_current_contract_partner/occupied/contactdata/private/private-contact-data.component';
import {
  buildAddressForm,
  buildOrganizationForm,
  buildPrivateIndividualForm,
} from '@ikp/pages/meter-point-change-request/03_current_contract_partner/current-contract-partner-form-builder';
import { MeterPointChangeSignalState } from '@ikp/pages/meter-point-change-request/meterpoint-change-request.types';
import { SignalState } from '@shared/service/signal-state-service/signal-state.service';

@Component({
  selector: 'app-occupied-contract-partner',
  standalone: true,
  templateUrl: './occupied_contract_partner.component.html',
  imports: [
    UiComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    AddressFormComponent,
    OrganizationContactDataComponent,
    PrivateContactDataComponent,
  ],
})
export class OccupiedContractPartnerComponent implements OnInit {
  protected wizardStore: MeterPointChangeSignalState = inject(SignalState);
  parentForm = input<FormGroup>();
  contactDataType: ContactDataType = null;
  formBuilder = inject(FormBuilder);
  formGroup: FormGroup;

  ngOnInit(): void {
    const currentOccupiedContractPartner = this.wizardStore.state().currentContractPartner
      ?.occupiedContractPartner as OccupiedContractPartner;
    if (currentOccupiedContractPartner) {
      this.addControlsForOccupiedContractPartner(currentOccupiedContractPartner);
    } else {
      this.formGroup = this.buildOccupiedContractPartnerWithDefaultValues();
      this.parentForm().addControl(OCCUPIED_CONTRACT_PARTNER_KEY, this.formGroup);
    }
    this.toggleValidators(this.parentForm().get('occupiedContractPartner.organizationType').value);
  }

  toggleValidatorsWithAddressReset(organizationType: ContactDataType): void {
    this.resetPostalAddressForm();
    this.toggleValidators(organizationType);
  }

  toggleValidators(organizationType: ContactDataType): void {
    const isPrivateIndividual = organizationType === ContactDataType.PRIVATE_INDIVIDUAL;
    this.contactDataType = isPrivateIndividual ? ContactDataType.PRIVATE_INDIVIDUAL : ContactDataType.ORGANIZATION;
  }

  private resetPostalAddressForm() {
    if (!this.parentForm().get(POSTAL_ADDRESS_KEY)) {
      return;
    }
    this.parentForm().removeControl(POSTAL_ADDRESS_KEY);
    this.parentForm().addControl(POSTAL_ADDRESS_KEY, buildAddressForm(this.formBuilder, null));
  }

  protected readonly ContactDataType = ContactDataType;
  protected readonly POSTAL_ADDRESS_KEY = POSTAL_ADDRESS_KEY;

  private addControlsForOccupiedContractPartner(currentOccupiedContractPartner: OccupiedContractPartner) {
    this.formGroup = this.formBuilder.group({ ...currentOccupiedContractPartner });
    this.parentForm().addControl(OCCUPIED_CONTRACT_PARTNER_KEY, this.formGroup);
    this.addContactDataIndividual(currentOccupiedContractPartner);
    this.addContactDataOrganization(currentOccupiedContractPartner);
    this.parentForm().addControl(POSTAL_ADDRESS_KEY, buildAddressForm(this.formBuilder, currentOccupiedContractPartner.invoiceAddress));
  }

  addContactDataIndividual(currentOccupiedContractPartner: OccupiedContractPartner): void {
    const contactDataIndividual = currentOccupiedContractPartner?.contactDataIndividual as ContactDataIndividual;
    if (contactDataIndividual) {
      this.parentForm().addControl(
        PRIVATE_INDIVIDUAL_CONTACT_DATA_KEY,
        buildPrivateIndividualForm(this.formBuilder, contactDataIndividual),
      );
    }
  }

  addContactDataOrganization(currentOccupiedContractPartner: OccupiedContractPartner): void {
    const contactDataOrganization = currentOccupiedContractPartner?.contactDataOrganization as ContactDataOrganization;
    if (contactDataOrganization) {
      this.parentForm().addControl(ORGANIZATION_CONTACT_DATA_KEY, buildOrganizationForm(this.formBuilder, contactDataOrganization));
    }
  }

  buildOccupiedContractPartnerWithDefaultValues() {
    return this.formBuilder.group({
      organizationType: [ContactDataType.PRIVATE_INDIVIDUAL, Validators.required],
    });
  }
}
