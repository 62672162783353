export interface Account {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  impersonator?: string;
  salutation?: string;
}

export interface IConfirmationStatus {
  isLoggedIn: boolean;
  hasAccount: boolean;
  isPartner: boolean;
}

export interface IChangeEmailModel {
  username: string;
  usernameConfirmation: string;
}

export interface IChangePasswordModel {
  currentPassword: string;
  newPassword: string;
  confirmation: string;
}

export interface IMigrationResult {
  migrationResultStatus: string;
  email: string;
  keycloakId: string;
  actionToken: string;
}

export interface IPersonalInfoModel {
  firstName: string;
  lastName: string;
}
