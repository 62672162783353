import { Component, computed, EventEmitter, inject, Output, signal } from '@angular/core';
import { IWizardStepComponent } from '@mwe/models';
import {
  MeterPointChangeDocumentResponse,
  MeterPointChangeSignalState,
} from '@ikp/pages/meter-point-change-request/meterpoint-change-request.types';
import { SignalState } from '@shared/service/signal-state-service/signal-state.service';
import { TranslatePipe } from '@ngx-translate/core';
import { HeadlineService, UiComponentsModule } from '@mwe/ui/components';
import {
  getZaehlpunktnummerShort,
  meterPointChangeOptions,
  MeterPointChangeType,
} from '@ikp/pages/meter-point-change-request/meter-point-change-request.utils';
import { BasicCardComponent } from '@ikp/components/cards/basic-card/basic-card.component';
import { InfoCardType } from '@shared/model/info-card-type.enum';
import { InfoCardContentFieldComponent } from '@shared/components/cards/info-card/components/info-card-content-field/info-card-content-field.component';
import { Router } from '@angular/router';
import {
  ROUTE_CURRENT_CONTRACT_PARTNER,
  ROUTE_METER_POINT_CHANGE_REQUEST,
  ROUTE_NEW_CONTRACT_PARTNER,
  ROUTE_PRODUCTS_AND_DATE,
} from '@ikp/app.constants';
import { InfoCardSectionComponent } from '@shared/components/cards/info-card/components/info-card-section/info-card-section.component';
import {
  currentEmptyContractPartnerToFields,
  currentOccupiedContractPartnerToFields,
  EMPTY_VALUE,
  newContractPartnerToFields,
  newEmptyContractPartnerToFields,
} from '@ikp/pages/meter-point-change-request/05_summary/summary.utils';
import { getAddressStreetAndPostCodeFormatted } from '@mwe/utils';
import { ReRegistrationDateType } from '@ikp/pages/meter-point-change-request/02_product_and_date/components/model/product-selection.model';
import dayjs from 'dayjs';
import { MeterPointChangeService } from '@ikp/service/meter-point/meter-point-change.service';
import { finalize } from 'rxjs';

export interface InfoCardField {
  label: string;
  value: string;
  contentDivId: string;
}

@Component({
  selector: 'app-ikp-summary',
  templateUrl: './summary.component.html',
  standalone: true,
  providers: [HeadlineService],
  imports: [TranslatePipe, UiComponentsModule, BasicCardComponent, InfoCardContentFieldComponent, InfoCardSectionComponent],
})
export class SummaryComponent implements IWizardStepComponent {
  private readonly DATE_FORMAT_SUMMARY_PAGE = 'DD.MM.YYYY';

  protected wizardStore: MeterPointChangeSignalState = inject(SignalState);
  private router = inject(Router);
  private meterPointChangeService = inject(MeterPointChangeService);

  protected isPDFDownloadShown = computed(() => this.wizardStore.state().stepChangeType !== MeterPointChangeType.OCCUPIED_TO_EMPTY);
  protected isDownloadWarningShown = signal(false);
  protected isPDFDownloaded = signal(false);
  protected isPDFDownloading = signal(false);

  // Cannot use output signals because of base Interface using @Output
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix -- we use the defined name from interface
  @Output() readonly onHidePrevButton = new EventEmitter<boolean>();

  meterPointAddress = computed(() => {
    return getAddressStreetAndPostCodeFormatted(this.wizardStore.state().meterPoint?.adresse);
  });

  changeTypeText = computed(
    () => meterPointChangeOptions.find(changeType => changeType.id === this.wizardStore.state().stepChangeType)?.text,
  );

  productFields = computed<InfoCardField[][]>(() => {
    const productAndDate = this.wizardStore.state().productAndDate;
    return productAndDate?.meterPoints.map(meterPoint => [
      {
        label: 'meterPoint.meterPointChangeRequest.summary.product.productType',
        value: meterPoint?.category || EMPTY_VALUE,
        contentDivId: 'meterpoint-change-summary-producttype',
      },
      {
        label: 'meterPoint.meterPointChangeRequest.summary.product.meterpoint',
        value: getZaehlpunktnummerShort(meterPoint?.meterPointNumber) || EMPTY_VALUE,
        contentDivId: 'meterpoint-change-summary-meterpoint',
      },
      {
        label: 'meterPoint.meterPointChangeRequest.summary.product.meternumber',
        value: meterPoint?.meterNumber || EMPTY_VALUE,
        contentDivId: 'meterpoint-change-summary-meternumber',
      },
      {
        label: 'meterPoint.meterPointChangeRequest.summary.product.meterreading',
        value: `${meterPoint?.meterCount} kWh` || EMPTY_VALUE,
        contentDivId: 'meterpoint-change-summary-meterreading',
      },
    ]);
  });

  changeDateFields = computed<InfoCardField[]>(() => {
    return [
      {
        label: 'meterPoint.meterPointChangeRequest.summary.changeDate.date',
        value: this.getFormattedChangeDate(),
        contentDivId: 'meterpoint-change-summary-changedate',
      },
    ];
  });

  currentPartnerFields = computed<InfoCardField[]>(() => {
    const state = this.wizardStore.state();
    if (state?.currentContractPartner?.emptyContractPartner) {
      return currentEmptyContractPartnerToFields(state.currentContractPartner?.emptyContractPartner);
    } else if (state?.currentContractPartner?.occupiedContractPartner) {
      return currentOccupiedContractPartnerToFields(state.currentContractPartner?.occupiedContractPartner);
    } else {
      return [];
    }
  });

  newPartnerFields = computed<InfoCardField[]>(() => {
    const state = this.wizardStore.state();
    if (state?.newContractPartner?.formData) {
      return newContractPartnerToFields(state.newContractPartner);
    } else if (state?.newContractPartner.emptyPartner) {
      return newEmptyContractPartnerToFields(state.newContractPartner.emptyPartner, state.newContractPartner.alternativeInvoicingAddress);
    } else {
      return [];
    }
  });

  hasAllNeededData(): boolean {
    return true;
  }

  submit(): Promise<void> {
    return Promise.resolve(undefined);
  }

  validate(): boolean {
    this.isDownloadWarningShown.set(!this.isPDFDownloaded());
    return this.isPDFDownloaded();
  }

  private getFormattedChangeDate(): string {
    const state = this.wizardStore.state();
    const reRegistrationDate = state.productAndDate?.reRegistrationDate;
    const formattedDate = dayjs(reRegistrationDate)?.format(this.DATE_FORMAT_SUMMARY_PAGE);
    return state.productAndDate?.reRegistrationDateSelection === ReRegistrationDateType.NEXT_POSSIBLE_DATE
      ? 'meterPoint.meterPointChangeRequest.productAndDateStep.possibleNextDate'
      : `${formattedDate}`;
  }

  public navigateToStepWithSubRoute(stepSubRoute: string) {
    this.router.navigate([ROUTE_METER_POINT_CHANGE_REQUEST, stepSubRoute]);
  }

  protected downloadPdf(): void {
    this.isPDFDownloading.set(true);
    this.meterPointChangeService
      .createDocument(this.wizardStore.state())
      .pipe(finalize(() => this.isPDFDownloading.set(false)))
      .subscribe({
        next: (blobRef: MeterPointChangeDocumentResponse) => {
          this.savePDFFile(blobRef);

          if (this.isPDFDownloaded()) {
            this.onHidePrevButton.emit(true);
            this.isDownloadWarningShown.set(false);
          }
        },
        error: () => this.meterPointChangeService.handleDownloadErrorPopup(),
      });
  }

  private savePDFFile(blobRef: MeterPointChangeDocumentResponse): void {
    const url = URL.createObjectURL(blobRef.blob);
    const a = Object.assign(document.createElement('a'), { href: url, download: blobRef.filename });

    a.click();
    URL.revokeObjectURL(url);
    a.remove();

    this.isPDFDownloaded.set(true);
  }

  protected readonly InfoCardType = InfoCardType;
  protected readonly ROUTE_PRODUCTS_AND_DATE = ROUTE_PRODUCTS_AND_DATE;
  protected readonly ROUTE_CURRENT_CONTRACT_PARTNER = ROUTE_CURRENT_CONTRACT_PARTNER;
  protected readonly ROUTE_NEW_CONTRACT_PARTNER = ROUTE_NEW_CONTRACT_PARTNER;
}
