export interface LogWienMigrationStatus {
  id: number;
  logWienUserId: string;
  weUserId: string;
  email: string;
  consent: boolean;
  createdAt: string;
  mgrStatusBSKW: string;
  mgrStatusTimestampBSKW: string;
  mgrStatusDSGVO: string;
  mgrStatusTimestampDSGVO: string;
  mgrStatusVW: string;
  mgrStatusTimestampVW: string;
  mgrStatusIL: string;
  mgrStatusTimestampIL: string;
  mgrStatusMWE: string;
  mgrStatusTimestampMWE: string;
}

export interface MigrationInfo {
  migrationNeeded: boolean;
  migrationStatus: string;
  migrationDetails: LogWienMigrationStatus;
}

export interface TermOfUseConsentStatus {
  userId: string;
  consent: boolean;
}
