<div class="row">
  <p>{{ 'meterPoint.meterPointChangeRequest.newContractPartnerTypeQuestion' | translate }}</p>
  <div class="row">
    @for (option of options; track option.id) {
      <div class="col-12 col-md-6">
        <div class="form-check">
          <input
            class="form-check-input"
            name="selectedChangeType"
            type="radio"
            [id]="option.id"
            [value]="option.text | translate"
            [checked]="selectedContractPartnerType() === option.id"
            (change)="selectedContractPartnerChange(option.id)"
          />
          <label class="form-check-label form-check-label-spacing" [for]="option.id"> {{ option.text | translate }} </label>
        </div>
      </div>
    }
  </div>
</div>
