import { Injectable, Signal, signal } from '@angular/core';

interface SignalStateConfig<T extends object> {
  initialState: T;
  storageKey?: string;
}

/**
 * Lightweight wrapper around a typed signal, that adds support for typesafe partial updates,
 * immutable read access and optional synchronization with the browser's session storage.
 * See example usage of factory in ./signal-state.utils.ts
 *
 * Disclaimer: This works only when typed by interfaces and types, not classes.
 * In case of classes, the class needs to be extended with a parsing function.
 */

// Safe to ignore as we want ComponentLevel providing (state-sharing)
// eslint-disable-next-line @angular-eslint/use-injectable-provided-in
@Injectable()
export class SignalState<T extends object> {
  private _state = signal<T>({} as T);
  private _config: SignalStateConfig<T>;

  get state(): Signal<T> {
    return this._state.asReadonly();
  }

  initialize(config: SignalStateConfig<T>): void {
    this._config = config;

    if (config.storageKey) {
      if (!this.loadFromSessionStorage()) {
        this._state.set(config.initialState);
        this.saveToSessionStorage(config.initialState);
      }
    } else {
      this._state.set(config.initialState);
    }
  }

  patchState(partialState: Partial<T>): void {
    this._state.update(currentState => {
      const newState = {
        ...currentState,
        ...partialState,
      };

      if (this._config.storageKey) {
        this.saveToSessionStorage(newState);
      }

      return newState;
    });
  }

  reset(): void {
    this._state.set(this._config.initialState);

    if (this._config.storageKey) {
      this.deleteSessionStorage();
    }
  }

  private saveToSessionStorage(state: T): void {
    try {
      sessionStorage.setItem(this._config.storageKey, JSON.stringify(state));
    } catch (error) {
      console.error('Failed to save state to session storage:', error);
    }
  }

  private deleteSessionStorage(): void {
    try {
      sessionStorage.removeItem(this._config.storageKey);
    } catch (error) {
      console.error('Failed to delete session storage key:', error);
    }
  }

  private loadFromSessionStorage(): boolean {
    try {
      const storedData = sessionStorage.getItem(this._config.storageKey);

      if (storedData) {
        const parsedData = JSON.parse(storedData) as T;
        this._state.set(parsedData);
        return true;
      }
    } catch (error) {
      console.error('Failed to load state from session storage:', error);
    }

    return false;
  }
}
