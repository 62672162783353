import { Component, computed, inject } from '@angular/core';
import { IWizardStepComponent } from '@mwe/models';
import { MeterPointChangeSignalState } from '@ikp/pages/meter-point-change-request/meterpoint-change-request.types';
import { SignalState } from '@shared/service/signal-state-service/signal-state.service';
import { UiComponentsModule } from '@mwe/ui/components';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import {
  closeMeterPointChangeRequestWizard,
  meterPointChangeOptions,
  MeterPointChangeType,
  MeterPointChangeTypeOption,
} from '@ikp/pages/meter-point-change-request/meter-point-change-request.utils';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IkpPopupService } from '@ikp/service/popup/ikp-popup.service';

@Component({
  selector: 'app-ikp-select-change-type',
  templateUrl: './select_change_type.component.html',
  styleUrl: './select_change_type.component.scss',
  imports: [CommonModule, UiComponentsModule, FormsModule, ReactiveFormsModule],
  standalone: true,
})
export class SelectChangeTypeComponent implements IWizardStepComponent {
  protected wizardStore: MeterPointChangeSignalState = inject(SignalState);
  protected popupService = inject(IkpPopupService);
  protected translateService = inject(TranslateService);
  protected selectedChangeType = computed(() => this.wizardStore.state().stepChangeType);
  private readonly router = inject(Router);

  protected infoBoxText = computed(() => {
    if (this.selectedChangeType() === MeterPointChangeType.OCCUPIED_TO_EMPTY) return null;

    const firstSection = 'meterPoint.meterPointChangeRequest.infoBox.firstSectionTenants';
    const secondSection = 'meterPoint.meterPointChangeRequest.infoBox.secondSection';

    return `${this.translateService.instant(firstSection)}<br>${this.translateService.instant(secondSection)}`;
  });

  onFormChanged(changeType: MeterPointChangeType): void {
    this.wizardStore.patchState({ stepChangeType: changeType });
  }

  cancel(): boolean {
    closeMeterPointChangeRequestWizard(this.popupService, this.router, this.wizardStore);
    return true;
  }

  // Safe to ignore to prevent wizard from hiding the cancel button
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  additionalPrevStepLogic() {}

  hasAllNeededData(): boolean {
    return true;
  }

  submit(): Promise<void> {
    return Promise.resolve(undefined);
  }

  validate(): boolean {
    return this.selectedChangeType() !== undefined;
  }

  protected options: MeterPointChangeTypeOption[] = meterPointChangeOptions;
}
