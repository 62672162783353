import { VoucherStateService } from './voucher-state.service';
import { IAktionen, LinkListGroupItem, VoucherProzessType } from '@mwe/models';

export interface IVoucherFormConfig {
  voucherStateService: VoucherStateService;
  titleKey?: string;
  codeLabelKey?: string;
  codeErrorKey?: string;
  isCodeMandatory?: boolean;
  checkCodeButtonKey?: string;
  deleteCodeButtonKey?: string;
  invalidCodeMsgKey?: string;
  invalidCodeWLMsgKey?: string;
  wlFieldsTitleKey?: string;
  wlFieldsDescriptionKey?: string;
  wlFieldsDescriptionDigitalKey?: string;
  wlFieldsErrorKey?: string;
  wlFieldsDataUseCommit?: { titleKey: string; labelKey: string; errorKey: string };
  wlFieldsLastName?: { labelKey: string; errorKey: string };
  wlFieldsBirthday?: { labelKey: string; errorKey: string };
  wlFieldsBirthdayFields?: [{ labelKey: string; errorKey: string; placeholderKey: string }];
  wlFieldsCardNumber?: { labelKey: string; errorKey: string; lengthErrorKey: string };
  processCode?: VoucherProzessType;
  initialValue?: IAktionen;
  oldVoucherCodeWarningKey?: string;
  infoText?: string;
  faq?: LinkListGroupItem[];
}
