import { Component, Input, OnInit } from '@angular/core';
import { LANDING_PAGE_EMAIL_INVITATION_ROUTE } from '@mwe/constants';
import { ActivatedRoute, Router } from '@angular/router';
import { AppStorageService, AuthService, EnvironmentService, NavigationLogic, ProfileService, StaticContentService } from '@mwe/services';
import { FrontpageAlertData, FrontpageContentData, FrontpageData } from '@mwe/models';

@Component({
  selector: 'mwe-frontpage-base',
  templateUrl: './base-frontpage.component.html',
  styleUrls: [],
})
export class BaseFrontpageComponent implements OnInit {
  @Input() defaultContent: FrontpageContentData;

  showAuthServerAlert = false;
  showDynamicAlert = false;
  isEmaiInvitationLandingPage = false;
  frontpageData: FrontpageData;
  frontpageAlert: FrontpageAlertData;
  dynamicAlertData: FrontpageAlertData;
  authServerAlertData: FrontpageAlertData;
  isLoaded = false;
  titleBigLabelKey: string;
  wrongPortalKeys: { text: string; link: string; text2: string };
  switchPortalUrl: string;
  constructor(
    private router: Router,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private appStorage: AppStorageService,
    private staticContentService: StaticContentService,
    private profileService: ProfileService,
    private environmentService: EnvironmentService,
    private navigationLogic: NavigationLogic,
  ) {}

  async ngOnInit(): Promise<void> {
    if (this.profileService.getProfileInfo().toggleSetDesignator === 'LP') {
      await this.profileService.loadProfileInfo();
    }
    this.frontpageData = { content: this.defaultContent };

    const guid = this.activatedRoute.snapshot.queryParams['mail'];
    this.isEmaiInvitationLandingPage = !!guid;
    this.appStorage.setEmailInvitation(this.isEmaiInvitationLandingPage);

    this.authServerAlertData = this.appStorage.getAuthServerFailure();
    if (this.authServerAlertData && Object.keys(this.authServerAlertData).length !== 0) {
      this.showAuthServerAlert = true;
    }

    if (window.location.hash.indexOf('logout=success') !== -1) {
      this.showDynamicAlert = true;
      this.dynamicAlertData = {
        type: 'success',
        title: 'home.logoutSuccess.title',
        message: 'home.logoutSuccess.message',
      };
      window.location.hash = '';
    } else if (window.location.hash.indexOf('logout=logwien') !== -1) {
      this.showDynamicAlert = true;
      this.dynamicAlertData = {
        type: 'success',
        title: 'home.logoutSuccess.title',
        message: 'profile.changeEmail.idp.logwien.logout.message',
      };
      window.location.hash = '';
    } else if (window.location.hash.indexOf('logout=automatic') !== -1) {
      this.showDynamicAlert = true;
      this.dynamicAlertData = {
        type: 'success',
        title: 'home.logoutAutomatic.title',
        message: 'home.logoutAutomatic.message',
      };
      window.location.hash = '';
    } else if (window.location.hash.indexOf('logout=new-terms-2023-canceled') !== -1) {
      this.showDynamicAlert = true;
      this.dynamicAlertData = {
        type: 'info',
        title: 'home.logoutLogwienNewTermsCanceled.title',
        message: 'home.logoutLogwienNewTermsCanceled.message',
      };
      window.location.hash = '';
    } else if (window.location.hash.indexOf('logout=migration-2023-canceled') !== -1) {
      this.showDynamicAlert = true;
      this.dynamicAlertData = {
        type: 'info',
        title: 'home.logoutLogwienMigrationCanceled.title',
        message: 'home.logoutLogwienMigrationCanceled.message',
      };
      window.location.hash = '';
    } else if (window.location.hash.indexOf('logout=new-terms-2023-approval-error') !== -1) {
      this.showDynamicAlert = true;
      this.dynamicAlertData = {
        type: 'danger',
        title: 'home.logoutLogwienNewTermsApprovalError.title',
        message: 'home.logoutLogwienNewTermsApprovalError.message',
      };
      window.location.hash = '';
    } else if (window.location.hash.indexOf('login=error') !== -1) {
      this.showDynamicAlert = true;
      this.dynamicAlertData = {
        type: 'danger',
        title: 'home.loginFailure.title',
        message: 'home.loginFailure.message',
      };
      window.location.hash = '';
    } else if (window.location.href.indexOf('session_=') !== -1) {
      window.location.href = '/privat/meine-produkte';
    }

    this.getFrontpageData().then(data => {
      if (!data) {
        return;
      }
      if (data.content?.isEnabled) {
        this.frontpageData = data;
      }
      this.frontpageAlert = this.staticContentService.getFrontpageAlertData();
      this.isLoaded = true;
    });
    this.appStorage.removeRequestedRoute();

    this.titleBigLabelKey = `home.titleBig.${this.environmentService.getPortalCustomerType()}`;
    this.wrongPortalKeys = {
      text: `home.wrongPortal.${this.environmentService.getPortalCustomerType()}.text`,
      link: `home.wrongPortal.${this.environmentService.getPortalCustomerType()}.link`,
      text2: `home.wrongPortal.${this.environmentService.getPortalCustomerType()}.text2`,
    };
    this.switchPortalUrl = this.navigationLogic.getSwitchPortalUrl();
  }

  async getFrontpageData(): Promise<FrontpageData> {
    if (this.router.url.startsWith(`/${LANDING_PAGE_EMAIL_INVITATION_ROUTE}`)) {
      this.isLoaded = true;
      return undefined;
    }

    try {
      return await this.staticContentService.getFrontpageContent();
    } catch (e) {
      // ignore
    }

    return undefined;
  }

  register(): void {
    this.authService.register();
  }

  login(): void {
    this.authService.login();
  }

  navigateToCampaignUri(): void {
    window.location.href = this.frontpageData.content.imageHref;
  }
}
