import { TarifOption } from '../availabilities/availabilities.model';
import { RechnungsAdresse } from '../invoices/invoice-data-change.models';
import { Sparte } from './api.model';

export interface TarifWechselAktionen {
  aktionsCode?: string;
  zusatzInformationen?: any;
}

export interface Tarifwechsel {
  geschaeftsPartnerId?: string;
  vertragsKontoNummer?: string;
  anlageId?: string;
  tarifTyp: string;
  isuTarifKey: string;
  tarifBezeichnung?: string;
  aktionen?: Array<TarifWechselAktionen>;
  ruecktrittsFristAbwarten?: boolean;
  sparte: string;
  tarifOptionen?: TarifOption[];
}

type Adressart = 'Rechnungsadresse' | 'Kontaktadresse' | 'Verbrauchsstellenadresse' | 'Anschlussobjektadresse';

const Adressart = {
  Rechnungsadresse: 'Rechnungsadresse' as Adressart,
  Kontaktadresse: 'Kontaktadresse' as Adressart,
  Verbrauchsstellenadresse: 'Verbrauchsstellenadresse' as Adressart,
  Anschlussobjektadresse: 'Anschlussobjektadresse' as Adressart,
};

export interface Verbrauchsstelle {
  verbrauchsstelle?: RechnungsAdresse;
  verbrauchsstelleId?: string;
}

export interface Anlage {
  anlageId: string;
  anlagentyp?: string;
  anlageInVerarbeitung?: boolean;
  verbrauchsstelle?: Verbrauchsstelle;
}

export interface Vertrag {
  vertragsnummer: string;
  sparte?: Sparte;
  vertragsbeginn?: Date;
  vertragsende?: Date;
  vertragsstatus?: string;
  anlage?: Anlage;
  tarif?: Tarif;
}

export interface Tarif {
  tarifTyp?: string;
  tarifKey: string;
  ISUTarifKey?: string;
  tarifName?: string;
  tarifBeschreibung?: string;
  tarifBild?: string;
  tarifOptionen?: TarifOption[];
}

export interface TarifwechselProdukte {
  tarif?: Tarif;
  sparte?: Sparte;
  kundenTyp?: KundenTypEnum;
  anlage?: Anlage;
  bedingungen?: TarifwechselBedingungen;
}

export type KundenTypEnum = 'PRIVAT' | 'BUSINESS';

export const KundenTypEnum = {
  PRIVAT: 'PRIVAT' as KundenTypEnum,
  BUSINESS: 'BUSINESS' as KundenTypEnum,
};

export interface TarifwechselBedingungen {
  /**
   * Flag, das angibt, ob dieses Produkt nur gewählt werden darf, falls ein SEPA Mandat angegeben wird.
   */
  sepaPflicht?: boolean;
  /**
   * Flag, das angibt, ob dieses Produkt nur gewählt werden darf, wenn sich der Kunde zur PDF Rechnung anmeldet.
   */
  eRechnungsPflicht?: boolean;
  /**
   * Flag, das angibt, ob dieses Produkt online auswählbar ist. Für Strom/Gas erkennbar aus dem Webservice ZWSOTARIF
   * aus der Methode ZwsOnlinetarif aus dem Flag Online.
   */
  onlineTarif?: boolean;
  /**
   * Übernommen von ZwsOtarifGetpossibletarif aus Kompatibilitätsgründen. Noch unklar was Bedeutung dieses Feldes ist.
   */
  preisdaten?: string;
}
