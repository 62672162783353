export interface TariffPrices {
  success: boolean;
  message: string;
  sparte?: string;
  pgdate?: string;
  data?: TariffPricesData;
}

export interface Position {
  bezeichnung: string;
  einheit: string;
  basis: string;
  betrag: string;
}

export interface TariffPricesData {
  energiekosten: { positions: Position[]; summe: string };
  netzkosten: { positions: Position[]; summe: string };
  steuern_abgaben: { positions: Position[]; summe: string };
}
