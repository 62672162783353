import { Route, Routes } from '@angular/router';
import { RouteMapping, RouteMappingOptions } from '@mwe/models';

export function createRouteMap(routes: RouteMappingOptions[]): RouteMapping {
  const routeMap = new Map() as RouteMapping;

  routes.forEach(route => {
    const value: RouteMappingOptions = {
      ...route,
    };

    if (route.children) {
      value.children = createRouteMap(route.children as RouteMappingOptions[]);
    }

    routeMap.set(route.routeId, value);
  });

  return routeMap;
}

export function getRoutesFromMap(routes: RouteMapping): Routes {
  const routesArray = Array.from(routes.values());

  return routesArray.map(route => {
    const value: RouteMappingOptions = {
      ...route,
    };

    if (value.routeId) {
      delete value.routeId;
    }

    if (route.children) {
      value.children = getRoutesFromMap(route.children as RouteMapping);
    }

    return value as Route;
  });
}

export function replaceRoutesInMap(routesMapping: RouteMapping, replacementRouts: RouteMappingOptions[]): RouteMapping {
  replacementRouts.forEach(route => {
    if (routesMapping.has(route.routeId)) {
      routesMapping.set(route.routeId, route);
    }

    if (!routesMapping.has(route.routeId)) {
      routesMapping.forEach(goThroughChildren);
    }

    function goThroughChildren(childRoutes: RouteMappingOptions) {
      if (!childRoutes.children) {
        return;
      }

      const children = childRoutes.children as RouteMapping;
      const hasChild = children.has(route.routeId);
      if (hasChild) {
        children.set(route.routeId, route);
      }

      if (!hasChild) {
        children.forEach(goThroughChildren);
      }
    }
  });

  return routesMapping;
}
