export interface IVkiOfferRequestDTO {
  verrechnungskontoId: string;
  geschaeftsPartnerId: string;
}

export class VkiOfferRequestDTO implements IVkiOfferRequestDTO {
  constructor(
    public verrechnungskontoId: string,
    public geschaeftsPartnerId: string,
  ) {}
}

export type IVkiOfferResponse = { angebote: IVkiOffer[] };

export interface IVkiOffer {
  geschaeftsPartnerId: string;
  verrechnungskontoId: string;
  vertragsnummer: string;
  aktiverVertrag: boolean;
  angebotsNummer: string;
  anlageId: string;
  sparte: string;
  kompansationswertBrutto: string;
  kompansationswertNetto: string;
  kompensationsEinheit: string;
  kompensationsLabel: string;
  gebrauchsAbgabeWien: boolean;
  titel: string;
  vorname: string;
  nachname: string;
  zustimmung: boolean;
  angebotsStatus: boolean;
  abDatum: string;
  bisDatum: string;
  zaehlpunktNummer: string;
  angebotsVariante: string;
  nameRechnungsempfaenger: string;
  adresseRechnungsempfaenger: string;
  emailRechnungsempfaenger: string;
}

export interface IVkiOfferTexts {
  offerTypes: {
    [key: string]: {
      title: string;
      label: string;
    };
  };
  offerError: string;
  endDate: string;
}

export type IVkiOwnerKeys = 'owner' | 'authorized';
