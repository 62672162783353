export interface AnmeldeDetails {
  abonnent: Abonnent;
}

export interface Abonnent {
  anrede: string;
  vorname: string;
  nachname: string;
  email: string;
  kundentyp: string;
  quelle: string;
  interessensgebiete: Interessensgebiete[];
  geloescht: boolean;
}

export interface Interessensgebiete {
  code: string;
  name: string;
  beschreibung?: string;
  kundentyp: string;
  abonniert: boolean;
}

export const NewsletterCategoriesMap: { [key: string]: string } = {
  '516200': 'Nachhaltigkeit & Innovation',
  '516201': 'Aktionen',
  '516202': 'Energieeffizienz / Energiesparen',
  '516205': 'Energiegemeinschaften',
};

export const NewsletterSalutationMap: { [key: string]: string } = {
  Frau: '1',
  Herr: '2',
  Divers: '5',
};
