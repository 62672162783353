import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { FeatureToggleEnum } from '@mwe/constants';
import { ProfileService } from '../profile/profile.service';
import { AppStorageService } from '../cache/app-storage.service';
import { FeatureToggledOutInfo } from '@mwe/models';

export const routeToggleAccessService: CanActivateFn = route => {
  const router = inject(Router);
  const profileService = inject(ProfileService);

  const toggles: FeatureToggleEnum[] = route.data['toggles'];
  const disabledToggleFallbackRoute = route.data['disabledToggleFallbackRoute'];

  if (!Array.isArray(toggles) || toggles.length <= 0) {
    return true;
  }

  if (disabledToggleFallbackRoute) {
    handleToggleFallbackRoute(route);
  }

  const areAllFeatureTogglesEnabled = profileService.areAllFeatureTogglesEnabled(toggles);
  const fallBackRoute = disabledToggleFallbackRoute ?? '';
  return areAllFeatureTogglesEnabled ? true : router.parseUrl(`/${fallBackRoute}`);
};

const handleToggleFallbackRoute = (route: ActivatedRouteSnapshot) => {
  const appStorageService = inject(AppStorageService);
  const data = route.data['toggledOutInfo'] as FeatureToggledOutInfo;

  appStorageService.setCurrentFeatureToggledOutInfo({
    titleOverwrite: data?.titleOverwrite,
    textOverwrite: data?.textOverwrite,
    processTitle: route.data['pageTitle'],
    isCloseBtnVisible: !route.data['hideCloseBtnFeatureToggledOutInfoPage'],
  });
};
