import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { FeatureToggleEnum, NEW_CLIENT_ROUTE, SUPERSCHNELL_ROUTE } from '@mwe/constants';
import { ProfileService } from '../profile/profile.service';
import { NewClientLogic } from '../new-client/new-client.logic';
import { NewClientStateService } from '../new-client/new-client-state.service';
import { TariffSelectionStateService } from '../tariff/selection/tariff-selection-state.service';

export const newClientAccessService: CanActivateFn = (_, state) => {
  const router = inject(Router);
  const profileService = inject(ProfileService);
  const newClientLogic = inject(NewClientLogic);
  const newClientStateService = inject(NewClientStateService);
  const tariffSelectionStateService = inject(TariffSelectionStateService);

  const newClientEnabled = profileService.isFeatureToggleEnabled(FeatureToggleEnum.NEW_CLIENT_ENABLED);
  const newClientInternetEnabled = profileService.isFeatureToggleEnabled(FeatureToggleEnum.NEW_INTERNET_CLIENT_ENABLED);
  if (newClientInternetEnabled || newClientEnabled) {
    const illegalPath =
      (newClientLogic.isSuperschnellProcess(state.url) && state.url.indexOf(SUPERSCHNELL_ROUTE) === -1) ||
      (newClientLogic.isNewClientProccess(state.url) && state.url.indexOf(NEW_CLIENT_ROUTE) === -1);

    if (illegalPath) {
      newClientStateService.clearData();
      tariffSelectionStateService.clearData();
      router.navigate([`/`]);
    }

    return !illegalPath;
  } else {
    return false;
  }
};
