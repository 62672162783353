import { Observable } from 'rxjs';
import { IAdditionalButton } from '../popup/popup.model';

export interface IWizardModel {
  titleKey: string;
  processId?: string;
  isDeepLinkEnabled?: boolean;
  wizardSteps: IWizardStep[];
  closeWarning?: ICloseWarning;
  forwardDirectionChangeIx?: number;
  hideProgressBar?: boolean; // todo remove old progress bar once every wizard is using new progress bar with labels
  cleanUpAfterLastStep?: () => void;
  useNavigatingBackTo?: boolean;
  useMoreWidth?: boolean;
  imageName?: string;
  isNotCancelable?: boolean;
  confirmationStatusStateStartIndex?: number; // default 0; less than 0 it will move to the context root
  scrollToTopmostInvalidFormControl?: boolean;
  customCancelLogic?(): void;
}

export type buttonColor = 'orange' | 'blue' | 'white';

export interface IWizardStep {
  titleKey?: string;
  subTitleKey?: string;
  progressBarLabel?: string;
  hideFromProgressBar?: boolean;
  path: string;
  prevButtonLabel?: string | (() => string);
  nextButtonLabel?: string | (() => string) | Observable<string>;
  isPrimaryHoverButton?: boolean | (() => boolean);
  showPrimarySpinnerWhenLoading?: boolean; // default = true
  hideNextButton?: boolean | (() => Promise<boolean>);
  hidePrevButton?: boolean | (() => Promise<boolean>);
  nextButtonDisabled?: Observable<boolean>;
  textButtonKey?: string;
  textButtonHref?: string;
  showCancelWarning?: boolean; // default = true for stepidx > 1
  skipNextStepButton?: WizardStepSkipButton;
  skipPrevStepButton?: WizardStepSkipButton;
  doSubmitInsteadOfCancel?: boolean;
  overwriteStepIndex?: number;
  prevButtonRouteOverwrite?: string;
  prevButtonColor?: buttonColor;
  nextButtonColor?: buttonColor;
  validConfirmationStatusStateStep?: boolean; // default false

  skipStep?(): boolean; // when next button should skip step
}

interface WizardStepSkipButton {
  skipCount: number;
  label: string;
}

interface ICloseWarning {
  additionalButtons?: IAdditionalButton[];
}

export interface IWizardStepAlert {
  titleKey?: string;
  messageKey?: string;
  type?: string;
  showIcon?: boolean;
}
