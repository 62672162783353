<div class="my-5 my-xl-8">
  <mwe-tooltip-card
    [iconClass]="'fas fa-map-marker-alt text-secondary-light'"
    [content]="meterPointAddress()"
    [hasTooltipBody]="false"
  ></mwe-tooltip-card>
</div>
<app-ikp-basic-card headingNodeType="h4" [hasBackground]="false" [title]="changeTypeText()">
  @for (product of productFields(); track product) {
    <hr class="my-5" />
    <lib-ikp-info-card-section
      [infoCardType]="InfoCardType.LINK"
      [title]="'meterPoint.meterPointChangeRequest.summary.product.title'"
      [actionText]="isPDFDownloaded() ? null : 'meterPoint.meterPointChangeRequest.summary.editActionButton'"
      [actionColor]="'blue'"
      (actionClicked)="navigateToStepWithSubRoute(ROUTE_PRODUCTS_AND_DATE)"
    >
      <div class="row row-gap-3">
        @for (field of product; track field.contentDivId) {
          @if (field.label === null && field.value === null) {
            <div class="col-xl-6 col-0"></div>
          } @else {
            <lib-info-card-content-field
              class="col-xl-6 col-12"
              [key]="field.label"
              [value]="field.value"
              [contentDivId]="field.contentDivId"
            />
          }
        }
      </div>
    </lib-ikp-info-card-section>
  }

  <hr class="my-5" />
  <lib-ikp-info-card-section
    [infoCardType]="InfoCardType.LINK"
    [title]="'meterPoint.meterPointChangeRequest.summary.changeDate.title'"
    [actionText]="isPDFDownloaded() ? null : 'meterPoint.meterPointChangeRequest.summary.editActionButton'"
    [actionColor]="'blue'"
    (actionClicked)="navigateToStepWithSubRoute(ROUTE_PRODUCTS_AND_DATE)"
  >
    @for (field of changeDateFields(); track field.contentDivId) {
      @if (field.label === null && field.value === null) {
        <div class="col-xl-6 col-0"></div>
      } @else {
        <lib-info-card-content-field
          class="col-xl-6 col-12"
          [key]="field.label"
          [value]="field.value"
          [contentDivId]="field.contentDivId"
        />
      }
    }
  </lib-ikp-info-card-section>
  <hr class="my-5" />
  <lib-ikp-info-card-section
    [infoCardType]="InfoCardType.LINK"
    [title]="'meterPoint.meterPointChangeRequest.currentContractPartnerStep.cardTitle'"
    [actionText]="isPDFDownloaded() ? null : 'meterPoint.meterPointChangeRequest.summary.editActionButton'"
    [actionColor]="'blue'"
    (actionClicked)="navigateToStepWithSubRoute(ROUTE_CURRENT_CONTRACT_PARTNER)"
  >
    <div class="row row-gap-3">
      @for (field of currentPartnerFields(); track field.contentDivId) {
        @if (field.label === null && field.value === null) {
          <div class="col-xl-6 col-0"></div>
        } @else {
          <lib-info-card-content-field
            class="col-xl-6 col-12"
            [key]="field.label"
            [value]="field.value"
            [contentDivId]="field.contentDivId"
          />
        }
      }
    </div>
  </lib-ikp-info-card-section>
  <hr class="my-5" />
  <lib-ikp-info-card-section
    [infoCardType]="InfoCardType.LINK"
    [title]="'meterPoint.meterPointChangeRequest.summary.newPartner.title'"
    [actionText]="isPDFDownloaded() ? null : 'meterPoint.meterPointChangeRequest.summary.editActionButton'"
    [actionColor]="'blue'"
    (actionClicked)="navigateToStepWithSubRoute(ROUTE_NEW_CONTRACT_PARTNER)"
  >
    <div class="row row-gap-3">
      @for (field of newPartnerFields(); track field.contentDivId) {
        @if (field.label === null && field.value === null) {
          <div class="col-xl-6 d-xl-flex d-none"></div>
        } @else {
          <lib-info-card-content-field
            class="col-xl-6 col-12"
            [key]="field.label"
            [value]="field.value"
            [contentDivId]="field.contentDivId"
          />
        }
      }
    </div>
  </lib-ikp-info-card-section>
  <hr class="my-5" />
  <lib-ikp-info-card-section
    [infoCardType]="InfoCardType.LINK"
    [title]="'meterPoint.meterPointChangeRequest.summary.contractualDeclaration.title'"
  >
    <div class="pt-3 row row-gap-2">
      <strong> {{ 'meterPoint.meterPointChangeRequest.summary.contractualDeclaration.subtitle' | translate }} </strong>
      <p>{{ 'meterPoint.meterPointChangeRequest.summary.contractualDeclaration.declarationText' | translate }}</p>
    </div>
  </lib-ikp-info-card-section>
  @if (isPDFDownloadShown()) {
    <hr class="my-5" />
    <lib-ikp-info-card-section [infoCardType]="InfoCardType.LINK" title="meterPoint.meterPointChangeRequest.summary.pdfDownload.title">
      <p>{{ 'meterPoint.meterPointChangeRequest.summary.pdfDownload.subtitle' | translate }}</p>
      <p class="mb-0">{{ 'meterPoint.meterPointChangeRequest.summary.pdfDownload.description' | translate }}</p>
      @if (isDownloadWarningShown()) {
        <mwe-inline-notification
          type="danger"
          titleKey="meterPoint.meterPointChangeRequest.summary.pdfDownload.infoBoxNotDownloadedTitle"
          messageKey="meterPoint.meterPointChangeRequest.summary.pdfDownload.infoBoxNotDownloadedText"
          class="ml-lg-6"
        />
      }
      <div class="mt-5">
        <mwe-button
          [title]="'meterPoint.meterPointChangeRequest.summary.pdfDownload.downloadButton' | translate"
          [showHoverEffect]="true"
          (clicked)="downloadPdf()"
          labelKey="meterPoint.meterPointChangeRequest.summary.pdfDownload.downloadButton"
          class="d-block text-uppercase-btn"
          category="primary"
          color="blue"
          [showSpinner]="isPDFDownloading()"
        />
      </div>
    </lib-ikp-info-card-section>
  }
</app-ikp-basic-card>
