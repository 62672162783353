import { Address, IOlavEntry } from '../address/address.model';
import {
  CommonMappingData,
  IAktionen,
  IBankDetails,
  IBulletConfirmationStatusChangeResponse,
  IERechnungsInfo,
  IProduktAuswahlDetails,
  IProduktVerfuegbarkeit,
} from '../integrationlayer/api.model';
import { IContractDetails, IdCard, IPersonalData, OrderIPDFLinks } from '../order/order.model';
import { IFileReferenceModel } from '../upload/file.model';
import { IOrganisation } from '../integrationlayer/customer.model';

export interface IProduktAuswahlParams {
  sparte?: string;
  tarifKey?: string;
  tarifTyp?: string;
  kanalCode?: string;
  kundenTyp?: string;
  prozessCode?: string;
}

export interface IProduktAuswahl {
  produkte: IProduktAuswahlDetails[];
  fernwaerme?: IProduktVerfuegbarkeit;
}

export interface IProduktAuswahlEmobility extends IProduktAuswahl {
  defaultTariff: string;
}

export interface IDefaultProdukt {
  strom?: { tarifBeschreibung: string; tarifKey: string };
  gas?: { tarifBeschreibung: string; tarifKey: string };
  internet?: { tarifBeschreibung: string; tarifKey: string };
  iptvLight?: { tarifBeschreibung: string; tarifKey: string };
  iptvPremium?: { tarifBeschreibung: string; tarifKey: string };
  voip?: { tarifBeschreibung: string; tarifKey: string };
}

// tslint:disable-next-line: no-empty-interface
export type NewClientMappingData = CommonMappingData;

// tslint:disable-next-line:no-empty-interface
export type INewClientConfirmationStatusResponse = IBulletConfirmationStatusChangeResponse;

export interface NewClientDetailsDTO {
  mappingData: NewClientMappingData[];
  personalData: IPersonalData;
  organisation: IOrganisation;
  newAddress: Address;
  addressCode: string;
  sepaMandat?: IBankDetails;
  eRechnungsInfo?: IERechnungsInfo;
  idCard: IdCard;
  referencedFiles: IFileReferenceModel[];
  pdfLinks: OrderIPDFLinks;
  contractDetails: IContractDetails;
  registrationDate: string;
  voucherInfo: IAktionen;
  orderedAt?: string;
  id?: number;
  processStatus?: string;
  newAddressOlav?: IOlavEntry;
  priceIndication?: any;
  mgmCode?: string;
  tkConnId?: string;
  grantedMarketingPermission?: boolean;
}

export interface NewClientContractDetailsViewModel {
  moveIn: string;
  oldProvider: string;
  supply: string;
}
