export interface UserSearchResult {
  totalCount: number;
  users: BasicUserInfo[];
  weInfoText?: string;
}

export interface BasicUserInfo {
  userId: string;
  email: string;
  salutation: string;
  firstName: string;
  lastName: string;
  userEnabled: boolean;
  registrationDate: string;

  deleted?: boolean;
}
export interface FullUserInfo extends BasicUserInfo {
  partnerLinks: UserInfoPartnerLink[];
  lastLoginDate: string;
  lastLoginIp: string;
  loginFailures: number;
  requiredActions: string[];
  migrationUnfinished: boolean;
  otherPartnerLinks: number;
  emailVerified: boolean;
  temporaryPartnerLinks: UserInfoPartnerLink[];
  otherTemporaryPartnerLinks: number;
  hasPasswordCredentials: boolean;
  identityProvider: string[];
  companyName: string;

  bskwKunde?: 'Ja' | 'Nein' | 'Unbekannt';
}

export interface UserInfoPartnerLink {
  partnerName: string;
  linkDate: string;
  legacyRegistrationDate?: string;
  services: UserInfoService[];
}

export interface UserInfoService {
  name: string;
  lockState: LockState;
  lastLogin: string;
}

export interface LockState {
  locked: boolean;
  lockDate: string;
  lockReason: string;
}

export interface UserStatus {
  userExists: boolean;
  isLinkedToPartner: boolean;
  userId: string;
}

export interface MigrationRequest {
  email: string;
  firstName: string;
  lastName: string;
  salutation?: string;
  hasCompany?: boolean;
  companyName?: string;
  enforceConflictCheck?: boolean;
  partners?: MigrationPartner[];
  clientId?: string;
  redirectUri?: string;
  legacyRegistrationDate?: string;
  sendMail?: boolean;
}
export interface MigrationPartner {
  name: string;
}

export interface MigrationResult {
  migrationResultStatus: string;
  email: string;
  keycloakId: string;
  actionToken: string;
}

export interface UserCreationInquiry {
  email: string;
  firstName: string;
  lastName: string;
  salutation?: string;
  redirectUri?: string;
}
