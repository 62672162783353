import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { inject } from '@angular/core';
import { FeatureFlagService } from '@ikp/service/feature-flag/feature-flag.service';
import { lastValueFrom } from 'rxjs';
import { ROUTE_NOT_FOUND } from '@ikp/app.constants';
import { FeatureFlag } from '@ikp/model/feature-flag.model';

/**
 * Configurable guard to check if a feature flag is enabled. If the feature flag is not enabled or does not exist,
 * the user is redirected to the not found page.
 * Usage:
 * ```typescript
 * export const appRoutes: Route[] = [
 *   {
 *     path: '',
 *     component: FrontpageComponent,
 *     canActivate: [featureFlagGuard(FeatureFlags.SPOTTARIF)],
 *     children: [],
 *   },
 * ```
 */
export function featureFlagGuard(flag: FeatureFlag): CanActivateFn {
  return async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const router = inject(Router);
    const featureFlagService = inject(FeatureFlagService);
    const enabled = await lastValueFrom(featureFlagService.getFeatureFlagStatus(flag));
    if (!enabled) {
      router.navigate([`/${ROUTE_NOT_FOUND}`]);
      return false;
    }
    return true;
  };
}
