interface IFelder {
  id: string;
  text: string;
}

export interface IGruppen {
  groupId: string;
  gruppenName: string;
  anzeigeText: string;
  darstellungsTyp: string;
  enthaeltCheckbox: boolean;
  defaultZustand: string;
  reihenfolge: number;
  istVerpflichtend: boolean;
  felder?: IFelder[];
}

export interface ICheckBoxesModel {
  zustimmungscode: string;
  gruppen: IGruppen[];
}

export interface IDsgvoStatus {
  wert: string;
  id: string;
  text: string;
}

export interface IDsgvoAdditionalData {
  dsgvoAccepted: string;
}
