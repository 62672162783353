import {
  AddressForm,
  ContactDataIndividual,
  ContactDataOrganization,
  ContactDataType,
  INVOICE_ADDRESS_KEY,
  OCCUPIED_CONTRACT_PARTNER_KEY,
  OccupiedContractPartner,
  ORGANIZATION_CONTACT_DATA_KEY,
  POSTAL_ADDRESS_KEY,
  PRIVATE_INDIVIDUAL_CONTACT_DATA_KEY,
} from '@ikp/pages/meter-point-change-request/03_current_contract_partner/model/occupied_contract_partner.model';
import {
  EMPTY_CONTRACT_PARTNER_KEY,
  EmptyContractPartner,
  InvoiceAddressForm,
} from '@ikp/pages/meter-point-change-request/03_current_contract_partner/model/empty_contract_partner.model';
import { FormGroup } from '@angular/forms';

export const convertOccupiedFormToModel = (form: FormGroup<NonNullable<unknown>>): OccupiedContractPartner => {
  const formValue = form.get(OCCUPIED_CONTRACT_PARTNER_KEY).value as OccupiedContractPartner;
  const contactDataIndividual = getContactDataIndividual(formValue, form);
  const contactDataOrganization = getContactDataOrganization(formValue, form);
  const invoiceAddress = getPostalAddress(form);
  return {
    organizationType: formValue.organizationType,
    contactDataIndividual: contactDataOrganization ? null : contactDataIndividual,
    contactDataOrganization: contactDataIndividual ? null : contactDataOrganization,
    invoiceAddress: invoiceAddress,
  };
};

export const convertEmptyFormToModel = (form: FormGroup<NonNullable<unknown>>): EmptyContractPartner => {
  const formValue = form.get(EMPTY_CONTRACT_PARTNER_KEY).value as EmptyContractPartner;
  const invoiceAddressValue = form.get(INVOICE_ADDRESS_KEY)?.value as InvoiceAddressForm;
  if (!invoiceAddressValue) {
    return {
      company: formValue.company,
      customerNumber: formValue.customerNumber,
      address: formValue.address,
      invoiceAddress: null,
    };
  }
  invoiceAddressValue.company = invoiceAddressValue.company.trim();
  invoiceAddressValue.address.city = invoiceAddressValue.address.city.trim();
  invoiceAddressValue.address.street = invoiceAddressValue.address.street.trim();
  invoiceAddressValue.address.houseNumberStaircase = invoiceAddressValue.address.houseNumberStaircase.trim();
  invoiceAddressValue.address.door = invoiceAddressValue.address.door.trim();
  return {
    company: formValue.company,
    customerNumber: formValue.customerNumber,
    address: formValue.address,
    invoiceAddress: invoiceAddressValue,
  };
};

export const getContactDataIndividual = (
  formValue: OccupiedContractPartner,
  form: FormGroup<NonNullable<unknown>>,
): ContactDataIndividual => {
  if (formValue.organizationType === ContactDataType.PRIVATE_INDIVIDUAL) {
    const contactDataIndividual = form.get(PRIVATE_INDIVIDUAL_CONTACT_DATA_KEY).value as ContactDataIndividual;
    return {
      ...contactDataIndividual,
      firstName: contactDataIndividual.firstName.trim(),
      lastName: contactDataIndividual.lastName.trim(),
      email: contactDataIndividual.email.trim(),
      phoneNumber: contactDataIndividual.phoneNumber.trim(),
    };
  }
  return null;
};

export const getContactDataOrganization = (
  formValue: OccupiedContractPartner,
  form: FormGroup<NonNullable<unknown>>,
): ContactDataOrganization => {
  if (formValue.organizationType === ContactDataType.ORGANIZATION) {
    const contactDataOrganization = form.get(ORGANIZATION_CONTACT_DATA_KEY).value as ContactDataOrganization;
    return {
      ...contactDataOrganization,
      name: contactDataOrganization.name.trim(),
      uid: contactDataOrganization.uid.trim(),
      firmenbuchNr: contactDataOrganization.firmenbuchNr.trim(),
      email: contactDataOrganization.email.trim(),
      phoneNumber: contactDataOrganization.phoneNumber.trim(),
    };
  }
  return null;
};

export const getPostalAddress = (form: FormGroup<NonNullable<unknown>>): AddressForm => {
  const addressForm = form.get(POSTAL_ADDRESS_KEY).value as AddressForm;
  return {
    ...addressForm,
    plz: addressForm.plz,
    city: addressForm.city.trim(),
    street: addressForm.street.trim(),
    houseNumberStaircase: addressForm.houseNumberStaircase.trim(),
    door: addressForm.door.trim(),
  };
};
