import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import dayjs from 'dayjs';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export function createDateRangeValidator(dateRange: NgbDateStruct[]): ValidatorFn {
  function getValidDate(date): dayjs.Dayjs | false {
    if (dayjs(date, 'YYYY-MM-DD', true).isValid()) {
      return dayjs(date, 'YYYY-MM-DD', true);
    }
    if (dayjs(date, 'DD.MM.YYYY', true).isValid()) {
      return dayjs(date, 'DD.MM.YYYY', true);
    }
    return false;
  }

  function dayjsFromNgbDate(date: NgbDateStruct): dayjs.Dayjs {
    return dayjs(`${date.year}-${String(date.month).padStart(2, '0')}-${String(date.day).padStart(2, '0')}`, 'YYYY-MM-DD', true);
  }

  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    const valueAsValidDate = getValidDate(value);
    if (!value || !valueAsValidDate) return { registrationTimeRange: true };
    const registrationTimeRangeDayjs = dateRange.map(e => {
      return dayjsFromNgbDate(e);
    });
    const dateValid =
      valueAsValidDate.valueOf() >= registrationTimeRangeDayjs[0].valueOf() &&
      valueAsValidDate.valueOf() <= registrationTimeRangeDayjs[1].valueOf();

    return !dateValid ? { registrationTimeRange: true } : null;
  };
}
