import { UiComponentsModule } from '@mwe/ui/components';
import { CommonModule } from '@angular/common';
import { Component, computed, inject } from '@angular/core';
import { MeterPointChangeSignalState } from '@ikp/pages/meter-point-change-request/meterpoint-change-request.types';
import { SignalState } from '@shared/service/signal-state-service/signal-state.service';
import { getAddressPostCodeAndStreetFormatted } from '@mwe/utils';

@Component({
  selector: 'app-invoicing-information',
  imports: [UiComponentsModule, CommonModule],
  templateUrl: './invoicing-information.component.html',
  styleUrl: './invoicing-information.component.scss',
  standalone: true,
})
export class InvoicingInformationComponent {
  protected wizardStore: MeterPointChangeSignalState = inject(SignalState);
  protected invoicingAddress = computed(() => getAddressPostCodeAndStreetFormatted(this.wizardStore.state().meterPoint.adresse));
}
