export interface NewUser {
  id?: string;
  userName?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  //enabled?: boolean;
  //emailVerified?: boolean;
  //totp?: boolean;
  //createdTimestamp?: number;
}

/**
 * Short spin of org.keycloak.representations.idm.UserRepresentation
 */
export interface OauthUser {
  id: string;
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  emailVerified: boolean;
  enabled: boolean;
  createdTimestamp: number;
}

/**
 * Extended spin of org.keycloak.representations.idm.UserRepresentation
 */
export interface OauthUserProfile extends OauthUser {
  totp: boolean;
  origin: string;
  federationLink: string;
  serviceAccountClientId: string;
  attributes: Map<string, Array<string>>;
  federatedIdentities: Array<FederatedIdentityRepresentation>;
  realmRoles: Array<string>;
  clientRoles: Map<string, Array<string>>;
  clientConsents: Array<UserConsentRepresentation>;
  socialLinks: Array<SocialLinkRepresentation>;
}

export interface FederatedIdentityRepresentation {
  identityProvider: string;
  userId: string;
  userName: string;
}

export interface UserConsentRepresentation {
  clientId: string;
  grantedClientScopes: Array<string>;
  createdDate: number;
  lastUpdatedDate: number;
}

export interface SocialLinkRepresentation {
  socialProvider: string;
  socialUserId: string;
  socialUsername: string;
}
