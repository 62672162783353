export interface IFelder {
  wert: string;
  id: string;
}

interface IGruppen {
  consent: boolean;
  groupId: string;
  felder: IFelder[];
}

export interface IGpList {
  sparte: string;
  geschaeftsPartnerId: string;
}

export interface ISaveConsentModel {
  gruppen: IGruppen[];
  gpList: IGpList[];
  ausloesenderProzess: string;
}

export interface IDsgvoTrackingData {
  [key: string]: any;
  type: string;
  event: string;
}
