import { IPopupModel } from '@mwe/models';
import { ChangeDetectionStrategy, Component, computed, input, output, signal, ViewChild } from '@angular/core';
import { PopupService } from '@mwe/services';
import { UiComponentsModule } from '@mwe/ui/components';
import { NgClass } from '@angular/common';
import { FileInputConfiguration, FileModel, FileUploadError, UploadResult } from '@shared/components/inputs/file-input/file-input.model';

@Component({
  selector: 'lib-ikp-file-input',
  templateUrl: './file-input.component.html',
  styleUrl: './file-input.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [UiComponentsModule, NgClass],
})
export class FileInputComponent {
  private readonly FILE_EXTENSION_MATCHER_REGEX: RegExp = /(?<=\.)([^.]+)$/g;

  @ViewChild('file', { static: false }) fileRef: any;

  config = input.required<FileInputConfiguration>();
  hasMarginBottom = input(true);
  readonly emitFileUpload = output<UploadResult>();

  uploadedFile = signal<FileModel>(null);
  fileErrors = signal<FileUploadError[]>([]);
  isValidFile = computed<boolean>(() => this.fileErrors().length === 0);
  totalSize = signal<number>(0);
  showUploadError = signal<boolean>(false);
  showLoadingSpinner = signal<boolean>(false);

  uploadField = signal<HTMLInputElement>(undefined);

  errorMessageKeys = computed<string[]>(() => {
    if (this.fileErrors().length === 0) {
      return [];
    } else if (typeof this.config().uploadErrorMsg === 'string') {
      return [this.config().uploadErrorMsg as string];
    } else {
      const errorMessageMap = this.config().uploadErrorMsg as Map<FileUploadError, string>;
      return this.fileErrors().map(error => errorMessageMap.get(error) ?? error);
    }
  });

  constructor(private popupService: PopupService) {}

  async fileUpload(fileInput: Event): Promise<void> {
    const target = fileInput.currentTarget as HTMLInputElement;
    if (!target || !target.files) return;
    this.showLoadingSpinner.set(true);
    this.uploadField.set(target);
    const file = this.uploadField().files[0];
    if (this.checkIfFileSizeAndTypeIsValid(file)) {
      await this.readFile(file);
    } else if (file) {
      const invalidFileMetadata = this.parseToUploadFile(file); //parse for metadata without file reader
      this.uploadedFile.set(invalidFileMetadata);
    }
    this.showLoadingSpinner.set(false);
  }

  async readFile(file: File): Promise<void> {
    const fileReader: FileReader = new FileReader();

    try {
      fileReader.onloadend = () => {
        const uploadedFile: FileModel = this.parseToUploadFile(file, fileReader);
        this.fileErrors.set([]);
        this.totalSize.set(uploadedFile.size);
        this.uploadedFile.set(uploadedFile);
        this.showUploadError.set(false);
        this.emitFileUpload.emit(this.getUploadEvent());
        if (this.uploadField()) {
          this.uploadField.update(input => ({
            ...input,
            value: '',
          }));
        }
      };

      fileReader.readAsDataURL(file);
    } catch (ex) {
      this.handleError([FileUploadError.COULD_NOT_LOAD_FILE]);
      this.showLoadingSpinner.set(false);
    }
  }

  checkIfFileSizeAndTypeIsValid(file: File) {
    const errors = [];
    if (file?.size < this.config().minSizeOfFileInByte) {
      errors.push(FileUploadError.FILE_TOO_SMALL);
    }
    if (file?.size > this.config().maxSizeOfFileInByte) {
      errors.push(FileUploadError.FILE_TOO_LARGE);
    }
    if (!this.checkAcceptedTypes(file?.type)) {
      errors.push(FileUploadError.FILE_TYPE_INVALID);
    }

    if (errors.length === 0) {
      this.showUploadError.set(false);
      return true;
    } else if (file) {
      this.handleError(errors);
    }
    return false;
  }

  checkAcceptedTypes(fileType: string): string {
    return this.config()
      .accept.split(',')
      .find(type => {
        const acceptedType = type.replace('*', '');
        return fileType?.trim().startsWith(acceptedType.trim());
      });
  }

  removeFile(): void {
    const model: IPopupModel = {
      id: 'upload-remove-file',
      titleKey: this.config().deletePopupConfiguration?.titleKey,
      messageKey: this.config().deletePopupConfiguration?.textKey,
      showSubmitButton: true,
      submitButtonKey: this.config().deletePopupConfiguration?.submitKey,
      showCancelButton: true,
      cancelButtonKey: this.config().deletePopupConfiguration?.cancelKey,
      iconTypeClass: 'fa-sharp-duo tone fa-solid fa-siren-on',
      iconColorClass: 'yellow',
      modalSize: 'lg',
    };
    if (!this.isValidFile() || !this.config().deletePopupConfiguration) {
      this.reset();
    } else {
      this.popupService.open(model);
      this.popupService.events().subscribe(result => {
        if (result) {
          this.reset();
        }
      });
    }
  }

  formatBytes(bytes, decimals = 1): string {
    if (bytes === 0) {
      return '0 Bytes';
    }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  private parseToUploadFile(file: File, fileReader?: FileReader): FileModel {
    const currentDate = Date.now();
    const timestamp = new Date(currentDate).toLocaleTimeString() + ':' + new Date(currentDate).getMilliseconds().toString();
    const fileName = file.name;

    return {
      id: timestamp,
      name: fileName,
      formattedSize: this.formatBytes(file.size),
      size: file.size,
      base64: fileReader && (file.type.includes('image') || file.type.includes('pdf')) ? fileReader.result : null,
      showPreview: false,
      extension: this.getFileExtension(fileName),
      originalFile: file,
    };
  }

  private getUploadEvent(): UploadResult {
    return { uploadedFile: this.isValidFile() ? this.uploadedFile() : null, valid: this.isValidFile(), errors: this.fileErrors() };
  }

  private handleError(errors: FileUploadError[]): void {
    this.showUploadError.set(true);
    this.fileErrors.set(errors);

    this.emitFileUpload.emit(this.getUploadEvent());
    this.uploadField.update(input => ({
      ...input,
      value: '',
    }));
    this.totalSize.set(0);
  }

  private reset() {
    this.uploadedFile.set(null);
    this.fileErrors.set([]);
    this.totalSize.set(0);
    this.showUploadError.set(false);
    this.emitFileUpload.emit(this.getUploadEvent());
  }

  getFileExtension(name: string): string {
    return name.match(this.FILE_EXTENSION_MATCHER_REGEX).toString().toUpperCase();
  }
}
