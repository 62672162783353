import { IOrderDetailsInfo, isProductCategory as modelUtils_isProductCategory } from '@mwe/models';
import { ProductCategoryEnum } from '@mwe/constants';
import { accumulatedCategoryForNewProducts } from '../common/mwe-util';

export const getRelocationFromDetail = (
  relocationDetailsInfo: IOrderDetailsInfo[],
  newAddressCategories: ProductCategoryEnum[]
): IOrderDetailsInfo => {
  const relocationFromDetailsList = relocationDetailsInfo.filter(elem => elem.status === 'Abmeldung');

  const accumulatedCategory = accumulatedCategoryForNewProducts(newAddressCategories);
  const detailsFromMap = new Map();
  detailsFromMap.set(1, ProductCategoryEnum.STROM); // relocation to with STROM
  detailsFromMap.set(2, ProductCategoryEnum.GAS); // relocation to with GAS
  detailsFromMap.set(3, ProductCategoryEnum.STROM); // relocation to with STROM + GAS
  detailsFromMap.set(4, ProductCategoryEnum.FERNWAERME); // relocation to with FW
  detailsFromMap.set(5, ProductCategoryEnum.STROM); // relocation to with S, FW
  detailsFromMap.set(6, ProductCategoryEnum.GAS); // relocation to with G, FW
  detailsFromMap.set(7, ProductCategoryEnum.STROM); // relocation to with S,G,FW

  const searchCategory = detailsFromMap.get(accumulatedCategory);
  let detailsInfo = relocationFromDetailsList.find(elem => modelUtils_isProductCategory(elem.category, searchCategory));

  if (!detailsInfo) {
    if ([2, 4].includes(accumulatedCategory)) {
      // customer moves to gas/fernwaerme only but in the old list we do not have such a product
      // no match found return now macth for category strom
      detailsInfo = relocationFromDetailsList.find(elem => modelUtils_isProductCategory(elem.category, ProductCategoryEnum.STROM));
    } else if ([1, 3, 6, 7].includes(accumulatedCategory)) {
      // customer moves to strom only but in the old list we do not have such a product
      // or customer moves to a place with gas in the list of products therefore try to
      // find a gas product in the old products list
      // no match found return now macth for category gas
      detailsInfo = relocationFromDetailsList.find(elem => modelUtils_isProductCategory(elem.category, ProductCategoryEnum.GAS));
    }
  }

  if (!detailsInfo) {
    // we can not find any strom gas match in the old list, try now to find nachtstrom or stromrueck
    detailsInfo = relocationFromDetailsList.find(elem => modelUtils_isProductCategory(elem.category, ProductCategoryEnum.NACHTSTROM));
    if (!detailsInfo) {
      detailsInfo = relocationFromDetailsList.find(elem => modelUtils_isProductCategory(elem.category, ProductCategoryEnum.STROMRUECK));
    }
  }

  if (!detailsInfo) {
    // we have not found any match take the value  of the first product  from the old products list
    detailsInfo = relocationFromDetailsList[0];
  }

  if (modelUtils_isProductCategory(detailsInfo.category, ProductCategoryEnum.STROMRUECK)) {
    // in this case it's not allowed to use the SEPA settings for an invoice
    detailsInfo.bezahlartIcon = 'zahlschein';
    detailsInfo.bezahlartText = 'Zahlschein';
  } else if (detailsInfo.bezahlartText?.toLowerCase() !== 'zahlschein') {
    detailsInfo.bezahlartIcon = 'sepa';
    //
  }

  return detailsInfo;
};
