<mwe-basic-card headingNodeType="h4" [hasBackground]="true" cardTitle="meterPoint.meterPointChangeRequest.stepChangeType">
  @for (option of options; track option.id; let first = $first; let last = $last) {
    <div class="d-flex flex-column" [ngClass]="{ 'pt-3': first, 'radio-border py-4 py-lg-5': !last, 'pt-4 pt-lg-5': last }">
      <div class="form-check mb-0">
        <input
          class="form-check-input"
          name="selectedChangeType"
          type="radio"
          [id]="option.id"
          [value]="option.text | translate"
          [checked]="selectedChangeType() === option.id"
          (change)="onFormChanged(option.id)"
        />
        <label class="form-check-label mb-4 mb-lg-3 ml-2" [for]="option.id"> {{ option.text | translate }} </label>
      </div>
      @if (infoBoxText() && option.id === selectedChangeType()) {
        <mwe-inline-notification [messageKey]="infoBoxText()" class="ml-lg-6" />
      }
    </div>
  }
</mwe-basic-card>
