import { Abschlagsplan, BezeichnungEnum, Preisbestandteil, RechnungDetails, Teilbetrag } from '@mwe/models';
import dayjs from 'dayjs';
import { isArrayWithMinOneItem } from '../../common/mwe-util';

export const findPreisbestandteil = (code: BezeichnungEnum, list: Preisbestandteil[]): Preisbestandteil => {
  return list.find(pb => pb.bezeichnung.toLowerCase() === code.toLowerCase());
};

export const getPreisbestandteil = (code: BezeichnungEnum, invoiceDetails: RechnungDetails): Preisbestandteil => {
  return findPreisbestandteil(code, invoiceDetails.preisbestandteil);
};

export const getPreisbestandteilWert = (code: BezeichnungEnum, invoiceDetails: RechnungDetails): number => {
  return getPreisbestandteil(code, invoiceDetails)?.preisbestandteilWert;
};

export const getNextPartialData = (data: Abschlagsplan): Teilbetrag => {
  if (!data) {
    return null;
  }

  const today = new Date();
  return data?.teilbetraege.find(t => !dayjs(t.faelligkeitsdatum).isBefore(today));
};

export const getNextPartialDataByIndex = (data: Abschlagsplan, indexOffset: number): Teilbetrag => {
  if (!data) {
    return null;
  }

  const today = new Date();
  const partialAmounts = data?.teilbetraege.filter(t => t.nummer !== '00');
  const nextAmountIndex = partialAmounts?.findIndex(t => !dayjs(t.faelligkeitsdatum).isBefore(today));
  const indexOfInterest = isNaN(nextAmountIndex) || nextAmountIndex === -1 ? undefined : nextAmountIndex + indexOffset;
  if (indexOfInterest < data?.teilbetraege.length - 1) {
    return data.teilbetraege[indexOfInterest];
  }
  return undefined;
};

export const getNextPartialAmount = (data: Abschlagsplan): number => {
  return getNextPartialData(data)?.betrag;
};

export const getPaymentExtensionDate = (data: Teilbetrag): string => {
  if (!isArrayWithMinOneItem(data.produktspezifischerTeilbetrag)) {
    return '';
  }

  const item = data.produktspezifischerTeilbetrag.find(i => i.stundungsDatum);

  return item?.stundungsDatum ?? '';
};
