import { CommonMappingData, IBulletConfirmationStatusChangeResponse } from '@mwe/models';

// tslint:disable-next-line: no-empty-interface
export type IVkiOfferConfirmMapping = CommonMappingData & {
  zaehlpunktNummer?: string;
};
// tslint:disable-next-line:no-empty-interface
export type IVkiOfferConfirmStatusResponse = IBulletConfirmationStatusChangeResponse;

export interface IVkiOfferConfirmDTO {
  mappingData: IVkiOfferConfirmMapping[];
  offerNumbers: string[];
  iban: string;
  bestaetigungsEmail?: string;
  newsletter?: boolean;
  verrechnungskontoInhaber?: boolean;
}

/**
 * Represents VKICompensationDetailsChangeData in Backend
 *
 */
export class VkiOfferConfirmDTO implements IVkiOfferConfirmDTO {
  constructor(
    public mappingData: IVkiOfferConfirmMapping[],
    public offerNumbers: string[],
    public iban: string,
    public bestaetigungsEmail: string,
    public newsletter: boolean,
    public verrechnungskontoInhaber: boolean,
  ) {}
}

export function parseVkiOfferConfirmDTO(orderDetail): IVkiOfferConfirmDTO {
  if (!orderDetail) {
    return null;
  }

  const orderData = JSON.parse(orderDetail.changeData);
  return {
    mappingData: orderData.mappingData,
    offerNumbers: orderData.offerNumbers,
    iban: orderData.iban,
    bestaetigungsEmail: orderData.bestaetigungsEmail,
    newsletter: orderData.newsletter,
    verrechnungskontoInhaber: orderData.verrechnungskontoInhaber,
  };
}
