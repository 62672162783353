export interface IFileModel {
  id: string;
  name: string;
  formattedSize: string;
  size: number;
  base64: string | ArrayBuffer;
  showPreview: boolean;
  showError?: boolean;
  isLoading?: boolean;
  extension?: string;
}

export interface IFileReferenceModel {
  dokumentRefId: string;
}

export interface IUploadResult {
  uploadedFiles: IFileModel[];
  valid: boolean;
}
