<mwe-basic-card [hasBackground]="false" cardTitle="meterPoint.meterPointChangeRequest.currentContractPartnerStep.cardTitle">
  <form [formGroup]="this.formGroup" tabindex="-1">
    {{ 'meterPoint.meterPointChangeRequest.currentContractPartnerStep.organizationTypeDecision' | translate }}
    <div class="row py-3">
      <div class="col-12 col-md-6">
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            formControlName="organizationType"
            id="radioPrivatperson"
            [value]="ContactDataType.PRIVATE_INDIVIDUAL"
            (change)="toggleValidatorsWithAddressReset(ContactDataType.PRIVATE_INDIVIDUAL)"
          />
          <label class="form-check-label" for="radioPrivatperson">{{
            'meterPoint.meterPointChangeRequest.currentContractPartnerStep.organizationType.private' | translate
          }}</label>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            formControlName="organizationType"
            id="radioOrganization"
            [value]="ContactDataType.ORGANIZATION"
            (change)="toggleValidatorsWithAddressReset(ContactDataType.ORGANIZATION)"
          />
          <label class="form-check-label" for="radioOrganization">{{
            'meterPoint.meterPointChangeRequest.currentContractPartnerStep.organizationType.organization' | translate
          }}</label>
        </div>
      </div>
    </div>
    <hr />
    <h6 class="pb-3 pt-3">{{ 'meterPoint.meterPointChangeRequest.currentContractPartnerStep.contactDataTitle' | translate }}</h6>
    @switch (this.contactDataType) {
      @case (ContactDataType.PRIVATE_INDIVIDUAL) {
        <app-private-contact-data [parentForm]="parentForm()" />
      }
      @case (ContactDataType.ORGANIZATION) {
        <app-organization-contact-data [parentForm]="parentForm()" />
      }
    }
    <hr />
    <h6 class="pb-3 pt-3">{{ 'meterPoint.meterPointChangeRequest.currentContractPartnerStep.invoiceTitle' | translate }}</h6>
    <div class="pb-3">{{ 'meterPoint.meterPointChangeRequest.currentContractPartnerStep.invoiceAddressQuestion' | translate }}</div>
    <app-address-form [controlName]="POSTAL_ADDRESS_KEY" [parentForm]="parentForm()" />
  </form>
</mwe-basic-card>
