import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import {
  COLLABORATION_ROUTE,
  FeatureToggleEnum,
  MAINTENANCE_ROUTE,
  TERMS_OF_USE_ROUTE,
  LANDING_PAGE_VKI_OFFER_ROUTE,
} from '@mwe/constants';
import { ProfileService } from '../profile/profile.service';
import { AppStorageService } from '../cache/app-storage.service';

export const maintenanceAccessService: CanActivateFn = async (_, state) => {
  const router = inject(Router);
  const profileService = inject(ProfileService);
  const appStorage = inject(AppStorageService);

  appStorage.setMaintenanceWhitelist([`/${MAINTENANCE_ROUTE}`, `/${TERMS_OF_USE_ROUTE}`, `/${LANDING_PAGE_VKI_OFFER_ROUTE}/*`]);

  if (state.url.endsWith(`/${COLLABORATION_ROUTE}`)) {
    return true;
  }

  if (profileService.shouldRefreshProfileInfo()) {
    await profileService.loadProfileInfo();
    profileService.checkFeatureToggleOverwrites();
  }

  if (profileService.isFeatureToggleEnabled(FeatureToggleEnum.MAINTENANCE_ENABLED)) {
    const isPageInWhitelist = appStorage.getMaintenanceWhitelist().some(pageUrl => state.url.match(new RegExp(pageUrl)));
    return isPageInWhitelist ? true : router.parseUrl(`/${MAINTENANCE_ROUTE}`);
  }

  return true;
};
