export interface Country {
  name: string;
  dialingNumber: string;
  countryCode: string;
}

export interface PhoneNumberFormInputGroupConfig {
  countryCode: string;
  phoneNumber: string;
  isDisabled: boolean;
  namePrefix: string;
  labelPrefix: string;
  isRequired: boolean;
}
