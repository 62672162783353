export interface AppConfigItem {
  id: number;
  name: string;
  category: string;
  value: string;
  description: string;
  service: string;
  applications: string;
  selectableApps: string;
  appArray: string[];
  selectableAppArray: string[];
}
