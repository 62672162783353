import {
  CommonMappingData,
  getTariffChangeAvailabilityForProduct,
  IOrderDetailsInfo,
  IProduktAuswahlDetails,
  IVertragskonto,
  PredefinedTariffSelectionData,
  Product,
  TariffChangeAvailabilities,
  TariffChangeAvailability,
  TariffChangeResponse,
  TariffSelectionCardData,
  Tarifwechsel,
} from '@mwe/models';
import { anyTrue, everyTrue, isSepa } from '../common/mwe-util';
import { hasClearingAccountDifferentInvoiceRecipient } from '../common/clearing-account.utils';
import { getCategorySequence, isEqualCategoryString } from '../products/product-categoriy.utils';
import { parseTariff } from '../products/product.utils';

export const hasProcessStatusError = (processStatus: string): boolean => {
  return processStatus.indexOf('error') > -1;
};

export const getProcessStatusBulletIndex = (
  processStatus: string,
  mappingData: CommonMappingData[],
  tariffChanges: Tarifwechsel[],
  customerChangeData: {
    email: string;
    customerNumbers: string[];
  },
): number => {
  if (processStatus.indexOf('_') > -1) {
    // following two arrays are needed to map old data entries
    // for old data entries we have user either the accountNummber or the customerNumber to define bullet ids
    const _accountNumbers = mappingData.filter(md => md.changeERechnung).map(md => md.accountNumber);
    const _customerNumbers = mappingData.filter(md => md.changeSepaMandat).map(md => md.businessPartnerNumber);
    const _orderIndices = mappingData.map(md => '' + md.orderIdx);
    const statusTokens = processStatus.split('_');
    const orderedIndex = _orderIndices.indexOf(statusTokens[2]);
    const customerDataChangeOffset = customerChangeData ? customerChangeData.customerNumbers.length : 0;
    let offset = customerDataChangeOffset + (_accountNumbers?.length > 0 ? _accountNumbers.length : 0);
    if (orderedIndex > -1) {
      offset = customerDataChangeOffset + orderedIndex;
    }
    if (statusTokens[1].toLowerCase() === 'customerdata') {
      const customerDataIdx = customerChangeData.customerNumbers.indexOf(statusTokens[2]);
      return customerDataIdx > -1 ? customerDataIdx : parseInt(statusTokens[2], 10);
    } else if (statusTokens[1].toLowerCase() === 'erechnung') {
      return customerDataChangeOffset + (orderedIndex > -1 ? orderedIndex : _accountNumbers.indexOf(statusTokens[2]));
    } else if (statusTokens[1].toLowerCase() === 'sepa') {
      return offset + (orderedIndex > -1 ? orderedIndex : _customerNumbers.indexOf(statusTokens[2]));
    } else {
      const tcAccountNumbers = [...Array.from(new Set(tariffChanges.map(t => t.vertragsKontoNummer)))];
      offset = _customerNumbers?.length > 0 ? offset + _customerNumbers.length : offset;
      let tariffStatus = statusTokens[2];
      if (anyTrue(tariffStatus.indexOf('Strom') > -1, tariffStatus.indexOf('Gas') > -1)) {
        tariffStatus = statusTokens[2].replace('Strom', '').replace('Gas', '');
        const statusCategory = statusTokens[2].replace(tariffStatus, '');
        const categoryOffset = mappingData.find(md => md.accountNumber === tariffStatus).categories.indexOf(statusCategory);
        return offset + tcAccountNumbers.indexOf(tariffStatus) + categoryOffset;
      }
      return offset + parseInt(tariffStatus, 10);
    }
  }
  return null;
};

export const hasFirstBulletError = (
  processStatus: string,
  mappingData: CommonMappingData[],
  tariffChanges: Tarifwechsel[],
  customerChangeData: {
    email: string;
    customerNumbers: string[];
  },
): boolean => {
  const bulletIndex = getProcessStatusBulletIndex(processStatus, mappingData, tariffChanges, customerChangeData);
  return everyTrue(hasProcessStatusError(processStatus), bulletIndex === 0);
};

export const getProductForTariffChangeAvailability = (productsForSelection: Product[], tca: TariffChangeAvailability): Product => {
  if (!tca) {
    return null;
  }

  return productsForSelection.find(p => everyTrue(p.accountNumber === tca.vertagskontoId, isEqualCategoryString(p.category, tca.sparte)));
};

export const getPredefinedTariffKey = (
  tariffChangeAvailabilities: TariffChangeAvailabilities,
  predefinedTariffs: PredefinedTariffSelectionData[],
  p: Product,
): Map<string, string> => {
  const taAvailability = getTariffChangeAvailabilityForProduct(tariffChangeAvailabilities, p);
  let predefinedTariff;
  if (predefinedTariffs) {
    predefinedTariffs.forEach(tariff => {
      if (taAvailability.tariffs.find(at => at.tariff.ISUTarifKey === tariff.tariffKey)) {
        predefinedTariff = tariff;
      }
    });
  }
  return predefinedTariff;
};

export const getOrderDetailWithDifferentInvoiceRecipient = (orderDetails: IOrderDetailsInfo[]): IOrderDetailsInfo => {
  return orderDetails.find((orderDetail: IOrderDetailsInfo) => {
    return hasClearingAccountDifferentInvoiceRecipient(orderDetail.verrechnungskonto);
  });
};

export const sortTariffByAccountNumberAndCategory = (tarifwechsel: Tarifwechsel[]): Tarifwechsel[] => {
  return tarifwechsel.sort((a, b) => {
    if (a.vertragsKontoNummer !== b.vertragsKontoNummer) {
      return a.vertragsKontoNummer > b.vertragsKontoNummer ? 1 : -1;
    } else {
      return getCategorySequence(a.sparte) > getCategorySequence(b.sparte) ? 1 : -1;
    }
  });
};

export const customerBankAccoutToVertragskonto = (customerBankAccount: any): IVertragskonto => {
  return {
    vertragskontoId: null,
    zahlungsmethode: customerBankAccount ? customerBankAccount.zahlungsmethode : 'Bankeinzug',
    blz: null,
    kontonummer: null,
    iban: customerBankAccount?.iban,
    bic: customerBankAccount?.swift,
  };
};

export const wasSepaChanged = (invoiceData: IVertragskonto, orderDetails: IOrderDetailsInfo): boolean => {
  const eingangskonto = orderDetails?.verrechnungskonto?.eingangskonto;

  return isSepa(invoiceData) && eingangskonto?.iban !== invoiceData.iban;
};

export const getOrderDetailsForProduktSelektion = (orderDetails: IOrderDetailsInfo[], pad: IProduktAuswahlDetails): IOrderDetailsInfo => {
  return orderDetails.find(p => everyTrue(p.accountNumber === pad.accountId, isEqualCategoryString(p.category, pad.sparte)));
};

export const mapProcessStatusForOldEntries = (
  tcResponse: TariffChangeResponse,
  bulletIds: string[],
  commonMappingData: CommonMappingData[],
  customerChangeData: {
    email: string;
    customerNumbers: string[];
  },
): void => {
  // map accountNumbers and/or businessPartnerNumbers (customerNumbers) to orderIdx of mapping data
  // if the status already contains the orderIdx of the mapping data then we make a reduntant replace with orderIdx
  const processingParts = tcResponse.status.split('_');
  const currentBulletId = processingParts[1] + '_' + processingParts[2];
  if (processingParts[3] === 'error') {
    const sliceEndIx = bulletIds.indexOf(currentBulletId);
    if (sliceEndIx === -1) {
      if (processingParts[1] === 'customerdata') {
        const customerDataIdx = customerChangeData.customerNumbers.indexOf(processingParts[2]);
        tcResponse.status = tcResponse.status.replace(processingParts[2], '' + customerDataIdx);
      } else if (processingParts[1] === 'erechnung') {
        tcResponse.status = tcResponse.status.replace(
          processingParts[2],
          '' + commonMappingData.find(md => md.accountNumber === processingParts[2] && md.changeERechnung).orderIdx,
        );
      } else if (processingParts[1] === 'sepa') {
        tcResponse.status = tcResponse.status.replace(
          processingParts[2],
          '' + commonMappingData.find(md => md.businessPartnerNumber === processingParts[2] && md.changeSepaMandat).orderIdx,
        );
      } else if (processingParts[1] === 'tariff') {
        const _md = commonMappingData.find(md => processingParts[2].indexOf(md.accountNumber) > -1 && md.changeSepaMandat);
        let categoryIdx = 0;
        for (let idx = 0; idx < _md.categories.length; idx++) {
          if (processingParts[2].indexOf(_md.categories[idx]) > -1) {
            categoryIdx = idx;
          }
        }
        tcResponse.status = tcResponse.status.replace(processingParts[2], '' + (_md.orderIdx + categoryIdx));
      }
    }
  }
};

export const parseIProduktAuswahlDetails = (tca: TariffChangeAvailability, product: Product): IProduktAuswahlDetails[] => {
  return tca.tariffs.map(t => {
    return {
      sparte: tca.sparte,
      kundenType: tca.kundenTyp,
      anlageId: tca.anlage.anlageId,
      onlineTarif: !!t.condition?.onlineTarif,
      bedingungen: {
        sepaPflicht: !!t.condition?.sepaPflicht,
        eRechnungsPflicht: !!t.condition?.eRechnungsPflicht,
      },
      accountId: tca.vertagskontoId,
      customerNumber: product.customerNumber,
      tarifBeschreibung: parseTariff(t.tariff.tarifName),
      tarif: t.tariff,
    } as IProduktAuswahlDetails;
  });
};

export const getTariffsForUserSelectedOffers = (data: TariffSelectionCardData[], ids: string[]): TariffSelectionCardData[] => {
  return (
    data?.filter(d => {
      return ids?.some(id => id === d.anlageId);
    }) || []
  );
};
