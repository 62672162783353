import { Component, computed, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslatePipe } from '@ngx-translate/core';
import { MeterPointChangeSignalState } from '@ikp/pages/meter-point-change-request/meterpoint-change-request.types';
import { SignalState } from '@shared/service/signal-state-service/signal-state.service';
import { CompanyService } from '@ikp/service/company/company.service';
import { getAddressPostCodeAndStreetFormatted } from '@mwe/utils';

@Component({
  selector: 'app-new-contract-partner-empty',
  standalone: true,
  imports: [CommonModule, TranslatePipe],
  templateUrl: './contract-partner-empty.component.html',
  styleUrl: './contract-partner-empty.component.scss',
})
export class ContractPartnerEmptyComponent {
  protected wizardStore: MeterPointChangeSignalState = inject(SignalState);
  protected readonly emptyContractPartner = computed(() => this.wizardStore.state().newContractPartner.emptyPartner);
  protected readonly addressFormatted = computed(() => {
    const address = this.emptyContractPartner()?.address;
    return getAddressPostCodeAndStreetFormatted({
      postcode: address?.plz,
      city: address?.city,
      street: address?.street,
      doorNumber: address?.door,
      streetNumber: address?.houseNumberStaircase,
      block: undefined,
      country: undefined,
      id: undefined,
    });
  });

  private companyService = inject(CompanyService);

  constructor() {
    const gpNr = this.wizardStore.state().meterPoint.geschaeftspartnerNr;
    this.companyService.loadCompanyByGpNr(gpNr).then(company => {
      this.wizardStore.patchState({
        ...this.wizardStore.state(),
        newContractPartner: {
          emptyPartner: {
            company: company.name,
            address: {
              plz: company.address.postcode,
              city: company.address.city,
              street: company.address.street,
              houseNumberStaircase: company.address.streetNumber,
              door: company.address.doorNumber,
            },
            customerNumber: company.gpNr,
          },
          formData: null,
          alternativeInvoicingAddress: null,
        },
      });
    });
  }
}
